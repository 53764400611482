// These values override the default in `@kite/tokens`.
// *Permanent* overrides are meant to apply an opinionated override to the default,
// for example, changing a token from a unitless '1' to '1px'
// *Custom* overrides are custom variables provided by Vanilla to make systemizing the
// the CSS a little easier, these may be moved to tokens at some point

// Custom
/* Custom CSS variables */
:root {
	// TODO(dura): Document these so users can theme them?
	--kv-hr-border-width: var(--kite-border-width-thin);
	--kv-blockquote-border-width: 0.25rem;
	--kv-blockquote-border-color: var(--kite-color-blue-30);
	--kv-blockquote-cite-color: var(--kite-color-gray-30);
	--kv-cursor-disabled: not-allowed;
}
