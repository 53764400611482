@import '../config';
@import '../theme/mixins';
@import 'mixins';

// Provide basic, default focus styles to fallback to,
// if polyfill fails to load.
.kite-theme--dark :focus {
	outline-color: var(--kite-focus-ring-color-dark);
}

:focus,
.kite-theme--light :focus {
	@include kite-focus-outline();
}

// This will hide the focus indicator if the element receives focus via the mouse,
// but it will still show up on keyboard focus.
.js-focus-visible :focus:not(.focus-visible) {
	outline: 0;
}

// Optionally: Define a strong focus indicator for keyboard focus.
// If you choose to skip this step then the browser's default focus
// indicator will be displayed instead.
.js-focus-visible .kite-theme--dark .focus-visible {
	outline-color: var(--kite-focus-ring-color-dark);
}

// This `--light` selector is the same specificity as the `--dark`, so
// in order to make sure it overrides in a `--dark` app, we can
// double up on the classname, or just make sure the styles are later in the cascade order.
// .js-focus-visible .kite-theme--light.kite-theme--light .focus-visible,
.js-focus-visible .kite-theme--light .focus-visible {
	outline-color: var(--kite-focus-ring-color);
}

// This is the default outline, and is meant to keep the rule as low specificity
// as possible to so that `outline: 0;` in Components with custom outlines
// will clobber it more easily
.js-focus-visible .focus-visible {
	@include kite-focus-outline();
}

// TODO(dura): Enable always showing focus styles for accessibility
.js-focus-visible {
	.kite-focus--always {
		.kite-theme--dark :focus {
			outline-color: var(--kite-focus-ring-color-dark);
		}

		.kite-theme--light :focus {
			outline-color: var(--kite-focus-ring-color);
		}

		:focus {
			@include kite-focus-outline();
		}
	}
}
