@import '../config';

/// Declares default focus outline
/// @access private
@mixin kite-focus-outline() {
	outline: var(--kite-focus-ring-width) var(--kite-focus-ring-style) var(--kite-focus-ring-color);
	outline-offset: var(--kite-focus-ring-offset);
}

/// Generates nested declarations to use `focus-visible`.
/// @access private
/// @example scss
/// 	.foo {
/// 		@include kite-focus-visible {
/// 			color: red;
/// 		}
/// 	}
/// 	// output
/// 	.kite-theme--light .foo.focus-visible,
/// 	.foo.focus-visible {
/// 		color: red;
/// 	}
@mixin kite-focus-visible() {
	$parent-selector: &;
	@if ($parent-selector) {
		&.focus-visible {
			@content;
		}
	} @else {
		@at-root {
			.js-focus-visible .focus-visible {
				@content;
			}
		}
	}
}

/// Generates nested declarations to hide focus styles
/// @access private
@mixin kite-focus-hidden($hidden: true) {
	// If the global variable does not exist, or it is exactly `false`
	@if _undefined('kite-css--enable-focus-visible') or ($kite-css--enable-focus-visible == false) {
		$parent-selector: &;
		// TODO(dura): How to work in `kite-focus--always`?
		@at-root .kite-focus--hidden {
			@if ($parent-selector) {
				#{$parent-selector} {
					@content;
				}
			} @else {
				@content;
			}
		}
	} @else {
		// TODO(dura): Add focus-visible styles for polyfill
	}
}
