@use 'sass:math';
@import 'vars';

// TODO(dura): In IE10-11, `flex-basis` doesn't support `box-sizing: border-box`
// https://github.com/philipwalton/flexbugs#7-flex-basis-doesnt-account-for-box-sizingborder-box
// Can remove the `width` declaration when we don't support < IE11

@mixin _kite-partial-size($size, $bp, $columns, $gridPrefix: $prefix) {
	.#{$gridPrefix}-cell-#{$size},
	.#{$gridPrefix}-cell-#{$size}-#{$bp}.#{$gridPrefix}-cell-#{$size}-#{$bp} {
		//flex: 0 0 #{(($size / $columns) * 100) + '%'};
		flex: 0 0 auto;
		width: #{(math.div($size, $columns) * 100) + '%'};
	}
}

@mixin _kite-full-size($size, $bp, $gridPrefix: $prefix) {
	.#{$gridPrefix}-cell-#{$size},
	.#{$gridPrefix}-cell-#{$size}-#{$bp} {
		//flex: 0 0 100%;
		flex: 0 0 auto;
		width: 100%;
	}
}

@mixin _kite-container-partial-size($size, $bp, $columns, $gridPrefix: $prefix) {
	.#{$gridPrefix}-cell-#{$size},
	.#{$gridPrefix}-cell-#{$size}-#{$bp}.#{$gridPrefix}-cell-#{$size}-#{$bp} {
		//flex: 0 0 #{(($size / $columns) * 100) + '%'};
		flex: 0 0 auto;
		width: #{(math.div($size, $columns) * 100) + 'cqw'};
	}
}

@mixin _kite-container-full-size($size, $bp, $gridPrefix: $prefix) {
	.#{$gridPrefix}-cell-#{$size},
	.#{$gridPrefix}-cell-#{$size}-#{$bp} {
		//flex: 0 0 100%;
		flex: 0 0 auto;
		width: 100cqw;
	}
}