
@import 'config';

// Print styles
// ---------------------------------------------------------

// Inlined to avoid the additional HTTP request:
// http://www.phpied.com/delay-loading-your-print-css/

/// Naive print styles
/// @access private
/// @group @kite/print
@mixin _kite-print {
	@media print {
		*,
		*::before,
		*::after,
		p::first-letter,
		div::first-letter,
		blockquote::first-letter,
		li::first-letter,
		p::first-line,
		div::first-line,
		blockquote::first-line,
		li::first-line {
			background: transparent !important;
			box-shadow: none !important;
			color: #000 !important; // Black prints faster: http://www.sanbeiji.com/archives/953
			text-shadow: none !important;
		}

		a,
		a:visited {
			text-decoration: underline;
		}

		a[href]::after {
			content: ' (' attr(href) ')';
		}

		abbr[title]::after {
			content: ' (' attr(title) ')';
		}

		// Don't show links that are fragment identifiers,
		// or use the `javascript:` pseudo protocol

		a[href^='#']::after,
		a[href^='javascript:']::after {
			content: '';
		}

		pre {
			white-space: pre-wrap !important;
		}

		pre,
		blockquote {
			border: 1px solid #999;
			page-break-inside: avoid;
		}

		// Printing Tables:
		// http://css-discuss.incutio.com/wiki/Printing_Tables

		thead {
			display: table-header-group;
		}

		tr,
		img {
			page-break-inside: avoid;
		}

		p,
		h2,
		h3 {
			orphans: 3;
			widows: 3;
		}

		h2,
		h3 {
			page-break-after: avoid;
		}
	}
}

// If the global variable does not exist, or it is exactly `false`
@if _undefined('kite-css--exclude-print') or ($kite-css--exclude-print == false) {
	@include _kite-print();
}
