@use 'sass:map';
@use 'sass:meta';
@use '../../components/link/mixins' as link;

@import '../helpers';
@import '../breakpoint';
@import '../reset';
@import '../vars';
@import 'vars';

/// Get the font-family for a Kite font
/// @param {String} $name
/// @param {Map} $font-families [$kite-font-families]
/// @return {String}
/// @access public
/// @group @kite/type
@function kite-font-family($name: 'sans-serif', $font-families: $kite-font-families) {
	@return map-get-strict($font-families, $name);
}

/// Retrieve the font-weight value for a given name
/// @param {String} $weight
/// @param {Map} $font-weights [$kite-font-weights]
/// @return {Number}
/// @access public
/// @group @kite/type
@function kite-font-weight($weight, $font-weights: $kite-font-weights) {
	@return map-get-strict($font-weights, $weight);
}

/// Resets and removes some of the default styling for lists
/// Only used on `ul` and `ol` to remove some styling for `li`
/// @access public
/// @group @kite/type
@mixin kite-list-reset {
	@include kite-reset();

	> li {
		line-height: inherit;
		margin: 0;
		padding: 0;
	}
}

/// Get the specified type style, with responsive breakpoint(s).
/// @access public
/// @param {String} $style - The name of the style
/// @param {String} $stack ['sm'] - The stack to use as default
/// @param {Bool | Map} $responsive [true] - Specify whether to include responsive styles. If `false`, only the default style will be used, with no media query. Pass a map of responsive breakpoints and stack sizes respectively: `('breakpoint': 'stack', 'breakpoint 2': 'stack 2', ...)`
/// @param {Map} $type-styles [$kite-type-styles] - map object containing style values
/// @example @include kite-type-style($kite-type-styles--rutledge, 'eyebrow'); // Use the 'eyebrow' style from the default 'sm' stack
/// @example @include kite-type-style($kite-type-styles--rutledge-alt, 'eyebrow', 'lg'); Use the 'eyebrow' style from the 'lg' stack
/// @example @include kite-type-style($kite-type-styles--rutledge-alt, 'eyebrow', $responsive: ('xs': 'md')); // At the 'xs' breakpoint, use the 'md' type stack
/// @example @include kite-type-style($kite-type-styles--rutledge-alt, 'eyebrow', $responsive: ('xl': 'md')); // At the 'xl' breakpoint, use the 'md' type stack
/// @group @kite/type
/// @see _kite-type-style-exact
@mixin kite-type-style($style, $type-styles: $kite-type-styles, $stack: 'sm', $responsive: true) {

	// Get the style for the default stack
	@include _kite-type-style-exact( $style, $type-styles, $stack);

	// Get any responsive stacks
	@if ($responsive != false) {
		@if (type-of($responsive) == 'map') or ($responsive == true) {
			@if ($responsive == true) {
				$responsive: ('md': 'lg'); // default responsive map
			}
			@each $key, $value in $responsive {
				@include kite-breakpoint-up($key) {
					@include _kite-type-style-exact($style, $type-styles, $value);
				}
			}
		} @else {
			@error 'The `$responsive` parameter must be of type `map` or `boolean`!';
		}
	}
}
/// Returns a style for the specified stack
/// @access private
/// @param {String} $style - The name of the style
/// @param {String} $stack ['sm'] - The stack to use as default
/// @param {Map} $type-styles [$kite-type-styles] - map object containing style values
/// @example @include _kite-type-style-exact('eyebrow', 'lg'); Get the 'eyebrow' style from the 'lg' stack
/// @group @kite/type
@mixin _kite-type-style-exact($style, $type-styles: $kite-type-styles, $stack: 'sm') {
	$stack-map: map-get-strict($type-styles, $stack);
	@if not map.has-key($stack-map, $style) {
		@error 'Invalid `#{$style}` style specified! Choose one of `#{map.keys($stack-map)}`.';
	}

	// You have to initialize vars to reassign them in @if/@else
	$props: map-get-strict($stack-map, $style);

	// font-size: map-get($props, font-size);
	// font-weight: map-get($props, font-weight);
	// letter-spacing: map-get($props, letter-spacing);
	// line-height: map-get($props, line-height);
	// margin: map-get($props, margin);
	// text-decoration: map-get($style-props, text-decoration);
	// text-transform: map-get($style-props, text-transform);
	@each $key, $value in $props {
		#{$key}: $value;
	}
}

/// Adds an ellipsis (...) to the end of a text element.
/// @access public
/// @param {Integer} $line - How many lines to truncate before overflowing
/// @group @kite/type
@mixin kite-typography-overflow-ellipsis($line: 1) {
	overflow: hidden;
	@if $line==1 {
		text-overflow: ellipsis;
		white-space: nowrap;
	} @else {
		display: box;
		/* stylelint-disable */
		display: -webkit-box;
		-webkit-line-clamp: $line;
		-webkit-box-orient: vertical;
		/* stylelint-enable */
		box-orient: vertical;
	}
}

/// Include a particular type styles stack
/// @param {String} $font-family
/// @param {Map} $type-styles[$kite-font-families]
/// @return {String}
/// @access public
/// @group @kite/type
@mixin kite-type-stack( $font-family: $kite-font-family, $type-styles: $kite-type-styles) {

	// Headings
	// ---------------------------
	h1,
	.#{$prefix}-type-style--title-1 {
		@include kite-type-style('title-1', $type-styles);
	}

	h2,
	.#{$prefix}-type-style--title-2 {
		@include kite-type-style('title-2', $type-styles);
	}

	h3,
	.#{$prefix}-type-style--title-3 {
		@include kite-type-style('title-3', $type-styles);
	}

	h4,
	.#{$prefix}-type-style--title-4 {
		@include kite-type-style('title-4', $type-styles);
	}

	h5,
	.#{$prefix}-type-style--title-5 {
		@include kite-type-style('title-5', $type-styles);
	}

	h6,
	.#{$prefix}-type-style--title-6 {
		@include kite-type-style('title-6', $type-styles);
	}

	.#{$prefix}-type-style--eyebrow {
		@include kite-type-style('eyebrow', $type-styles);
	}

	// Enable using a different font-family for headings
	h1,
	.#{$prefix}-type-style--title-1,
	h2,
	.#{$prefix}-type-style--title-2,
	h3,
	.#{$prefix}-type-style--title-3,
	h4,
	.#{$prefix}-type-style--title-4,
	h5,
	.#{$prefix}-type-style--title-5,
	h6,
	.#{$prefix}-type-style--title-6,
	.#{$prefix}-type-style--eyebrow {
		font-family: $font-family;
	}

	// Body
	// ---------------------------
	.#{$prefix}-type-style--body {
		@include kite-type-style('body', $type-styles);;
	}

	p {
		@include kite-type-style('body', $type-styles);
	}

	// Captions
	// ---------------------------
	figcaption,
	.#{$prefix}-type-style--caption {
		@include kite-type-style('caption', $type-styles);
	}

	// Display styles
	// ---------------------------
	.#{$prefix}-type-style--display {
		@include kite-type-style('display', $type-styles);
	}

	.#{$prefix}-type-style--body-display {
		@include kite-type-style('body-display', $type-styles);
	}

	// Links (basic inline, see Link component for custom styles)
	// ---------------------------
	@include link.default('a');

	// Lists
	// ---------------------------
	ol,
	ul,
	.#{$prefix}-list {
		margin: 0 0 var(--kite-spacing-element-xl) 0;
		padding: 0 0 0 var(--kite-spacing-element-xl);

		&.#{$prefix}-list--alpha {
			list-style: lower-alpha;
		}

		&.#{$prefix}-list--unstyled {
			list-style: none;
			padding-left: 0;

			> li {
				padding-left: 0;
			}
		}

		&.#{$prefix}-reset {
			@include kite-list-reset();
		}

		> li {
			line-height: 1.5;
			margin-bottom: var(--kite-spacing-element-sm);
			padding-left: var(--kite-spacing-element-xs);
		}
	}

	dl {
		margin: 0 0 var(--kite-spacing-element-xl) 0;
		padding: 0;
	}

	dt {
		font-weight: var(--kite-font-weight-bold);
	}

	dd {
		margin: 0 0 var(--kite-spacing-element-xs) 0;
	}

	// Horizontal rules
	// ---------------------------
	hr {
		border: 0;
		border-top: var(--kv-hr-border-width) solid var(--kite-border-color);
		margin-top: var(--kite-spacing-element-xl);
		margin-bottom: var(--kite-spacing-element-xl);
	}

	// Blockquotes
	// ---------------------------
	blockquote,
	.#{$prefix}-blockquote {
		@include kite-type-style('body-display', $type-styles);
		border-left: var(--kv-blockquote-border-width) solid var(--kv-blockquote-border-color);
		margin-bottom: var(--kite-spacing-element-md);
		padding: var(--kite-spacing-element-xs) var(--kite-spacing-element-md);
	}

	.#{$prefix}-blockquote__footer {
		color: var(--kv-blockquote-cite-color);
		display: block;
		font-size: 80%; // back to default font-size

		&::before {
			content: '\2014 \00A0'; // em dash, nbsp
		}
	}

	// Misc styles
	// ---------------------------

	// Emphasis
	mark,
	.#{$prefix}-mark {
		background-color: var(--kite-color-yellow-10);
	}

	// Abbreviations
	abbr[title] {
		border-bottom: 0;
		cursor: help;
		text-decoration: underline;
		text-decoration: underline dotted;
	}
}
