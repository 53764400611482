/// Include styles for screen reader/assistive text
// see https://webaim.org/techniques/css/invisiblecontent/
// https://www.a11yproject.com/posts/2013-01-11-how-to-hide-content/
// https://accessibility.18f.gov/hidden-content/
/// @access public
/// @group @kite/utilities
@mixin kite-assistive-text {
	border: 0;
	clip: rect(0, 0, 0, 0);
	clip-path: inset(50%);
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	white-space: nowrap; // prevent SR stripping white space
	width: 1px; // width 0 will be ignored by SR
	height: 1px; // height 0 will be ignore by SR
}

/// @access public
/// @alias kite-assistive-text
/// @group @kite/utilities
@mixin kite-visually-hidden {
	@include kite-assistive-text();
}

/// Reset styles set by `kite-assistive-text()`
/// Useful for showing assistive text on `:focus`, or with `prefers-reduced-motion`
/// @access public
/// @group @kite/utilities
@mixin kite-assistive-text-reset {
	clip: auto;
	clip-path: unset;
	margin: 0;
	overflow: visible;
	position: static;
	width: auto;
	height: auto;
}
/// @access public
/// @alias kite-assistive-text-reset
/// @group @kite/utilities
@mixin kite-visually-hidden-reset {
	@include kite-assistive-text-reset();
}

/// Include styles for screen reader/assistive text to only
/// display when it is focused (for example, by a keyboard user)
/// @access public
/// @group @kite/utilities
@mixin kite-assistive-text-focusable {
	&:active,
	&:focus {
		@include kite-assistive-text-reset();
	}
}
/// @access public
/// @alias kite-assistive-text-focusable
/// @group @kite/utilities
@mixin kite-visually-hidden-focusable {
	@include kite-assistive-text-focusable();
}

/// Include styles for screen reader/assistive text
/// @access public
/// @group @kite/utilities
@mixin _kite-assistive {
	.kite-assistive-text,
	.kite-visually-hidden {
		@include kite-assistive-text();

		&--focusable {
			@include kite-assistive-text-focusable();
		}
	}
}
