@import 'convert';
@import 'helpers';
@import 'grid/vars';

/// Check to see if the given breakpoint name is the smallest breakpoint
/// @param {String} $name - The name of the breakpoint
/// @param {Map} $breakpoints [$kite-grid-breakpoints] - A map of breakpoints where the key is the name of the breakpoint and the value is the values for the breakpoint
/// @return {Bool}
/// @access private
/// @group @kite/layout
@function _kite-breakpoint-is-smallest(
	$name,
	$breakpoints: $kite-grid-breakpoints
) {
	@return index(map-keys($breakpoints), $name) == 1;
}

/// Check to see if the given breakpoint name is the largest breakpoint
/// @param {String} $name - The name of the breakpoint
/// @param {Map} $breakpoints [$kite-grid-breakpoints] - A map of breakpoints where the key is the name of the breakpoint and the value is the values for the breakpoint
/// @return {Bool}
/// @access private
/// @group @kite/layout
@function _kite-breakpoint-is-largest(
	$name,
	$breakpoints: $kite-grid-breakpoints
) {
	@return index(map-keys($breakpoints), $name) == length(map-keys($breakpoints));
}

/// Minimum breakpoint width. `Null` for the smallest (first) breakpoint.
/// @param {String} $name - The name of the breakpoint
/// @param {Map} $breakpoints [$kite-grid-breakpoints] - Map of available breakpoints
/// @return {Number} - The minimum width of the breakpoint
/// @access public
/// @group @kite/layout
@function kite-breakpoint-min($name, $breakpoints: $kite-grid-breakpoints) {
	$min: map-deep-get($breakpoints, $name, 'width');
	@return if($min != 0, $min, null);
}

/// Generate a media query of at least the minimum width from the given breakpoint name.
/// No query for the smallest breakpoint.
/// Makes the `@content` apply to the given breakpoint and wider.
/// @param {String | Number} $name
/// @param {Map} $breakpoints [$kite-grid-breakpoints] - A map of breakpoints where the key is the name
/// @content
/// @access public
/// @group @kite/layout
@mixin kite-breakpoint-up($name, $breakpoints: $kite-grid-breakpoints) {
	@if type-of($name) == 'number' {
		@media (min-width: $name) {
			@content;
		}
	} @else if map-has-key($breakpoints, $name) {
		$breakpoint: map-get($breakpoints, $name);
		$width: map-get($breakpoint, width);
		@if _kite-breakpoint-is-smallest($name, $breakpoints) {
			@content;
		} @else {
			@media only screen and (min-width: $width) {
				@content;
			}
		}
	} @else {
		@error 'Unable to find a breakpoint with name `#{$name}`. Expected one of: (#{map-keys($breakpoints)})';
	}
}

/// Generate a container query of at least the minimum width from the given breakpoint name.
/// No query for the smallest breakpoint.
/// Makes the `@content` apply to the given breakpoint and wider.
/// @param {String | Number} $name
/// @param {Map} $breakpoints [$kite-grid-breakpoints] - A map of breakpoints where the key is the name
/// @content
/// @access public
/// @group @kite/layout
@mixin kite-container-breakpoint-up($name, $containerName: null, $breakpoints: $kite-grid-breakpoints) {
	@if type-of($name) == 'number' {
		@supports not (container-type: inline-size) {
			@media only screen and (min-width: $width) {
				@content
			}
		}
		@container #{$containerName} (min-width: $name) {
			@content;
		}
	} @else if map-has-key($breakpoints, $name) {
		$breakpoint: map-get($breakpoints, $name);
		$width: map-get($breakpoint, width);
		@if _kite-breakpoint-is-smallest($name, $breakpoints) {
			@content;
		} @else {
			/* media query fallback */
			@supports not (container-type: inline-size) {
				@media only screen and (min-width: $width) {
					@content
				}
			}					
			@container #{$containerName} (min-width: #{$width}) {
				@content;
			}
		}
	} @else {
		@error 'Unable to find a breakpoint with name `#{$name}`. Expected one of: (#{map-keys($breakpoints)})';
	}
}