@charset 'utf-8';

// TODO(dura): Global stylesheet
// Stencil is traditionally used to compile many components into an app, and each component comes with its own compartmentalized styles. However, it's still common to have styles which should be "global" across all components and the website.

:root {
	--my-primary-color: green;
}

// Tokens, only want these import statements referenced once because, unlike
// scss variables, `:root{...}` outputs CSS!!!

// TODO(dura): This is the `@kite/tokens/dis/web/beam/variables-tokens.css` file,
// but with a `.scss` extension to avoid compile errors. Fix the tooling so that
// we can ingest it directly as the `.css` version (or transform it at compile time).
// @import '@kite/tokens/dist/web/beam/variables-deep.css';
@import '@kite/tokens/dist/web/beam/vanilla-variables-deep.scss';
@import 'tokens/override-deep.scss';
@import 'tokens/shame';

:root {
	/* --kite-icon-inline-offset: Offset to visually center-align icons inline with Rutledge, and default line-heights */
	--kite-icon-inline-offset: -0.1em;
}

/* This adds theme reference root to allow for container based theming, to remove if tokens to include them in dist*/
.kite-theme {
	@extend :root
}

// 🌍 Global
//-------------------------

@import 'config';
@import 'vars';
@import 'print';
@import 'font-face';
@import 'reboot';
@import 'focus';
@import 'typography';
@import 'body';
@import 'layout';
@import 'grid';
@import 'form';

// 🔩 Utilities
// ------------------------

@import 'util';

// 🎨 Theme
// ------------------------

@import 'theme';
