/* You can add global styles to this file, and also import other style files */
@import '@kite/vanilla/scss/global';
@import '@angular/cdk/overlay-prebuilt.css';
@import '@kite/theme/kite-theme';

body {
    background: $kite-gray-1;
    height: 100vh;
    margin: 0;
    padding: 0;
}
.cdk-overlay-pane{
  background-color: $kite-white;
}

.landing-header {
    display: inline-block
}

label.form-label {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
}

.form-label, .form-input {
  margin-bottom: 8px;
  display: block;
}

input.form-input {
  min-width: 90px;
  margin-bottom: 10px;
  padding: 13px 9px;
  border-radius: 0;
  border: 1px solid $kite-gray-3;
}
