@import 'mixins';

// This file provides the built-in themes.
// Some themes may also be provided as standalone convenience files in `src/global/theme`.

// Dark Theme

// If the global variable exists, and it is exactly `true`
// @if (not _undefined('kite-css--include-theme-media-query')) and ($kite-css--include-theme-media-query == true) {
// 	@include _kite-theme('dark', $kite-css--include-theme-media-query);
// 	@media (prefers-color-scheme: dark) {
// 		@include _kite-theme($theme);
// 	}
// }

// TODO(dura): Mixin to generate the class name? Not really necessary...
.kite-theme--dark {
	--kite-color-background: var(--kite-color-background-dark);
	--kite-color-text: var(--kite-color-text-dark);

	// Component theme styles are kept with the component
}
