@import '../config';
@import '../vars';
@import 'mixins';

// Defaults to Sans and use Ruledge if enabled
$kite-type-styles: $kite-type-styles--spectrum-sans !default;
$kite-font-family: kite-font-family('spectrum-sans') !default;
$kite-font-weights: $kite-font-weights--spectrum-sans !default;

@if not _undefined('kite-css--enable-rutledge') and ($kite-css--enable-rutledge == true) {
	$kite-type-styles: $kite-type-styles--rutledge;
	$kite-font-family: kite-font-family('rutledge');
	$kite-font-weights: $kite-font-weights--rutledge;
}

// If the global variable does not exist, or it is exactly `false`
@if _undefined('kite-css--enable-typography-namespace') or ($kite-css--enable-typography-namespace == false) {
	@include kite-type-stack();
} @else {
	.#{$prefix}-typography {
		@include kite-type-stack();
		@include kite-type-style('body');

		// text-rendering: optimizeLegibility; // TODO(dura): Test this setting
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}
}

// TODO(dura): Sticker sheet styles DO NOT USE IN PRODUCTION
// Add specifically to sticker sheet only
.#{$prefix}-typography-sm {
	h1,
	.#{$prefix}-type-style--title-1 {
		@include kite-type-style('title-1', $responsive: false);
	}

	h2,
	.#{$prefix}-type-style--title-2 {
		@include kite-type-style('title-2', $responsive: false);
	}

	h3,
	.#{$prefix}-type-style--title-3 {
		@include kite-type-style('title-3', $responsive: false);
	}

	h4,
	.#{$prefix}-type-style--title-4 {
		@include kite-type-style('title-4', $responsive: false);
	}

	h5,
	.#{$prefix}-type-style--title-5,
	h6,
	.#{$prefix}-type-style--title-6 {
		@include kite-type-style('title-5', $responsive: false);
	}

	.#{$prefix}-type-style--eyebrow {
		@include kite-type-style('eyebrow', $responsive: false);
	}

	.#{$prefix}-type-style--body {
		@include kite-type-style('body', $responsive: false);
	}

	figcaption,
	.#{$prefix}-type-style--caption {
		@include kite-type-style('caption', $responsive: false);
	}

	.#{$prefix}-type-style--display {
		@include kite-type-style('display', $responsive: false);
	}

	.#{$prefix}-type-style--body-display {
		@include kite-type-style('body-display', $responsive: false);
	}
}
