
// TODO(dura): This is here because Stencil components import this `_vars.scss` file, and
// they won't compile without these scss variables. We are refactoring components to use the
// --css-variable tokens instead of the $scss-variable tokens, so we should be able to
// remove this after that is complete.
// However, if scss vars are still needed to do calculations in pre-processing,
// then, we'll need to import the entire set as below, or provide a shortlist in our
// own variables.scss tokens file.
// TODO(dura): May want to switch to variables-deep.scss
@import '@kite/tokens/dist/web/beam/variables.scss';

// Import SCSS shame variables.
@import './tokens/shame-scss';

/// Sets the prefix for all CSS classes
/// @access private
/// @group @kite/config
/// @example .#{$prefix}-button--primary
/// @example .kite-button--primary
$prefix: 'kite' !default;

/// Default font size
/// @type Number
/// @access public
/// @group @kite/type
$kite-font-size-base: 16px !default;
