
@import 'config';
@import 'helpers';
@import 'typography/vars';

/// Path to the Rutledge font files.
/// We recommend using your own CDN, or bundling the font files with your code.
/// @access public
/// @group @kite/fonts
$kite-font-path--rutledge: 'https://cdn.spectrumflow.net/kite/fonts/rutledge' !default;

/// Path to the Spectrum Sans font files.
/// We recommend using your own CDN, or bundling the font files with your code.
/// @access public
/// @group @kite/fonts
$kite-font-path--spectrum-sans: 'https://cdn.spectrumflow.net/kite/fonts/spectrum-sans' !default;

$kite-font-path: $kite-font-path--spectrum-sans !default;

/// Defines how font files are loaded and displayed by the browser
/// @link https://css-tricks.com/almanac/properties/f/font-display/
/// @access public
/// @group @kite/fonts
$kite-font-display: auto !default;

/// Checks that you're not using our demo CDN
/// @access private
/// @group @kite/fonts
@mixin _check-font-path {
	@if (str-index($kite-font-path, 'https://cdn-stage.spectrumflow.net') == 1) {
		@warn 'The default font path (#{$kite-font-path}) should be used only for demonstration/evaluation purposes. For production applications, please host fonts in your own CDN and change `$kite-font-path` accordingly.';
		@content;
	} @else {
		@content;
	}
}

/// Rutledge `@font-face`
/// @access public
/// @group @kite/fonts
@mixin kite-font-face-rutledge() {
	@include _check-font-path {
		// Light
		@font-face {
			font-family: 'Rutledge';
			font-style: normal;
			font-weight: map-get-strict($kite-font-weights--rutledge, 'light');
			font-display: $kite-font-display;
			src: local('Rutledge Light'),
				local('Rutledge-Light'),
				url('#{$kite-font-path}/rutledge-light.woff2') format('woff2'),
				url('#{$kite-font-path}/rutledge-light.woff') format('woff');
		}
		@font-face {
			font-family: 'Rutledge';
			font-style: italic;
			font-weight: map-get-strict($kite-font-weights--rutledge, 'light');
			font-display: $kite-font-display;
			src: local('Rutledge Light Italic'),
				local('Rutledge-LightItalic'),
				url('#{$kite-font-path}/rutledge-lightitalic.woff2') format('woff2'),
				url('#{$kite-font-path}/rutledge-lightitalic.woff') format('woff');
		}

		// Regular
		@font-face {
			font-family: 'Rutledge';
			font-style: normal;
			font-weight: map-get-strict($kite-font-weights--rutledge, 'regular');
			font-display: $kite-font-display;
			src: local('Rutledge Regular'),
				local('Rutledge-Regular'),
				url('#{$kite-font-path}/rutledge-regular.woff2') format('woff2'),
				url('#{$kite-font-path}/rutledge-regular.woff') format('woff');
		}
		@font-face {
			font-family: 'Rutledge';
			font-style: italic;
			font-weight: map-get-strict($kite-font-weights--rutledge, 'regular');
			font-display: $kite-font-display;
			src: local('Rutledge Regular Italic'),
				local('Rutledge-RegularItalic'),
				url('#{$kite-font-path}/rutledge-regularitalic.woff2') format('woff2'),
				url('#{$kite-font-path}/rutledge-regularitalic.woff') format('woff');
		}

		// Medium
		@font-face {
			font-family: 'Rutledge';
			font-style: normal;
			font-weight: map-get-strict($kite-font-weights--rutledge, 'medium');
			font-display: $kite-font-display;
			src: local('Rutledge Medium'),
				local('Rutledge-Medium'),
				url('#{$kite-font-path}/rutledge-medium.woff2') format('woff2'),
				url('#{$kite-font-path}/rutledge-medium.woff') format('woff');
		}
		@font-face {
			font-family: 'Rutledge';
			font-style: italic;
			font-weight: map-get-strict($kite-font-weights--rutledge, 'medium');
			font-display: $kite-font-display;
			src: local('Rutledge Medium Italic'),
				local('Rutledge-MediumItalic'),
				url('#{$kite-font-path}/rutledge-mediumitalic.woff2') format('woff2'),
				url('#{$kite-font-path}/rutledge-mediumitalic.woff') format('woff');
		}

		// Bold
		@font-face {
			font-family: 'Rutledge';
			font-style: normal;
			font-weight: map-get-strict($kite-font-weights--rutledge, 'bold');
			font-display: $kite-font-display;
			src: local('Rutledge Bold'),
				local('Rutledge-Bold'),
				url('#{$kite-font-path}/rutledge-bold.woff2') format('woff2'),
				url('#{$kite-font-path}/rutledge-bold.woff') format('woff');
		}
		@font-face {
			font-family: 'Rutledge';
			font-style: italic;
			font-weight: map-get-strict($kite-font-weights--rutledge, 'bold');
			font-display: $kite-font-display;
			src: local('Rutledge Bold Italic'),
				local('Rutledge-BoldItalic'),
				url('#{$kite-font-path}/rutledge-bolditalic.woff2') format('woff2'),
				url('#{$kite-font-path}/rutledge-bolditalic.woff') format('woff');
		}
	}
}

/// Spectrum Sans `@font-face`, variable font with woff/2 fallbacks.
/// @access public
/// @group @kite/fonts
@mixin kite-font-face-spectrum-sans() {
	// Light
	@font-face {
		font-family: 'Spectrum Sans';
		font-style: normal;
		font-weight: map-get-strict($kite-font-weights--spectrum-sans, 'light');
		font-display: $kite-font-display;
		src: local('Spectrum Sans Light'),
			local('SpectrumSans-Light'),
			url('#{$kite-font-path--spectrum-sans}/SpectrumSansVF_W_Wght.woff2') format('woff2-variations'),
			url('#{$kite-font-path--spectrum-sans}/SpectrumSans_W_Lt.woff2') format('woff2'),
			url('#{$kite-font-path--spectrum-sans}/SpectrumSans_W_Lt.woff') format('woff');
	}
	@font-face {
		font-family: 'Spectrum Sans';
		font-style: italic;
		font-weight: map-get-strict($kite-font-weights--spectrum-sans, 'light');
		font-display: $kite-font-display;
		src: local('Spectrum Sans Light Italic'),
			local('SpectrumSans-LightItalic'),
			url('#{$kite-font-path--spectrum-sans}/SpectrumSansVF_W_Italics_Wght.woff2') format('woff2-variations'),
			url('#{$kite-font-path--spectrum-sans}/SpectrumSans_W_LtIt.woff2') format('woff2'),
			url('#{$kite-font-path--spectrum-sans}/SpectrumSans_W_LtIt.woff') format('woff');
	}

	// Book
	@font-face {
		font-family: 'Spectrum Sans';
		font-style: normal;
		font-weight: map-get-strict($kite-font-weights--spectrum-sans, 'book');
		font-display: $kite-font-display;
		src: local('Spectrum Sans Book'),
			local('SpectrumSans-Book'),
			url('#{$kite-font-path--spectrum-sans}/SpectrumSansVF_W_Wght.woff2') format('woff2-variations'),
			url('#{$kite-font-path--spectrum-sans}/SpectrumSans_W_Bk.woff2') format('woff2'),
			url('#{$kite-font-path--spectrum-sans}/SpectrumSans_W_Bk.woff') format('woff');
	}
	@font-face {
		font-family: 'Spectrum Sans';
		font-style: italic;
		font-weight: map-get-strict($kite-font-weights--spectrum-sans, 'book');
		font-display: $kite-font-display;
		src: local('Spectrum Sans Book Italic'),
			local('SpectrumSans-BookItalic'),
			url('#{$kite-font-path--spectrum-sans}/SpectrumSansVF_W_Wght.woff2') format('woff2-variations'),
			url('#{$kite-font-path--spectrum-sans}/SpectrumSans_W_BkIt.woff2') format('woff2'),
			url('#{$kite-font-path--spectrum-sans}/SpectrumSans_W_BkIt.woff') format('woff');
	}

	// Regular & Italic
	@font-face {
		font-family: 'Spectrum Sans';
		font-style: normal;
		font-weight: map-get-strict($kite-font-weights--spectrum-sans, 'regular');
		font-display: $kite-font-display;
		src: local('Spectrum Sans Regular'),
			local('SpectrumSans-Regular'),
			url('#{$kite-font-path--spectrum-sans}/SpectrumSansVF_W_Wght.woff2') format('woff2-variations'),
			url('#{$kite-font-path--spectrum-sans}/SpectrumSans_W_Rg.woff2') format('woff2'),
			url('#{$kite-font-path--spectrum-sans}/SpectrumSans_W_Rg.woff') format('woff');
	}
	@font-face {
		font-family: 'Spectrum Sans';
		font-style: italic;
		font-weight: map-get-strict($kite-font-weights--spectrum-sans, 'regular');
		font-display: $kite-font-display;
		src: local('Spectrum Sans Italic'),
			local('SpectrumSans-Italic'),
			url('#{$kite-font-path--spectrum-sans}/SpectrumSansVF_W_Wght.woff2') format('woff2-variations'),
			url('#{$kite-font-path--spectrum-sans}/SpectrumSans_W_It.woff2') format('woff2'),
			url('#{$kite-font-path--spectrum-sans}/SpectrumSans_W_It.woff') format('woff');
	}

	// Medium
	@font-face {
		font-family: 'Spectrum Sans';
		font-style: normal;
		font-weight: map-get-strict($kite-font-weights--spectrum-sans, 'medium');
		font-display: $kite-font-display;
		src: local('Spectrum Sans Medium'),
			local('SpectrumSans-Medium'),
			url('#{$kite-font-path--spectrum-sans}/SpectrumSansVF_W_Wght.woff2') format('woff2-variations'),
			url('#{$kite-font-path--spectrum-sans}/SpectrumSans_W_Md.woff2') format('woff2'),
			url('#{$kite-font-path--spectrum-sans}/SpectrumSans_W_Md.woff') format('woff');
	}
	@font-face {
		font-family: 'Spectrum Sans';
		font-style: italic;
		font-weight: map-get-strict($kite-font-weights--spectrum-sans, 'medium');
		font-display: $kite-font-display;
		src: local('Spectrum Sans Medium Italic'),
			local('SpectrumSans-MediumItalic'),
			url('#{$kite-font-path--spectrum-sans}/SpectrumSansVF_W_Italics_Wght.woff2') format('woff2-variations'),
			url('#{$kite-font-path--spectrum-sans}/SpectrumSans_W_MdIt.woff2') format('woff2'),
			url('#{$kite-font-path--spectrum-sans}/SpectrumSans_W_MdIt.woff') format('woff');
	}

	// Bold
	@font-face {
		font-family: 'Spectrum Sans';
		font-style: normal;
		font-weight: map-get-strict($kite-font-weights--spectrum-sans, 'bold');
		font-display: $kite-font-display;
		src: local('Spectrum Sans Bold'),
			local('SpectrumSans-Bold'),
			url('#{$kite-font-path--spectrum-sans}/SpectrumSansVF_W_Wght.woff2') format('woff2-variations'),
			url('#{$kite-font-path--spectrum-sans}/SpectrumSans_W_Bd.woff2') format('woff2'),
			url('#{$kite-font-path--spectrum-sans}/SpectrumSans_W_Bd.woff') format('woff');
	}
	@font-face {
		font-family: 'Spectrum Sans';
		font-style: italic;
		font-weight: map-get-strict($kite-font-weights--spectrum-sans, 'bold');
		font-display: $kite-font-display;
		src: local('Spectrum Sans Bold Italic'),
			local('SpectrumSans-BoldItalic'),
			url('#{$kite-font-path--spectrum-sans}/SpectrumSansVF_W_Italics_Wght.woff2') format('woff2-variations'),
			url('#{$kite-font-path--spectrum-sans}/SpectrumSans_W_BdIt.woff2') format('woff2'),
			url('#{$kite-font-path--spectrum-sans}/SpectrumSans_W_BdIt.woff') format('woff');
	}

	// Extra Bold
	@font-face {
		font-family: 'Spectrum Sans';
		font-style: normal;
		font-weight: map-get-strict($kite-font-weights--spectrum-sans, 'extrabold');
		font-display: $kite-font-display;
		src: local('Spectrum Sans ExtraBold'),
			local('SpectrumSans-ExtraBold'),
			url('#{$kite-font-path--spectrum-sans}/SpectrumSansVF_W_Wght.woff2') format('woff2-variations'),
			url('#{$kite-font-path--spectrum-sans}/SpectrumSans_W_XBd.woff2') format('woff2'),
			url('#{$kite-font-path--spectrum-sans}/SpectrumSans_W_XBd.woff') format('woff');
	}
	@font-face {
		font-family: 'Spectrum Sans';
		font-style: italic;
		font-weight: map-get-strict($kite-font-weights--spectrum-sans, 'extrabold');
		src: local('Spectrum Sans ExtraBold Italic'),
			local('SpectrumSans-ExtraBoldItalic'),
			url('#{$kite-font-path--spectrum-sans}/SpectrumSansVF_W_Italics_Wght.woff2') format('woff2-variations'),
			url('#{$kite-font-path--spectrum-sans}/SpectrumSans_W_XBdIt.woff2') format('woff2'),
			url('#{$kite-font-path--spectrum-sans}/SpectrumSans_W_XBdIt.woff') format('woff');
	}
}

// If the global variable does not exist, or it is exactly `false`
@if _undefined('kite-css--exclude-font-face') or ($kite-css--exclude-font-face == false) {
	@if (not _undefined('kite-css--enable-rutledge')) and ($kite-css--enable-rutledge == true) {
		$kite-font-path: $kite-font-path--rutledge;
		@include kite-font-face-rutledge();
	} @else {
		@include kite-font-face-spectrum-sans();
	}
}
