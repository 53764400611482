/// Resets default browser styling
/// @access public
/// @group global-reset
@mixin kite-reset {
	border: 0;
	box-sizing: border-box;
	font-family: inherit;
	font-size: 100%;
	margin: 0;
	padding: 0;
	vertical-align: baseline;

	*,
	*::before,
	*::after {
		box-sizing: inherit;
	}
}
