/**
  * Do not edit directly
  * Generated on Tue, 30 Nov 2021 21:41:46 GMT
  */

:root {
	--kite-color-dark-blue-30: #001019;
	--kite-color-dark-blue-20: #002133;
	--kite-color-dark-blue-10: #004366;
	--kite-color-blue-30: #0062b2;
	--kite-color-blue-20: #0073d1;
	--kite-color-blue-10: #008cff;
	--kite-color-light-blue-20: #5db8fc;
	--kite-color-light-blue-10: #b7cee5;
	--kite-color-green-20: #008516;
	--kite-color-green-10: #00bf1f;
	--kite-color-yellow-20: #feb533;
	--kite-color-yellow-10: #ffd400;
	--kite-color-red-20: #d6312b;
	--kite-color-red-10: #ff4d4a;
	--kite-color-gray-30: #63738a;
	--kite-color-gray-25: #9ba9bd;
	--kite-color-gray-20: #d8dde6;
	--kite-color-gray-10: #f8f8f8;
	--kite-color-black: #000000;
	--kite-color-white: #ffffff;
	--kite-color-primary: var(--kite-color-blue-20);
	--kite-color-primary-dark: var(--kite-color-blue-20);
	--kite-color-error: var(--kite-color-red-20);
	--kite-color-error-dark: var(--kite-color-red-10);
	--kite-color-negative: var(--kite-color-red-20);
	--kite-color-negative-dark: var(--kite-color-red-10);
	--kite-color-caution: var(--kite-color-yellow-20);
	--kite-color-caution-dark: var(--kite-color-yellow-20);
	--kite-color-info: var(--kite-color-light-blue-20);
	--kite-color-info-dark: var(--kite-color-light-blue-20);
	--kite-color-neutral: var(--kite-color-light-blue-20);
	--kite-color-neutral-dark: var(--kite-color-light-blue-20);
	--kite-color-success: var(--kite-color-green-20);
	--kite-color-success-dark: var(--kite-color-green-10);
	--kite-color-positive: var(--kite-color-green-20);
	--kite-color-positive-dark: var(--kite-color-green-10);
	--kite-color-disabled: var(--kite-color-gray-25);
	--kite-color-disabled-dark: var(--kite-color-gray-30);
	--kite-color-text: var(--kite-color-black);
	--kite-color-text-dark: var(--kite-color-white);
	--kite-color-background: var(--kite-color-gray-10);
	--kite-color-background-dark: var(--kite-color-dark-blue-30);
	--kite-percents-half: 50%;
	--kite-size-2: 0.125rem;
	--kite-size-14: 0.875rem;
	--kite-size-xxs: 0.25rem;
	--kite-size-xs: 0.5rem;
	--kite-size-sm: 0.75rem;
	--kite-size-md: 1rem;
	--kite-size-lg: 1.25rem;
	--kite-size-xl: 1.5rem;
	--kite-size-xxl: 2rem;
	--kite-size-xxxl: 2.5rem;
	--kite-size-touch-target: 2.75rem;
	--kite-size-radius-sm: 0.125rem;
	--kite-size-radius-md: 0.25rem;
	--kite-size-radius-lg: 0.5rem;
	--kite-size-radius-circle: var(--kite-percents-half);
	--kite-spacing-element-xxs: var(--kite-size-xxs);
	--kite-spacing-element-xs: var(--kite-size-xs);
	--kite-spacing-element-sm: var(--kite-size-sm);
	--kite-spacing-element-md: var(--kite-size-md);
	--kite-spacing-element-lg: var(--kite-size-lg);
	--kite-spacing-element-xl: var(--kite-size-xl);
	--kite-spacing-element-xxl: var(--kite-size-xxl);
	--kite-spacing-element-xxxl: var(--kite-size-xxxl);
	--kite-spacing-section-xs: 3rem;
	--kite-spacing-section-sm: 4rem;
	--kite-spacing-section-md: 5rem;
	--kite-spacing-section-lg: 6.25rem;
	--kite-spacing-section-xl: 7.5rem;
	--kite-font-family-sans-serif: Rutledge, -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
	--kite-font-family-monospace: Menlo, Monaco, 'Andale Mono', 'lucida console', 'Courier New', monospace;
	--kite-font-family-heading: var(--kite-font-family-sans-serif);
	--kite-font-family-text: var(--kite-font-family-sans-serif);
	--kite-font-weight-light: 200;
	--kite-font-weight-regular: 400;
	--kite-font-weight-medium: 500;
	--kite-font-weight-bold: 700;
	--kite-font-tracking-sm: -1px;
	--kite-tracking-md: normal;
	--kite-tracking-lg: var(--kite-size-2);
	--kite-tracking-sm: -1px;
	--kite-border-color: var(--kite-color-gray-20);
	--kite-border-color-dark: var(--kite-color-white);
	--kite-border-width-thin: 1px;
	--kite-border-width-thick: 2px;
	--kite-border-radius-sm: var(--kite-size-radius-sm);
	--kite-border-radius-md: var(--kite-size-radius-md);
	--kite-border-radius-lg: var(--kite-size-radius-lg);
	--kite-border-radius-circle: var(--kite-size-radius-circle);
	--kite-color-rgb-dark-blue-30: 0,  16,  25;
	--kite-color-rgb-dark-blue-20: 0, 33, 51;
	--kite-color-rgb-dark-blue-10: 0, 67, 102;
	--kite-color-rgb-blue-30: 0, 98, 178;
	--kite-color-rgb-blue-20: 0, 115, 209;
	--kite-color-rgb-blue-10: 0, 140, 255;
	--kite-color-rgb-light-blue-20: 93, 184, 252;
	--kite-color-rgb-light-blue-10: 183, 206, 229;
	--kite-color-rgb-green-20: 0, 133, 22;
	--kite-color-rgb-green-10: 0, 191, 31;
	--kite-color-rgb-yellow-20: 254, 181, 51;
	--kite-color-rgb-yellow-10: 255, 212, 0;
	--kite-color-rgb-red-20: 214, 49, 43;
	--kite-color-rgb-red-10: 255, 77, 74;
	--kite-color-rgb-gray-30: 99, 115, 138;
	--kite-color-rgb-gray-25: 155, 169, 189;
	--kite-color-rgb-gray-20: 216, 221, 230;
	--kite-color-rgb-gray-10: 248, 248, 248;
	--kite-color-rgb-black: 0, 0, 0;
	--kite-color-rgb-white: 255, 255, 255;
	--kite-motion-duration-fast: 300ms;
	--kite-motion-duration-faster: 225ms;
	--kite-motion-duration-fastest: 195ms;
	--kite-motion-curve-default: cubic-bezier(0.4, 0, 0.2, 1);
	--kite-motion-curve-deceleration: cubic-bezier(0, 0, 0.2, 1);
	--kite-motion-curve-acceleration: cubic-bezier(0.4, 0, 1, 1);
	--kite-motion-curve-sharp: cubic-bezier(0.4, 0, 0.6, 1);
	--kite-opacity-3: 0.3;
	--kite-opacity-8: 0.8;
	--kite-z-index-deepdive: -99999;
	--kite-z-index-default: 1;
	--kite-z-index-sticky: 1000;
	--kite-z-index-dropdown: 1100;
	--kite-z-index-dialog-backdrop: 1200;
	--kite-z-index-dialog: 1300;
	--kite-z-index-notification: 1600;
	--kite-cursor-action: pointer;
	--kite-cursor-disabled: not-allowed;
	--kite-elevation-0: none;
	--kite-elevation-0-dark: none;
	--kite-elevation-1: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
	--kite-elevation-1-dark: 0 1px 4px 0 rgba(0, 0, 0, 0.5);
	--kite-elevation-2: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
	--kite-elevation-2-dark: 0 2px 8px 0 rgba(0, 0, 0, 0.5);
	--kite-elevation-3: 0 3px 12px 0 rgba(0, 0, 0, 0.1);
	--kite-elevation-3-dark: 0 3px 12px 0 rgba(0, 0, 0, 0.5);
	--kite-focus-ring-color: var(--kite-color-blue-30);
	--kite-focus-ring-color-dark: var(--kite-color-white);
	--kite-focus-ring-offset: 0.125rem;
	--kite-focus-ring-inset: -0.125rem;
	--kite-focus-ring-style: solid;
	--kite-focus-ring-width: var(--kite-size-2);
	--kite-grid-xs-columns: 4;
	--kite-grid-xs-gutters: var(--kite-spacing-element-md);
	--kite-grid-xs-margins: var(--kite-spacing-element-md);
	--kite-grid-sm-columns: 4;
	--kite-grid-sm-gutters: var(--kite-spacing-element-md);
	--kite-grid-sm-margins: var(--kite-spacing-element-md);
	--kite-grid-md-columns: 4;
	--kite-grid-md-gutters: var(--kite-spacing-element-md);
	--kite-grid-md-margins: var(--kite-spacing-element-md);
	--kite-grid-lg-columns: 12;
	--kite-grid-lg-gutters: var(--kite-spacing-element-md);
	--kite-grid-lg-margins: var(--kite-spacing-element-xl);
	--kite-grid-xl-columns: 12;
	--kite-grid-xl-gutters: var(--kite-spacing-element-md);
	--kite-grid-xl-margins: var(--kite-spacing-element-xl);
	--kite-grid-xxl-columns: 12;
	--kite-grid-xxl-gutters: var(--kite-spacing-element-md);
	--kite-grid-xxl-margins: var(--kite-spacing-element-xl);
	--kite-media-query-xs: only screen and (max-width: 479px);
	--kite-media-query-sm: only screen and (min-width: 480px);
	--kite-media-query-md: only screen and (min-width: 600px);
	--kite-media-query-lg: only screen and (min-width: 840px);
	--kite-media-query-xl: only screen and (min-width: 960px);
	--kite-media-query-xxl: only screen and (min-width: 1280px);
	--kite-typography-sm-display-size: 3rem;
	--kite-typography-sm-display-weight: var(--kite-font-weight-bold);
	--kite-typography-sm-display-tracking: var(--kite-tracking-sm);
	--kite-typography-sm-display-line-height: 1.25;
	--kite-typography-sm-display-margin-bottom: var(--kite-spacing-element-xl);
	--kite-typography-sm-title-1-size: 2.5rem;
	--kite-typography-sm-title-1-weight: var(--kite-font-weight-bold);
	--kite-typography-sm-title-1-tracking: var(--kite-tracking-sm);
	--kite-typography-sm-title-1-line-height: 1.3;
	--kite-typography-sm-title-1-margin-bottom: var(--kite-spacing-element-md);
	--kite-typography-sm-title-2-size: 2.25rem;
	--kite-typography-sm-title-2-weight: var(--kite-font-weight-bold);
	--kite-typography-sm-title-2-tracking: var(--kite-tracking-sm);
	--kite-typography-sm-title-2-line-height: 1.222;
	--kite-typography-sm-title-2-margin-bottom: var(--kite-spacing-element-md);
	--kite-typography-sm-title-3-size: 2rem;
	--kite-typography-sm-title-3-weight: var(--kite-font-weight-bold);
	--kite-typography-sm-title-3-tracking: var(--kite-tracking-sm);
	--kite-typography-sm-title-3-line-height: 1.25;
	--kite-typography-sm-title-3-margin-bottom: var(--kite-spacing-element-md);
	--kite-typography-sm-title-4-size: 1.75rem;
	--kite-typography-sm-title-4-weight: var(--kite-font-weight-bold);
	--kite-typography-sm-title-4-tracking: var(--kite-tracking-md);
	--kite-typography-sm-title-4-line-height: 1.286;
	--kite-typography-sm-title-4-margin-bottom: var(--kite-spacing-element-md);
	--kite-typography-sm-title-5-size: 1.5rem;
	--kite-typography-sm-title-5-weight: var(--kite-font-weight-bold);
	--kite-typography-sm-title-5-tracking: var(--kite-tracking-md);
	--kite-typography-sm-title-5-line-height: 1.25;
	--kite-typography-sm-title-5-margin-bottom: var(--kite-spacing-element-md);
	--kite-typography-sm-title-6-size: 1.25rem;
	--kite-typography-sm-title-6-weight: var(--kite-font-weight-bold);
	--kite-typography-sm-title-6-tracking: var(--kite-tracking-md);
	--kite-typography-sm-title-6-line-height: 1.3;
	--kite-typography-sm-title-6-margin-bottom: var(--kite-spacing-element-sm);
	--kite-typography-sm-body-display-size: 1.25rem;
	--kite-typography-sm-body-display-weight: var(--kite-font-weight-regular);
	--kite-typography-sm-body-display-tracking: var(--kite-tracking-md);
	--kite-typography-sm-body-display-line-height: 1.3;
	--kite-typography-sm-body-display-margin-bottom: var(--kite-spacing-element-xl);
	--kite-typography-sm-body-size: 1rem;
	--kite-typography-sm-body-weight: var(--kite-font-weight-regular);
	--kite-typography-sm-body-tracking: var(--kite-tracking-md);
	--kite-typography-sm-body-line-height: 1.5;
	--kite-typography-sm-body-margin-bottom: var(--kite-spacing-element-xl);
	--kite-typography-sm-eyebrow-size: 0.875rem;
	--kite-typography-sm-eyebrow-style: uppercase;
	--kite-typography-sm-eyebrow-weight: var(--kite-font-weight-bold);
	--kite-typography-sm-eyebrow-tracking: var(--kite-tracking-lg);
	--kite-typography-sm-eyebrow-line-height: 1.571;
	--kite-typography-sm-eyebrow-margin-bottom: var(--kite-spacing-element-md);
	--kite-typography-sm-caption-size: 0.875rem;
	--kite-typography-sm-caption-weight: var(--kite-font-weight-regular);
	--kite-typography-sm-caption-tracking: var(--kite-tracking-md);
	--kite-typography-sm-caption-line-height: 1.571;
	--kite-typography-sm-caption-margin-bottom: var(--kite-spacing-element-lg);
	--kite-typography-lg-display-size: 4rem;
	--kite-typography-lg-display-weight: var(--kite-font-weight-bold);
	--kite-typography-lg-display-tracking: var(--kite-tracking-sm);
	--kite-typography-lg-display-line-height: 1.125;
	--kite-typography-lg-display-margin-bottom: var(--kite-spacing-element-xl);
	--kite-typography-lg-title-1-size: 3rem;
	--kite-typography-lg-title-1-weight: var(--kite-font-weight-bold);
	--kite-typography-lg-title-1-tracking: var(--kite-tracking-sm);
	--kite-typography-lg-title-1-line-height: 1.25;
	--kite-typography-lg-title-1-margin-bottom: var(--kite-spacing-element-md);
	--kite-typography-lg-title-2-size: 2.5rem;
	--kite-typography-lg-title-2-weight: var(--kite-font-weight-bold);
	--kite-typography-lg-title-2-tracking: var(--kite-tracking-sm);
	--kite-typography-lg-title-2-line-height: 1.25;
	--kite-typography-lg-title-2-margin-bottom: var(--kite-spacing-element-md);
	--kite-typography-lg-title-3-size: 2.25rem;
	--kite-typography-lg-title-3-weight: var(--kite-font-weight-bold);
	--kite-typography-lg-title-3-tracking: var(--kite-tracking-sm);
	--kite-typography-lg-title-3-line-height: 1.222;
	--kite-typography-lg-title-3-margin-bottom: var(--kite-spacing-element-md);
	--kite-typography-lg-title-4-size: 2rem;
	--kite-typography-lg-title-4-weight: var(--kite-font-weight-bold);
	--kite-typography-lg-title-4-tracking: var(--kite-tracking-md);
	--kite-typography-lg-title-4-line-height: 1.25;
	--kite-typography-lg-title-4-margin-bottom: var(--kite-spacing-element-md);
	--kite-typography-lg-title-5-size: 1.5rem;
	--kite-typography-lg-title-5-weight: var(--kite-font-weight-bold);
	--kite-typography-lg-title-5-tracking: var(--kite-tracking-md);
	--kite-typography-lg-title-5-line-height: 1.25;
	--kite-typography-lg-title-5-margin-bottom: var(--kite-spacing-element-md);
	--kite-typography-lg-title-6-size: 1.25rem;
	--kite-typography-lg-title-6-weight: var(--kite-font-weight-medium);
	--kite-typography-lg-title-6-tracking: var(--kite-tracking-md);
	--kite-typography-lg-title-6-line-height: 1.3;
	--kite-typography-lg-title-6-margin-bottom: var(--kite-spacing-element-md);
	--kite-typography-lg-body-display-size: 1.25rem;
	--kite-typography-lg-body-display-weight: var(--kite-font-weight-regular);
	--kite-typography-lg-body-display-tracking: var(--kite-tracking-md);
	--kite-typography-lg-body-display-line-height: 1.3;
	--kite-typography-lg-body-display-margin-bottom: var(--kite-spacing-element-xl);
	--kite-typography-lg-body-size: 1rem;
	--kite-typography-lg-body-weight: var(--kite-font-weight-regular);
	--kite-typography-lg-body-tracking: var(--kite-tracking-md);
	--kite-typography-lg-body-line-height: 1.5;
	--kite-typography-lg-body-margin-bottom: var(--kite-spacing-element-md);
	--kite-typography-lg-eyebrow-size: 1rem;
	--kite-typography-lg-eyebrow-style: uppercase;
	--kite-typography-lg-eyebrow-weight: var(--kite-font-weight-bold);
	--kite-typography-lg-eyebrow-tracking: var(--kite-tracking-lg);
	--kite-typography-lg-eyebrow-line-height: 1.5;
	--kite-typography-lg-eyebrow-margin-bottom: var(--kite-spacing-element-md);
	--kite-typography-lg-caption-size: 0.875rem;
	--kite-typography-lg-caption-weight: var(--kite-font-weight-regular);
	--kite-typography-lg-caption-tracking: var(--kite-tracking-md);
	--kite-typography-lg-caption-line-height: 1.429;
	--kite-typography-lg-caption-margin-bottom: var(--kite-spacing-element-sm);
	--kite-alt-typography-xxs-title-1-size: 1.313rem;
	--kite-alt-typography-xxs-title-1-weight: var(--kite-font-weight-medium);
	--kite-alt-typography-xxs-title-1-tracking: var(--kite-tracking-md);
	--kite-alt-typography-xxs-title-1-line-height: 1.333;
	--kite-alt-typography-xxs-title-1-margin-bottom: var(--kite-spacing-element-xl);
	--kite-alt-typography-xxs-title-2-size: 1.125rem;
	--kite-alt-typography-xxs-title-2-weight: var(--kite-font-weight-medium);
	--kite-alt-typography-xxs-title-2-tracking: var(--kite-tracking-md);
	--kite-alt-typography-xxs-title-2-line-height: 1.5;
	--kite-alt-typography-xxs-title-2-margin-bottom: var(--kite-spacing-element-md);
	--kite-alt-typography-xxs-title-3-size: 1rem;
	--kite-alt-typography-xxs-title-3-weight: var(--kite-font-weight-medium);
	--kite-alt-typography-xxs-title-3-tracking: var(--kite-tracking-md);
	--kite-alt-typography-xxs-title-3-line-height: 1.5;
	--kite-alt-typography-xxs-title-3-margin-bottom: var(--kite-spacing-element-md);
	--kite-alt-typography-xxs-title-4-size: 0.875rem;
	--kite-alt-typography-xxs-title-4-weight: var(--kite-font-weight-bold);
	--kite-alt-typography-xxs-title-4-tracking: var(--kite-tracking-md);
	--kite-alt-typography-xxs-title-4-line-height: 1.5;
	--kite-alt-typography-xxs-title-4-margin-bottom: var(--kite-spacing-element-xs);
	--kite-alt-typography-xxs-title-5-size: 0.875rem;
	--kite-alt-typography-xxs-title-5-weight: var(--kite-font-weight-medium);
	--kite-alt-typography-xxs-title-5-tracking: var(--kite-tracking-md);
	--kite-alt-typography-xxs-title-5-line-height: 1.5;
	--kite-alt-typography-xxs-title-5-margin-bottom: var(--kite-spacing-element-xxs);
	--kite-alt-typography-xxs-body-size: 0.875rem;
	--kite-alt-typography-xxs-body-weight: var(--kite-font-weight-regular);
	--kite-alt-typography-xxs-body-tracking: var(--kite-tracking-md);
	--kite-alt-typography-xxs-body-line-height: 1.5;
	--kite-alt-typography-xxs-body-margin-bottom: var(--kite-spacing-element-xl);
	--kite-alt-typography-xxs-eyebrow-size: 0.813rem;
	--kite-alt-typography-xxs-eyebrow-style: uppercase;
	--kite-alt-typography-xxs-eyebrow-weight: var(--kite-font-weight-bold);
	--kite-alt-typography-xxs-eyebrow-tracking: var(--kite-tracking-lg);
	--kite-alt-typography-xxs-eyebrow-line-height: 1.538;
	--kite-alt-typography-xxs-eyebrow-margin-bottom: var(--kite-spacing-element-xs);
	--kite-alt-typography-xxs-caption-size: 0.75rem;
	--kite-alt-typography-xxs-caption-weight: var(--kite-font-weight-regular);
	--kite-alt-typography-xxs-caption-tracking: var(--kite-tracking-md);
	--kite-alt-typography-xxs-caption-line-height: 1.5;
	--kite-alt-typography-xxs-caption-margin-bottom: var(--kite-spacing-element-lg);
	--kite-alt-typography-xs-title-1-size: 1.438rem;
	--kite-alt-typography-xs-title-1-weight: var(--kite-font-weight-medium);
	--kite-alt-typography-xs-title-1-tracking: var(--kite-tracking-md);
	--kite-alt-typography-xs-title-1-line-height: 1.304;
	--kite-alt-typography-xs-title-1-margin-bottom: var(--kite-spacing-element-xl);
	--kite-alt-typography-xs-title-2-size: 1.188rem;
	--kite-alt-typography-xs-title-2-weight: var(--kite-font-weight-medium);
	--kite-alt-typography-xs-title-2-tracking: var(--kite-tracking-md);
	--kite-alt-typography-xs-title-2-line-height: 1.421;
	--kite-alt-typography-xs-title-2-margin-bottom: var(--kite-spacing-element-md);
	--kite-alt-typography-xs-title-3-size: 1.063rem;
	--kite-alt-typography-xs-title-3-weight: var(--kite-font-weight-medium);
	--kite-alt-typography-xs-title-3-tracking: var(--kite-tracking-md);
	--kite-alt-typography-xs-title-3-line-height: 1.471;
	--kite-alt-typography-xs-title-3-margin-bottom: var(--kite-spacing-element-md);
	--kite-alt-typography-xs-title-4-size: 0.938rem;
	--kite-alt-typography-xs-title-4-weight: var(--kite-font-weight-bold);
	--kite-alt-typography-xs-title-4-tracking: var(--kite-tracking-md);
	--kite-alt-typography-xs-title-4-line-height: 1.533;
	--kite-alt-typography-xs-title-4-margin-bottom: var(--kite-spacing-element-xs);
	--kite-alt-typography-xs-title-5-size: 0.938rem;
	--kite-alt-typography-xs-title-5-weight: var(--kite-font-weight-medium);
	--kite-alt-typography-xs-title-5-tracking: var(--kite-tracking-md);
	--kite-alt-typography-xs-title-5-line-height: 1.533;
	--kite-alt-typography-xs-title-5-margin-bottom: var(--kite-spacing-element-xxs);
	--kite-alt-typography-xs-body-size: 0.938rem;
	--kite-alt-typography-xs-body-weight: var(--kite-font-weight-regular);
	--kite-alt-typography-xs-body-tracking: var(--kite-tracking-md);
	--kite-alt-typography-xs-body-line-height: 1.533;
	--kite-alt-typography-xs-body-margin-bottom: var(--kite-spacing-element-xl);
	--kite-alt-typography-xs-eyebrow-size: 0.813rem;
	--kite-alt-typography-xs-eyebrow-style: uppercase;
	--kite-alt-typography-xs-eyebrow-weight: var(--kite-font-weight-bold);
	--kite-alt-typography-xs-eyebrow-tracking: var(--kite-tracking-lg);
	--kite-alt-typography-xs-eyebrow-line-height: 1.538;
	--kite-alt-typography-xs-eyebrow-margin-bottom: var(--kite-spacing-element-xs);
	--kite-alt-typography-xs-caption-size: 0.75rem;
	--kite-alt-typography-xs-caption-weight: var(--kite-font-weight-regular);
	--kite-alt-typography-xs-caption-tracking: var(--kite-tracking-md);
	--kite-alt-typography-xs-caption-line-height: 1.5;
	--kite-alt-typography-xs-caption-margin-bottom: var(--kite-spacing-element-lg);
	--kite-alt-typography-md-title-1-size: 2.25rem;
	--kite-alt-typography-md-title-1-weight: var(--kite-font-weight-bold);
	--kite-alt-typography-md-title-1-tracking: var(--kite-tracking-sm);
	--kite-alt-typography-md-title-1-line-height: 1.25;
	--kite-alt-typography-md-title-1-margin-bottom: var(--kite-spacing-element-xl);
	--kite-alt-typography-md-title-2-size: 1.75rem;
	--kite-alt-typography-md-title-2-weight: var(--kite-font-weight-bold);
	--kite-alt-typography-md-title-2-tracking: var(--kite-tracking-sm);
	--kite-alt-typography-md-title-2-line-height: 1.286;
	--kite-alt-typography-md-title-2-margin-bottom: var(--kite-spacing-element-md);
	--kite-alt-typography-md-title-3-size: 1.5rem;
	--kite-alt-typography-md-title-3-weight: var(--kite-font-weight-bold);
	--kite-alt-typography-md-title-3-tracking: var(--kite-tracking-sm);
	--kite-alt-typography-md-title-3-line-height: 1.292;
	--kite-alt-typography-md-title-3-margin-bottom: var(--kite-spacing-element-md);
	--kite-alt-typography-md-title-4-size: 1.25rem;
	--kite-alt-typography-md-title-4-weight: var(--kite-font-weight-bold);
	--kite-alt-typography-md-title-4-tracking: var(--kite-tracking-md);
	--kite-alt-typography-md-title-4-line-height: 1.05;
	--kite-alt-typography-md-title-4-margin-bottom: var(--kite-spacing-element-xs);
	--kite-alt-typography-md-title-5-size: 1rem;
	--kite-alt-typography-md-title-5-weight: var(--kite-font-weight-bold);
	--kite-alt-typography-md-title-5-tracking: var(--kite-tracking-md);
	--kite-alt-typography-md-title-5-line-height: 1.5;
	--kite-alt-typography-md-title-5-margin-bottom: var(--kite-spacing-element-xxs);
	--kite-alt-typography-md-body-size: 1rem;
	--kite-alt-typography-md-body-weight: var(--kite-font-weight-regular);
	--kite-alt-typography-md-body-tracking: var(--kite-tracking-md);
	--kite-alt-typography-md-body-line-height: 1.5;
	--kite-alt-typography-md-body-margin-bottom: var(--kite-spacing-element-xl);
	--kite-alt-typography-md-eyebrow-size: 0.875rem;
	--kite-alt-typography-md-eyebrow-style: uppercase;
	--kite-alt-typography-md-eyebrow-weight: var(--kite-font-weight-bold);
	--kite-alt-typography-md-eyebrow-tracking: var(--kite-tracking-lg);
	--kite-alt-typography-md-eyebrow-line-height: 1.5;
	--kite-alt-typography-md-eyebrow-margin-bottom: var(--kite-spacing-element-xs);
	--kite-alt-typography-md-caption-size: 0.75rem;
	--kite-alt-typography-md-caption-weight: var(--kite-font-weight-regular);
	--kite-alt-typography-md-caption-tracking: var(--kite-tracking-md);
	--kite-alt-typography-md-caption-line-height: 1.75;
	--kite-alt-typography-md-caption-margin-bottom: var(--kite-spacing-element-lg);
	--kite-alt-typography-xl-title-1-size: 3.5rem;
	--kite-alt-typography-xl-title-1-weight: var(--kite-font-weight-medium);
	--kite-alt-typography-xl-title-1-tracking: var(--kite-tracking-md);
	--kite-alt-typography-xl-title-1-line-height: 1.393;
	--kite-alt-typography-xl-title-1-margin-bottom: var(--kite-spacing-element-xxl);
	--kite-alt-typography-xl-title-2-size: 2.625rem;
	--kite-alt-typography-xl-title-2-weight: var(--kite-font-weight-medium);
	--kite-alt-typography-xl-title-2-tracking: var(--kite-tracking-sm);
	--kite-alt-typography-xl-title-2-line-height: 1.429;
	--kite-alt-typography-xl-title-2-margin-bottom: var(--kite-spacing-element-lg);
	--kite-alt-typography-xl-title-3-size: 2.25rem;
	--kite-alt-typography-xl-title-3-weight: var(--kite-font-weight-regular);
	--kite-alt-typography-xl-title-3-tracking: var(--kite-tracking-sm);
	--kite-alt-typography-xl-title-3-line-height: 1.056;
	--kite-alt-typography-xl-title-3-margin-bottom: var(--kite-spacing-element-lg);
	--kite-alt-typography-xl-title-4-size: 1.75rem;
	--kite-alt-typography-xl-title-4-weight: var(--kite-font-weight-regular);
	--kite-alt-typography-xl-title-4-tracking: var(--kite-tracking-md);
	--kite-alt-typography-xl-title-4-line-height: 0.964;
	--kite-alt-typography-xl-title-4-margin-bottom: var(--kite-spacing-element-sm);
	--kite-alt-typography-xl-title-5-size: 1.5rem;
	--kite-alt-typography-xl-title-5-weight: var(--kite-font-weight-medium);
	--kite-alt-typography-xl-title-5-tracking: var(--kite-tracking-md);
	--kite-alt-typography-xl-title-5-line-height: 1;
	--kite-alt-typography-xl-title-5-margin-bottom: var(--kite-spacing-element-xs);
	--kite-alt-typography-xl-body-size: 1.5rem;
	--kite-alt-typography-xl-body-weight: var(--kite-font-weight-regular);
	--kite-alt-typography-xl-body-tracking: var(--kite-tracking-md);
	--kite-alt-typography-xl-body-line-height: 1.333;
	--kite-alt-typography-xl-body-margin-bottom: var(--kite-spacing-element-xxl);
	--kite-alt-typography-xl-eyebrow-size: 1.375rem;
	--kite-alt-typography-xl-eyebrow-style: uppercase;
	--kite-alt-typography-xl-eyebrow-weight: var(--kite-font-weight-bold);
	--kite-alt-typography-xl-eyebrow-tracking: var(--kite-tracking-lg);
	--kite-alt-typography-xl-eyebrow-line-height: 1.091;
	--kite-alt-typography-xl-eyebrow-margin-bottom: var(--kite-spacing-element-sm);
	--kite-alt-typography-xl-caption-size: 1.25rem;
	--kite-alt-typography-xl-caption-weight: var(--kite-font-weight-medium);
	--kite-alt-typography-xl-caption-tracking: var(--kite-tracking-md);
	--kite-alt-typography-xl-caption-line-height: 1.2;
	--kite-alt-typography-xl-caption-margin-bottom: var(--kite-spacing-element-xl);
	--kite-form-control-label-text-color: var(--kite-color-text);
	--kite-form-control-label-text-color-dark: var(--kite-color-text-dark);
	--kite-form-control-label-margin-bottom: var(--kite-spacing-element-xxs);
	--kite-form-control-margin-bottom: var(--kite-spacing-element-xl);
	--kite-form-control-error-text-color: var(--kite-color-error);
	--kite-form-control-error-text-color-dark: var(--kite-color-error-dark);
	--kite-form-control-error-text-size: var(--kite-typography-lg-body-size);
	--kite-form-control-error-text-margin-top: var(--kite-spacing-element-xxs);
	--kite-form-control-hover-border-width: var(--kite-border-width-thick);
	--kite-form-control-input-background-color: var(--kite-color-white);
	--kite-form-control-input-background-color-dark: var(--kite-color-dark-blue-20);
	--kite-form-control-input-border-color: var(--kite-color-gray-30);
	--kite-form-control-input-border-color-dark: var(--kite-color-gray-30);
	--kite-form-control-input-border-style: solid;
	--kite-form-control-input-border-width: var(--kite-border-width-thin);
	--kite-form-control-input-cursor: var(--kite-cursor-action);
	--kite-form-control-input-max-width: 18rem;
	--kite-form-control-input-padding-top: var(--kite-spacing-element-sm);
	--kite-form-control-input-padding-right: var(--kite-spacing-element-xs);
	--kite-form-control-input-padding-bottom: var(--kite-spacing-element-sm);
	--kite-form-control-input-padding-left: var(--kite-spacing-element-xs);
	--kite-form-control-input-text-color: var(--kite-color-text);
	--kite-form-control-input-text-color-dark: var(--kite-color-text-dark);
	--kite-form-control-input-placeholder-text-color: var(--kite-color-gray-30);
	--kite-form-control-input-placeholder-text-color-dark: var(--kite-color-gray-25);
	--kite-form-control-input-disabled-background-color: var(--kite-color-gray-20);
	--kite-form-control-input-disabled-background-color-dark: var(--kite-color-dark-blue-20);
	--kite-form-control-input-disabled-border-color: var(--kite-color-disabled);
	--kite-form-control-input-disabled-border-color-dark: var(--kite-color-disabled-dark);
	--kite-form-control-input-disabled-cursor: var(--kite-cursor-disabled);
	--kite-form-control-input-disabled-text-color: var(--kite-color-disabled);
	--kite-form-control-input-disabled-text-color-dark: var(--kite-color-disabled-dark);
	--kite-form-control-input-error-border-color: var(--kite-color-error);
	--kite-form-control-input-error-border-color-dark: var(--kite-color-error-dark);
	--kite-form-control-input-focus-ring-color: var(--kite-focus-ring-color);
	--kite-form-control-input-focus-ring-color-dark: var(--kite-focus-ring-color-dark);
	--kite-form-control-input-focus-ring-style: var(--kite-focus-ring-style);
	--kite-form-control-input-focus-ring-width: var(--kite-focus-ring-width);
	--kite-accordion-label-text-color: var(--kite-color-text);
	--kite-accordion-label-text-color-dark: var(--kite-color-text-dark);
	--kite-accordion-label-text-size: var(--kite-typography-lg-body-size);
	--kite-accordion-label-margin-right: var(--kite-spacing-element-md);
	--kite-accordion-disabled-label-text-color: var(--kite-color-disabled);
	--kite-accordion-disabled-label-text-color-dark: var(--kite-color-disabled-dark);
	--kite-accordion-alert-label-text-color: var(--kite-color-red-20);
	--kite-accordion-alert-label-text-color-dark: var(--kite-color-red-10);
	--kite-accordion-success-label-text-color: var(--kite-color-success);
	--kite-accordion-success-label-text-color-dark: var(--kite-color-success-dark);
	--kite-accordion-border-bottom-color: var(--kite-border-color);
	--kite-accordion-border-bottom-color-dark: var(--kite-color-dark-blue-10);
	--kite-accordion-border-bottom-style: solid;
	--kite-accordion-border-bottom-width: var(--kite-border-width-thin);
	--kite-accordion-cursor: var(--kite-cursor-action);
	--kite-accordion-sm-padding-top: var(--kite-spacing-element-md);
	--kite-accordion-sm-padding-right: var(--kite-spacing-element-md);
	--kite-accordion-sm-padding-bottom: var(--kite-spacing-element-md);
	--kite-accordion-sm-padding-left: var(--kite-spacing-element-md);
	--kite-accordion-md-padding-top: var(--kite-spacing-element-md);
	--kite-accordion-md-padding-right: var(--kite-spacing-element-xl);
	--kite-accordion-md-padding-bottom: var(--kite-spacing-element-md);
	--kite-accordion-md-padding-left: var(--kite-spacing-element-xl);
	--kite-accordion-lg-padding-top: var(--kite-spacing-element-md);
	--kite-accordion-lg-padding-right: var(--kite-spacing-element-xxl);
	--kite-accordion-lg-padding-bottom: var(--kite-spacing-element-md);
	--kite-accordion-lg-padding-left: var(--kite-spacing-element-xxl);
	--kite-accordion-title-text-size: var(--kite-typography-lg-body-size);
	--kite-accordion-title-text-color: var(--kite-color-text);
	--kite-accordion-title-text-color-dark: var(--kite-color-text-dark);
	--kite-accordion-title-padding-left: var(--kite-spacing-element-xs);
	--kite-accordion-icon-fill: var(--kite-color-gray-30);
	--kite-accordion-icon-fill-dark: var(--kite-color-white);
	--kite-accordion-icon-height: var(--kite-size-md);
	--kite-accordion-icon-width: var(--kite-size-md);
	--kite-accordion-icon-transition-duration: var(--kite-motion-duration-fast);
	--kite-accordion-icon-transition-curve: var(--kite-motion-curve-default);
	--kite-accordion-expanded-padding-top: var(--kite-spacing-element-xs);
	--kite-accordion-expanded-padding-bottom: var(--kite-spacing-element-xxl);
	--kite-accordion-expanded-transition-duration: var(--kite-motion-duration-fast);
	--kite-accordion-expanded-transition-curve: var(--kite-motion-curve-default);
	--kite-accordion-hover-background-color: #001019;
	--kite-accordion-hover-background-color-dark: #ffffff;
	--kite-accordion-hover-background-opacity: 0.02;
	--kite-accordion-hover-background-opacity-dark: 0.05;
	--kite-accordion-disabled-cursor: var(--kite-cursor-disabled);
	--kite-accordion-disabled-title-text-color: var(--kite-color-disabled);
	--kite-accordion-disabled-title-text-color-dark: var(--kite-color-disabled-dark);
	--kite-accordion-disabled-icon-fill: var(--kite-color-disabled);
	--kite-accordion-disabled-icon-fill-dark: var(--kite-color-disabled-dark);
	--kite-accordion-focus-ring-color: var(--kite-focus-ring-color);
	--kite-accordion-focus-ring-color-dark: var(--kite-focus-ring-color-dark);
	--kite-accordion-focus-ring-style: var(--kite-focus-ring-style);
	--kite-accordion-focus-ring-width: var(--kite-focus-ring-width);
	--kite-alert-dismiss-icon-cursor: var(--kite-cursor-action);
	--kite-alert-dismiss-icon-fill: var(--kite-color-black);
	--kite-alert-dismiss-icon-fill-dark: var(--kite-color-black);
	--kite-alert-dismiss-icon-height: var(--kite-size-xl);
	--kite-alert-dismiss-icon-margin-left: var(--kite-spacing-element-md);
	--kite-alert-dismiss-icon-width: var(--kite-size-xl);
	--kite-alert-indicator-icon-height: var(--kite-size-xl);
	--kite-alert-indicator-icon-width: var(--kite-size-xl);
	--kite-alert-link-margin-top: var(--kite-spacing-element-xxs);
	--kite-alert-link-icon-height: var(--kite-size-sm);
	--kite-alert-link-icon-margin-left: var(--kite-spacing-element-xxs);
	--kite-alert-link-icon-width: var(--kite-size-sm);
	--kite-alert-text-align: left;
	--kite-alert-text-color: var(--kite-color-text);
	--kite-alert-text-color-dark: var(--kite-color-text);
	--kite-alert-text-size: var(--kite-typography-lg-body-size);
	--kite-alert-text-weight: var(--kite-typography-lg-body-weight);
	--kite-alert-global-indicator-icon-margin-right: var(--kite-spacing-element-sm);
	--kite-alert-global-padding-top: var(--kite-spacing-element-md);
	--kite-alert-global-padding-right: var(--kite-spacing-element-md);
	--kite-alert-global-padding-bottom: var(--kite-spacing-element-md);
	--kite-alert-global-padding-left: var(--kite-spacing-element-md);
	--kite-alert-global-caution-background-color: var(--kite-color-caution);
	--kite-alert-global-caution-background-color-dark: var(--kite-color-caution);
	--kite-alert-global-caution-indicator-icon-fill: var(--kite-color-black);
	--kite-alert-global-caution-indicator-icon-fill-dark: var(--kite-color-black);
	--kite-alert-global-caution-link-text-color: var(--kite-color-text);
	--kite-alert-global-caution-link-text-color-dark: var(--kite-color-text);
	--kite-alert-global-caution-link-icon-fill: var(--kite-color-black);
	--kite-alert-global-caution-link-icon-fill-dark: var(--kite-color-black);
	--kite-alert-global-caution-text-color: var(--kite-color-text);
	--kite-alert-global-caution-text-color-dark: var(--kite-color-text);
	--kite-alert-global-error-background-color: var(--kite-color-error);
	--kite-alert-global-error-background-color-dark: var(--kite-color-error);
	--kite-alert-global-error-dismiss-icon-fill: var(--kite-color-white);
	--kite-alert-global-error-dismiss-icon-fill-dark: var(--kite-color-white);
	--kite-alert-global-error-indicator-icon-fill: var(--kite-color-white);
	--kite-alert-global-error-indicator-icon-fill-dark: var(--kite-color-white);
	--kite-alert-global-error-link-text-color: var(--kite-color-white);
	--kite-alert-global-error-link-text-color-dark: var(--kite-color-white);
	--kite-alert-global-error-link-icon-fill: var(--kite-color-white);
	--kite-alert-global-error-link-icon-fill-dark: var(--kite-color-white);
	--kite-alert-global-error-text-color: var(--kite-color-white);
	--kite-alert-global-error-text-color-dark: var(--kite-color-white);
	--kite-alert-global-info-text-color: var(--kite-color-text);
	--kite-alert-global-info-text-color-dark: var(--kite-color-text);
	--kite-alert-global-info-background-color: var(--kite-color-info);
	--kite-alert-global-info-background-color-dark: var(--kite-color-info);
	--kite-alert-global-info-indicator-icon-fill: var(--kite-color-black);
	--kite-alert-global-info-indicator-icon-fill-dark: var(--kite-color-black);
	--kite-alert-global-info-link-text-color: var(--kite-color-text);
	--kite-alert-global-info-link-text-color-dark: var(--kite-color-text);
	--kite-alert-global-info-link-icon-fill: var(--kite-color-black);
	--kite-alert-global-info-link-icon-fill-dark: var(--kite-color-black);
	--kite-alert-page-background-color: var(--kite-color-white);
	--kite-alert-page-background-color-dark: var(--kite-color-white);
	--kite-alert-page-border-color: var(--kite-border-color);
	--kite-alert-page-border-color-dark: var(--kite-border-color);
	--kite-alert-page-border-radius: var(--kite-border-radius-md);
	--kite-alert-page-border-style: solid;
	--kite-alert-page-border-width: var(--kite-border-width-thin);
	--kite-alert-page-border-left-width: var(--kite-size-xs);
	--kite-alert-page-border-left-style: solid;
	--kite-alert-page-elevation: var(--kite-elevation-1);
	--kite-alert-page-indicator-icon-margin-right: var(--kite-spacing-element-xs);
	--kite-alert-page-link-text-color: var(--kite-color-primary);
	--kite-alert-page-link-text-color-dark: var(--kite-color-primary);
	--kite-alert-page-link-text-margin-top: var(--kite-spacing-element-xxs);
	--kite-alert-page-link-icon-fill: var(--kite-color-primary);
	--kite-alert-page-link-icon-fill-dark: var(--kite-color-primary);
	--kite-alert-page-margin-bottom: var(--kite-spacing-element-xxs);
	--kite-alert-page-padding-top: var(--kite-spacing-element-md);
	--kite-alert-page-padding-right: var(--kite-spacing-element-md);
	--kite-alert-page-padding-bottom: var(--kite-spacing-element-md);
	--kite-alert-page-padding-left: var(--kite-spacing-element-xs);
	--kite-alert-page-text-color: var(--kite-color-text);
	--kite-alert-page-text-color-dark: var(--kite-color-text);
	--kite-alert-page-text-size: var(--kite-typography-lg-body-size);
	--kite-alert-page-caution-border-left-color: var(--kite-color-caution);
	--kite-alert-page-caution-border-left-color-dark: var(--kite-color-caution);
	--kite-alert-page-caution-indicator-icon-fill: var(--kite-color-caution);
	--kite-alert-page-caution-indicator-icon-fill-dark: var(--kite-color-caution);
	--kite-alert-page-error-border-left-color: var(--kite-color-error);
	--kite-alert-page-error-border-left-color-dark: var(--kite-color-error);
	--kite-alert-page-error-indicator-icon-fill: var(--kite-color-error);
	--kite-alert-page-error-indicator-icon-fill-dark: var(--kite-color-error);
	--kite-alert-page-info-border-left-color: var(--kite-color-info);
	--kite-alert-page-info-border-left-color-dark: var(--kite-color-info);
	--kite-alert-page-info-indicator-icon-fill: var(--kite-color-info);
	--kite-alert-page-info-indicator-icon-fill-dark: var(--kite-color-info);
	--kite-alert-page-success-border-left-color: var(--kite-color-success);
	--kite-alert-page-success-border-left-color-dark: var(--kite-color-success);
	--kite-alert-page-success-indicator-icon-fill: var(--kite-color-success);
	--kite-alert-page-success-indicator-icon-fill-dark: var(--kite-color-success);
	--kite-badge-background-color: var(--kite-color-error);
	--kite-badge-background-color-dark: var(--kite-color-error);
	--kite-badge-border-radius: var(--kite-border-radius-circle);
	--kite-badge-text-color: var(--kite-color-white);
	--kite-badge-text-color-dark: var(--kite-color-white);
	--kite-badge-text-size: var(--kite-size-sm);
	--kite-badge-padding-top: var(--kite-spacing-element-xxs);
	--kite-badge-padding-right: var(--kite-spacing-element-xxs);
	--kite-badge-padding-bottom: var(--kite-spacing-element-xxs);
	--kite-badge-padding-left: var(--kite-spacing-element-xxs);
	--kite-badge-icon-dot-height: var(--kite-size-xs);
	--kite-badge-icon-dot-width: var(--kite-size-xs);
	--kite-badge-tab-margin-right: var(--kite-spacing-element-xs);
	--kite-button-border-radius: var(--kite-border-radius-md);
	--kite-button-cursor: var(--kite-cursor-action);
	--kite-button-height: var(--kite-size-touch-target);
	--kite-button-text-size: var(--kite-typography-lg-body-size);
	--kite-button-text-weight: var(--kite-font-weight-medium);
	--kite-button-padding-left: var(--kite-spacing-element-md);
	--kite-button-padding-right: var(--kite-spacing-element-md);
	--kite-button-icon-height: var(--kite-size-xl);
	--kite-button-icon-width: var(--kite-size-xl);
	--kite-button-icon-margin: var(--kite-spacing-element-md);
	--kite-button-focus-ring-color: var(--kite-focus-ring-color);
	--kite-button-focus-ring-color-dark: var(--kite-focus-ring-color-dark);
	--kite-button-focus-ring-offset: var(--kite-focus-ring-offset);
	--kite-button-focus-ring-radius: var(--kite-border-radius-lg);
	--kite-button-focus-ring-style: var(--kite-focus-ring-style);
	--kite-button-focus-ring-width: var(--kite-focus-ring-width);
	--kite-button-disabled-cursor: var(--kite-cursor-disabled);
	--kite-button-group-margin-top: var(--kite-spacing-element-xxl);
	--kite-button-group-margin-bottom: var(--kite-spacing-element-xxl);
	--kite-button-group-button-margin-right: var(--kite-spacing-element-xl);
	--kite-button-group-button-margin-bottom: var(--kite-spacing-element-md);
	--kite-button-primary-background-color: var(--kite-color-primary);
	--kite-button-primary-background-color-dark: var(--kite-color-primary-dark);
	--kite-button-primary-border-color: transparent;
	--kite-button-primary-border-color-dark: transparent;
	--kite-button-primary-border-style: solid;
	--kite-button-primary-border-width: var(--kite-border-width-thin);
	--kite-button-primary-icon-fill: var(--kite-color-white);
	--kite-button-primary-icon-fill-dark: var(--kite-color-white);
	--kite-button-primary-text-color: var(--kite-color-white);
	--kite-button-primary-text-color-dark: var(--kite-color-white);
	--kite-button-primary-active-background-color: var(--kite-color-blue-30);
	--kite-button-primary-active-background-color-dark: var(--kite-color-blue-30);
	--kite-button-primary-active-border-color: transparent;
	--kite-button-primary-active-border-color-dark: transparent;
	--kite-button-primary-active-text-color: var(--kite-color-white);
	--kite-button-primary-active-text-color-dark: var(--kite-color-white);
	--kite-button-primary-active-icon-fill: var(--kite-color-white);
	--kite-button-primary-active-icon-fill-dark: var(--kite-color-white);
	--kite-button-primary-disabled-background-color: var(--kite-color-gray-20);
	--kite-button-primary-disabled-background-color-dark: var(--kite-color-dark-blue-10);
	--kite-button-primary-disabled-border-color: transparent;
	--kite-button-primary-disabled-border-color-dark: transparent;
	--kite-button-primary-disabled-text-color: var(--kite-color-disabled);
	--kite-button-primary-disabled-text-color-dark: var(--kite-color-disabled-dark);
	--kite-button-primary-disabled-icon-fill: var(--kite-color-disabled);
	--kite-button-primary-disabled-icon-fill-dark: var(--kite-color-disabled-dark);
	--kite-button-primary-focus-background-color: var(--kite-color-blue-30);
	--kite-button-primary-focus-background-color-dark: var(--kite-color-blue-30);
	--kite-button-primary-focus-border-color: transparent;
	--kite-button-primary-focus-border-color-dark: transparent;
	--kite-button-primary-focus-text-color: var(--kite-color-white);
	--kite-button-primary-focus-text-color-dark: var(--kite-color-white);
	--kite-button-primary-focus-icon-fill: var(--kite-color-white);
	--kite-button-primary-focus-icon-fill-dark: var(--kite-color-white);
	--kite-button-primary-hover-background-color: var(--kite-color-blue-30);
	--kite-button-primary-hover-background-color-dark: var(--kite-color-blue-30);
	--kite-button-primary-hover-border-color: transparent;
	--kite-button-primary-hover-border-color-dark: transparent;
	--kite-button-primary-hover-text-color: var(--kite-color-white);
	--kite-button-primary-hover-text-color-dark: var(--kite-color-white);
	--kite-button-primary-hover-icon-fill: var(--kite-color-white);
	--kite-button-primary-hover-icon-fill-dark: var(--kite-color-white);
	--kite-button-secondary-background-color: transparent;
	--kite-button-secondary-background-color-dark: transparent;
	--kite-button-secondary-border-color: var(--kite-color-primary);
	--kite-button-secondary-border-color-dark: var(--kite-border-color-dark);
	--kite-button-secondary-border-style: solid;
	--kite-button-secondary-border-width: var(--kite-border-width-thin);
	--kite-button-secondary-icon-fill: var(--kite-color-primary);
	--kite-button-secondary-icon-fill-dark: var(--kite-color-white);
	--kite-button-secondary-text-color: var(--kite-color-primary);
	--kite-button-secondary-text-color-dark: var(--kite-color-text-dark);
	--kite-button-secondary-active-background-color: var(--kite-color-blue-30);
	--kite-button-secondary-active-background-color-dark: var(--kite-color-white);
	--kite-button-secondary-active-border-color: transparent;
	--kite-button-secondary-active-border-color-dark: transparent;
	--kite-button-secondary-active-text-color: var(--kite-color-white);
	--kite-button-secondary-active-text-color-dark: var(--kite-color-dark-blue-20);
	--kite-button-secondary-active-icon-fill: var(--kite-color-white);
	--kite-button-secondary-active-icon-fill-dark: var(--kite-color-dark-blue-20);
	--kite-button-secondary-disabled-background-color: transparent;
	--kite-button-secondary-disabled-background-color-dark: transparent;
	--kite-button-secondary-disabled-border-color: var(--kite-color-disabled);
	--kite-button-secondary-disabled-border-color-dark: var(--kite-color-disabled-dark);
	--kite-button-secondary-disabled-icon-fill: var(--kite-color-disabled);
	--kite-button-secondary-disabled-icon-fill-dark: var(--kite-color-disabled-dark);
	--kite-button-secondary-disabled-text-color: var(--kite-color-disabled);
	--kite-button-secondary-disabled-text-color-dark: var(--kite-color-disabled-dark);
	--kite-button-secondary-focus-background-color: var(--kite-color-blue-30);
	--kite-button-secondary-focus-background-color-dark: var(--kite-color-white);
	--kite-button-secondary-focus-border-color: transparent;
	--kite-button-secondary-focus-border-color-dark: transparent;
	--kite-button-secondary-focus-text-color: var(--kite-color-white);
	--kite-button-secondary-focus-text-color-dark: var(--kite-color-dark-blue-20);
	--kite-button-secondary-focus-icon-fill: var(--kite-color-white);
	--kite-button-secondary-focus-icon-fill-dark: var(--kite-color-dark-blue-20);
	--kite-button-secondary-hover-background-color: var(--kite-color-blue-30);
	--kite-button-secondary-hover-background-color-dark: var(--kite-color-white);
	--kite-button-secondary-hover-border-color: transparent;
	--kite-button-secondary-hover-border-color-dark: transparent;
	--kite-button-secondary-hover-text-color: var(--kite-color-white);
	--kite-button-secondary-hover-text-color-dark: var(--kite-color-dark-blue-20);
	--kite-button-secondary-hover-icon-fill: var(--kite-color-white);
	--kite-button-secondary-hover-icon-fill-dark: var(--kite-color-dark-blue-20);
	--kite-button-borderless-background-color: transparent;
	--kite-button-borderless-background-color-dark: transparent;
	--kite-button-borderless-border-color: transparent;
	--kite-button-borderless-border-color-dark: transparent;
	--kite-button-borderless-border-style: solid;
	--kite-button-borderless-border-width: var(--kite-border-width-thin);
	--kite-button-borderless-icon-fill: var(--kite-color-primary);
	--kite-button-borderless-icon-fill-dark: var(--kite-color-white);
	--kite-button-borderless-text-color: var(--kite-color-primary);
	--kite-button-borderless-text-color-dark: var(--kite-color-text-dark);
	--kite-button-borderless-active-background-color: transparent;
	--kite-button-borderless-active-background-color-dark: transparent;
	--kite-button-borderless-active-border-color: transparent;
	--kite-button-borderless-active-border-color-dark: transparent;
	--kite-button-borderless-active-icon-fill: var(--kite-color-blue-30);
	--kite-button-borderless-active-icon-fill-dark: var(--kite-color-gray-25);
	--kite-button-borderless-active-text-color: var(--kite-color-blue-30);
	--kite-button-borderless-active-text-color-dark: var(--kite-color-gray-25);
	--kite-button-borderless-disabled-background-color: transparent;
	--kite-button-borderless-disabled-background-color-dark: transparent;
	--kite-button-borderless-disabled-border-color: transparent;
	--kite-button-borderless-disabled-border-color-dark: transparent;
	--kite-button-borderless-disabled-icon-fill: var(--kite-color-disabled);
	--kite-button-borderless-disabled-icon-fill-dark: var(--kite-color-disabled-dark);
	--kite-button-borderless-disabled-text-color: var(--kite-color-disabled);
	--kite-button-borderless-disabled-text-color-dark: var(--kite-color-disabled-dark);
	--kite-button-borderless-focus-background-color: transparent;
	--kite-button-borderless-focus-background-color-dark: transparent;
	--kite-button-borderless-focus-border-color: transparent;
	--kite-button-borderless-focus-border-color-dark: transparent;
	--kite-button-borderless-focus-icon-fill: var(--kite-color-blue-30);
	--kite-button-borderless-focus-icon-fill-dark: var(--kite-color-white);
	--kite-button-borderless-focus-text-color: var(--kite-color-blue-30);
	--kite-button-borderless-focus-text-color-dark: var(--kite-color-white);
	--kite-button-borderless-hover-background-color: transparent;
	--kite-button-borderless-hover-background-color-dark: transparent;
	--kite-button-borderless-hover-border-color: transparent;
	--kite-button-borderless-hover-border-color-dark: transparent;
	--kite-button-borderless-hover-icon-fill: var(--kite-color-blue-30);
	--kite-button-borderless-hover-icon-fill-dark: var(--kite-color-gray-25);
	--kite-button-borderless-hover-text-color: var(--kite-color-blue-30);
	--kite-button-borderless-hover-text-color-dark: var(--kite-color-gray-25);
	--kite-card-background-color: var(--kite-color-white);
	--kite-card-background-color-dark: var(--kite-color-dark-blue-20);
	--kite-card-border-color: var(--kite-border-color);
	--kite-card-border-color-dark: var(--kite-color-dark-blue-20);
	--kite-card-border-radius: var(--kite-border-radius-md);
	--kite-card-border-style: solid;
	--kite-card-border-width: var(--kite-border-width-thin);
	--kite-card-sm-min-padding-top: var(--kite-spacing-element-md);
	--kite-card-sm-min-padding-right: var(--kite-spacing-element-md);
	--kite-card-sm-min-padding-bottom: var(--kite-spacing-element-md);
	--kite-card-sm-min-padding-left: var(--kite-spacing-element-md);
	--kite-card-sm-margin-bottom: var(--kite-spacing-element-md);
	--kite-card-md-min-padding-top: var(--kite-spacing-element-xl);
	--kite-card-md-min-padding-right: var(--kite-spacing-element-xl);
	--kite-card-md-min-padding-bottom: var(--kite-spacing-element-xl);
	--kite-card-md-min-padding-left: var(--kite-spacing-element-xl);
	--kite-card-md-margin-bottom: var(--kite-spacing-element-md);
	--kite-card-lg-min-padding-top: var(--kite-spacing-element-xxl);
	--kite-card-lg-min-padding-right: var(--kite-spacing-element-xxl);
	--kite-card-lg-min-padding-bottom: var(--kite-spacing-element-xxl);
	--kite-card-lg-min-padding-left: var(--kite-spacing-element-xxl);
	--kite-card-lg-margin-bottom: var(--kite-spacing-element-xl);
	--kite-checkbox-background-color: transparent;
	--kite-checkbox-background-color-dark: transparent;
	--kite-checkbox-border-color: var(--kite-color-gray-30);
	--kite-checkbox-border-color-dark: var(--kite-color-white);
	--kite-checkbox-border-radius: var(--kite-border-radius-sm);
	--kite-checkbox-border-style: solid;
	--kite-checkbox-border-width: var(--kite-border-width-thick);
	--kite-checkbox-cursor: var(--kite-cursor-action);
	--kite-checkbox-height: var(--kite-size-lg);
	--kite-checkbox-width: var(--kite-size-lg);
	--kite-checkbox-input-margin-right: var(--kite-spacing-element-xs);
	--kite-checkbox-label-text-color: var(--kite-color-text);
	--kite-checkbox-label-text-color-dark: var(--kite-color-text-dark);
	--kite-checkbox-label-text-size: var(--kite-typography-sm-body-size);
	--kite-checkbox-label-text-weight: var(--kite-typography-sm-body-weight);
	--kite-checkbox-label-margin-bottom: 0;
	--kite-checkbox-margin-top: 0;
	--kite-checkbox-margin-right: 0;
	--kite-checkbox-margin-bottom: var(--kite-spacing-element-xl);
	--kite-checkbox-margin-left: 0;
	--kite-checkbox-disabled-border-color: var(--kite-color-disabled);
	--kite-checkbox-disabled-border-color-dark: var(--kite-color-disabled-dark);
	--kite-checkbox-disabled-cursor: var(--kite-cursor-disabled);
	--kite-checkbox-disabled-label-text-color: var(--kite-color-disabled);
	--kite-checkbox-disabled-label-text-color-dark: var(--kite-color-disabled-dark);
	--kite-checkbox-disabled-icon-color: var(--kite-color-gray-20);
	--kite-checkbox-disabled-icon-color-dark: var(--kite-color-gray-25);
	--kite-checkbox-focus-ring-color: var(--kite-focus-ring-color);
	--kite-checkbox-focus-ring-color-dark: var(--kite-focus-ring-color-dark);
	--kite-checkbox-focus-ring-offset: var(--kite-size-2);
	--kite-checkbox-focus-ring-style: var(--kite-focus-ring-style);
	--kite-checkbox-focus-ring-radius: var(--kite-border-radius-md);
	--kite-checkbox-focus-ring-width: var(--kite-focus-ring-width);
	--kite-checkbox-selected-background-color: var(--kite-color-primary);
	--kite-checkbox-selected-background-color-dark: var(--kite-color-primary);
	--kite-checkbox-selected-icon-color: var(--kite-color-white);
	--kite-checkbox-selected-icon-color-dark: var(--kite-color-white);
	--kite-checkbox-selected-icon-height: var(--kite-size-md);
	--kite-checkbox-selected-icon-width: var(--kite-size-md);
	--kite-checkbox-selected-disabled-border-color: var(--kite-color-disabled);
	--kite-checkbox-selected-disabled-border-color-dark: var(--kite-color-disabled-dark);
	--kite-checkbox-selected-disabled-background-color: var(--kite-color-disabled);
	--kite-checkbox-selected-disabled-background-color-dark: var(--kite-color-disabled-dark);
	--kite-checkbox-selected-disabled-icon-color: var(--kite-checkbox-disabled-icon-color);
	--kite-checkbox-selected-disabled-icon-color-dark: var(--kite-checkbox-disabled-icon-color-dark);
	--kite-checkbox-indeterminate-background-color: var(--kite-checkbox-selected-background-color);
	--kite-checkbox-indeterminate-background-color-dark: var(--kite-checkbox-selected-background-color);
	--kite-checkbox-indeterminate-icon-color: var(--kite-checkbox-selected-icon-color);
	--kite-checkbox-indeterminate-icon-color-dark: var(--kite-checkbox-selected-icon-color-dark);
	--kite-checkbox-indeterminate-icon-height: var(--kite-checkbox-selected-icon-height);
	--kite-checkbox-indeterminate-icon-width: var(--kite-checkbox-selected-icon-width);
	--kite-checkbox-indeterminate-disabled-border-color: var(--kite-checkbox-selected-disabled-border-color);
	--kite-checkbox-indeterminate-disabled-border-color-dark: var(--kite-checkbox-selected-disabled-border-color-dark);
	--kite-checkbox-indeterminate-disabled-background-color: var(--kite-checkbox-selected-disabled-background-color);
	--kite-checkbox-indeterminate-disabled-background-color-dark: var(--kite-checkbox-selected-disabled-background-color-dark);
	--kite-checkbox-indeterminate-disabled-icon-color: var(--kite-checkbox-disabled-icon-color);
	--kite-checkbox-indeterminate-disabled-icon-color-dark: var(--kite-checkbox-disabled-icon-color-dark);
	--kite-checkbox-group-margin-top: var(--kite-spacing-element-xxl);
	--kite-checkbox-group-margin-bottom: var(--kite-spacing-element-xxl);
	--kite-chip-background-color: var(--kite-color-background);
	--kite-chip-background-color-dark: var(--kite-color-background-dark);
	--kite-chip-text-color: var(--kite-color-dark-blue-30);
	--kite-chip-text-color-dark: var(--kite-color-light-blue-10);
	--kite-chip-border-color: var(--kite-color-gray-30);
	--kite-chip-border-color-dark: var(--kite-chip-text-color-dark);
	--kite-chip-border-radius: var(--kite-border-radius-circle);
	--kite-chip-border-style: var(--kite-form-control-input-border-style);
	--kite-chip-border-width: var(--kite-form-control-input-border-width);
	--kite-chip-margin-top: var(--kite-spacing-element-md);
	--kite-chip-margin-right: var(--kite-spacing-element-md);
	--kite-chip-margin-bottom: var(--kite-spacing-element-md);
	--kite-chip-margin-left: var(--kite-spacing-element-md);
	--kite-chip-padding-top: var(--kite-spacing-element-xxs);
	--kite-chip-padding-right: var(--kite-spacing-element-xxl);
	--kite-chip-padding-bottom: var(--kite-spacing-element-xxs);
	--kite-chip-padding-left: var(--kite-spacing-element-xxl);
	--kite-chip-min-height: var(--kite-size-xxl);
	--kite-chip-height: var(--kite-size-xxl);
	--kite-chip-focus-ring-color: var(--kite-color-gray-30);
	--kite-chip-focus-ring-color-dark: var(--kite-chip-text-color-dark);
	--kite-chip-focus-ring-style: var(--kite-focus-ring-style);
	--kite-chip-focus-ring-width: var(--kite-focus-ring-width);
	--kite-chip-hover-background-color: var(--kite-color-gray-20);
	--kite-chip-hover-background-color-dark: var(--kite-color-gray-20);
	--kite-chip-selected-background-color: var(--kite-color-gray-30);
	--kite-chip-selected-background-color-dark: var(--kite-chip-text-color-dark);
	--kite-chip-selected-text-color: var(--kite-color-text-dark);
	--kite-chip-selected-text-color-dark: var(--kite-chip-text-color);
	--kite-chip-selected-disabled-background-color: var(--kite-color-disabled);
	--kite-chip-selected-disabled-background-color-dark: var(--kite-color-disabled-dark);
	--kite-chip-selected-disabled-text-color: var(--kite-color-gray-20);
	--kite-chip-selected-disabled-text-color-dark: var(--kite-color-disabled);
	--kite-chip-disabled-background-color: var(--kite-chip-background-color);
	--kite-chip-disabled-background-color-dark: var(--kite-color-background-dark);
	--kite-chip-disabled-text-color: var(--kite-color-disabled);
	--kite-chip-disabled-text-color-dark: var(--kite-color-disabled-dark);
	--kite-chip-disabled-cursor: var(--kite-cursor-disabled);
	--kite-chip-icon-margin: var(--kite-spacing-element-xs);
	--kite-data-table-text-color: var(--kite-color-text);
	--kite-data-table-text-color-dark: var(--kite-color-text-dark);
	--kite-data-table-title-row-border-bottom-color: var(--kite-border-color);
	--kite-data-table-title-row-border-bottom-color-dark: var(--kite-color-dark-blue-10);
	--kite-data-table-title-row-border-bottom-style: solid;
	--kite-data-table-title-row-border-bottom-width: var(--kite-border-width-thin);
	--kite-data-table-title-row-text-color: var(--kite-color-text);
	--kite-data-table-title-row-text-color-dark: var(--kite-color-text-dark);
	--kite-data-table-title-row-text-weight: var(--kite-font-weight-bold);
	--kite-data-table-title-row-icon-asc: ki-more-up-f;
	--kite-data-table-title-row-icon-desc: ki-more-down-f;
	--kite-data-table-title-row-icon-margin-left: var(--kite-size-xxs);
	--kite-data-table-title-row-icon-size: var(--kite-size-md);
	--kite-data-table-cell-border-color: var(--kite-border-color);
	--kite-data-table-cell-border-color-dark: var(--kite-color-dark-blue-10);
	--kite-data-table-cell-border-style: solid;
	--kite-data-table-cell-border-width: var(--kite-border-width-thin);
	--kite-data-table-cell-compact-padding-top: var(--kite-spacing-element-xs);
	--kite-data-table-cell-compact-padding-right: var(--kite-spacing-element-xs);
	--kite-data-table-cell-compact-padding-bottom: var(--kite-spacing-element-xs);
	--kite-data-table-cell-compact-padding-left: var(--kite-spacing-element-xs);
	--kite-data-table-cell-default-padding-top: var(--kite-spacing-element-sm);
	--kite-data-table-cell-default-padding-right: var(--kite-spacing-element-sm);
	--kite-data-table-cell-default-padding-bottom: var(--kite-spacing-element-sm);
	--kite-data-table-cell-default-padding-left: var(--kite-spacing-element-sm);
	--kite-data-table-cell-comfortable-padding-top: var(--kite-spacing-element-md);
	--kite-data-table-cell-comfortable-padding-right: var(--kite-spacing-element-md);
	--kite-data-table-cell-comfortable-padding-bottom: var(--kite-spacing-element-md);
	--kite-data-table-cell-comfortable-padding-left: var(--kite-spacing-element-md);
	--kite-data-table-cell-focus-ring-color: var(--kite-focus-ring-color);
	--kite-data-table-cell-focus-ring-color-dark: var(--kite-focus-ring-color-dark);
	--kite-data-table-cell-focus-ring-style: var(--kite-focus-ring-style);
	--kite-data-table-cell-focus-ring-width: var(--kite-focus-ring-width);
	--kite-data-table-row-border-color: var(--kite-border-color);
	--kite-data-table-row-border-color-dark: var(--kite-color-dark-blue-10);
	--kite-data-table-row-border-style: solid;
	--kite-data-table-row-border-width: var(--kite-border-width-thin);
	--kite-data-table-row-striped-background-color: var(--kite-color-gray-10);
	--kite-data-table-row-striped-background-color-dark: var(--kite-color-dark-blue-30);
	--kite-data-table-row-hover-background-color: var(--kite-color-gray-20);
	--kite-data-table-row-hover-background-color-dark: var(--kite-color-dark-blue-10);
	--kite-data-table-row-focus-ring-color: var(--kite-focus-ring-color);
	--kite-data-table-row-focus-ring-color-dark: var(--kite-focus-ring-color-dark);
	--kite-data-table-row-focus-ring-style: var(--kite-focus-ring-style);
	--kite-data-table-row-focus-ring-width: var(--kite-focus-ring-width);
	--kite-data-table-lg-text-size: var(--kite-typography-lg-body-size);
	--kite-data-table-lg-breakpoint: var(--kite-media-query-md);
	--kite-data-table-sm-text-size: var(--kite-size-14);
	--kite-dialog-overlay-background-color: var(--kite-color-dark-blue-20);
	--kite-dialog-overlay-background-color-dark: var(--kite-color-dark-blue-30);
	--kite-dialog-overlay-opacity: var(--kite-opacity-8);
	--kite-dialog-overlay-z-index: var(--kite-z-index-dialog-backdrop);
	--kite-dialog-background-color: var(--kite-card-background-color);
	--kite-dialog-background-color-dark: var(--kite-card-background-color-dark);
	--kite-dialog-box-shadow: var(--kite-elevation-3);
	--kite-dialog-dismiss-icon-cursor: var(--kite-cursor-action);
	--kite-dialog-dismiss-icon-fill: var(--kite-color-black);
	--kite-dialog-dismiss-icon-fill-dark: var(--kite-color-white);
	--kite-dialog-dismiss-icon-height: var(--kite-size-xl);
	--kite-dialog-dismiss-icon-width: var(--kite-size-xl);
	--kite-dialog-max-width: 42.5rem;
	--kite-dialog-title-text-size: var(--kite-typography-sm-title-5-size);
	--kite-dialog-title-text-weight: var(--kite-font-weight-bold);
	--kite-dialog-title-text-line-height: var(--kite-typography-sm-title-3-line-height);
	--kite-dialog-title-text-margin-bottom: var(--kite-spacing-element-md);
	--kite-dialog-title-icon-margin-right: var(--kite-spacing-element-xs);
	--kite-dialog-title-icon-height: var(--kite-size-xl);
	--kite-dialog-text-color: var(--kite-color-text);
	--kite-dialog-text-color-dark: var(--kite-color-text-dark);
	--kite-dialog-text-align: left;
	--kite-dialog-z-index: var(--kite-z-index-dialog);
	--kite-dialog-full-size-width: 100%;
	--kite-dialog-full-size-height: 100%;
	--kite-dialog-full-size-padding-top: var(--kite-spacing-element-lg);
	--kite-dialog-full-size-padding-right: var(--kite-spacing-element-lg);
	--kite-dialog-full-size-padding-bottom: var(--kite-spacing-element-lg);
	--kite-dialog-full-size-padding-left: var(--kite-spacing-element-md);
	--kite-dialog-default-size-border-radius: var(--kite-border-radius-md);
	--kite-dialog-default-size-padding-top: var(--kite-spacing-section-xs);
	--kite-dialog-default-size-padding-right: var(--kite-spacing-section-xs);
	--kite-dialog-default-size-padding-bottom: var(--kite-spacing-section-xs);
	--kite-dialog-default-size-padding-left: var(--kite-spacing-section-xs);
	--kite-dialog-default-size-md-max-height: 72%;
	--kite-dialog-default-size-lg-max-height: 72%;
	--kite-dialog-default-size-xl-max-height: 84%;
	--kite-dialog-default-size-xxl-max-height: 84%;
	--kite-dropdown-background-color: var(--kite-form-control-input-background-color);
	--kite-dropdown-background-color-dark: var(--kite-form-control-input-background-color-dark);
	--kite-dropdown-border-color: var(--kite-form-control-input-border-color);
	--kite-dropdown-border-color-dark: var(--kite-form-control-input-border-color-dark);
	--kite-dropdown-border-style: var(--kite-form-control-input-border-style);
	--kite-dropdown-border-width: var(--kite-form-control-input-border-width);
	--kite-dropdown-cursor: var(--kite-form-control-input-cursor);
	--kite-dropdown-height: var(--kite-size-touch-target);
	--kite-dropdown-icon-height: var(--kite-size-xl);
	--kite-dropdown-icon-width: var(--kite-size-xl);
	--kite-dropdown-icon-color: var(--kite-form-control-input-text-color);
	--kite-dropdown-icon-color-dark: var(--kite-form-control-input-text-color-dark);
	--kite-dropdown-label-text-color: var(--kite-form-control-label-text-color);
	--kite-dropdown-label-text-color-dark: var(--kite-form-control-label-text-color-dark);
	--kite-dropdown-label-margin-bottom: var(--kite-form-control-label-margin-bottom);
	--kite-dropdown-max-width: var(--kite-form-control-input-max-width);
	--kite-dropdown-padding-top: var(--kite-form-control-input-padding-top);
	--kite-dropdown-padding-right: var(--kite-form-control-input-padding-right);
	--kite-dropdown-padding-bottom: var(--kite-form-control-input-padding-bottom);
	--kite-dropdown-padding-left: var(--kite-form-control-input-padding-right);
	--kite-dropdown-text-color: var(--kite-form-control-input-text-color);
	--kite-dropdown-text-color-dark: var(--kite-form-control-input-text-color-dark);
	--kite-dropdown-focus-ring-color: var(--kite-form-control-input-focus-ring-color);
	--kite-dropdown-focus-ring-color-dark: var(--kite-form-control-input-focus-ring-color-dark);
	--kite-dropdown-focus-ring-style: var(--kite-form-control-input-focus-ring-style);
	--kite-dropdown-focus-ring-width: var(--kite-form-control-input-focus-ring-width);
	--kite-dropdown-disabled-background-color: var(--kite-form-control-input-disabled-background-color);
	--kite-dropdown-disabled-background-color-dark: var(--kite-form-control-input-disabled-background-color-dark);
	--kite-dropdown-disabled-border-color: var(--kite-form-control-input-disabled-border-color);
	--kite-dropdown-disabled-border-color-dark: var(--kite-form-control-input-disabled-border-color-dark);
	--kite-dropdown-disabled-cursor: var(--kite-form-control-input-disabled-cursor);
	--kite-dropdown-disabled-icon-color: var(--kite-color-disabled);
	--kite-dropdown-disabled-icon-color-dark: var(--kite-form-control-input-disabled-text-color-dark);
	--kite-dropdown-disabled-text-color: var(--kite-form-control-input-disabled-text-color);
	--kite-dropdown-disabled-text-color-dark: var(--kite-form-control-input-disabled-text-color-dark);
	--kite-dropdown-list-background-color: var(--kite-color-white);
	--kite-dropdown-list-background-color-dark: var(--kite-color-dark-blue-20);
	--kite-dropdown-list-border-color: var(--kite-border-color);
	--kite-dropdown-list-border-color-dark: var(--kite-color-gray-30);
	--kite-dropdown-list-border-radius: var(--kite-border-radius-md);
	--kite-dropdown-list-border-style: solid;
	--kite-dropdown-list-border-width: var(--kite-border-width-thin);
	--kite-dropdown-list-cursor: var(--kite-cursor-action);
	--kite-dropdown-list-elevation: var(--kite-elevation-2);
	--kite-dropdown-list-elevation-dark: var(--kite-elevation-2-dark);
	--kite-dropdown-list-z-index: var(--kite-z-index-dropdown);
	--kite-dropdown-list-margin-top: var(--kite-spacing-element-xs);
	--kite-dropdown-list-offset-right: var(--kite-spacing-element-md);
	--kite-dropdown-list-max-width: 21rem;
	--kite-dropdown-list-min-width: 10.5rem;
	--kite-dropdown-list-transition-curve: cubic-bezier(0.25, 0.8, 0.5, 1);
	--kite-dropdown-list-transition-duration: var(--kite-motion-duration-fast);
	--kite-dropdown-list-transition-target-property: opacity;
	--kite-dropdown-list-hidden-opacity: 0;
	--kite-dropdown-list-visible-opacity: 1;
	--kite-dropdown-list-item-border-bottom-color: var(--kite-border-color);
	--kite-dropdown-list-item-border-bottom-color-dark: var(--kite-border-color);
	--kite-dropdown-list-item-border-bottom-style: solid;
	--kite-dropdown-list-item-border-bottom-width: var(--kite-border-width-thin);
	--kite-dropdown-list-item-destructive-text-color: var(--kite-color-red-20);
	--kite-dropdown-list-item-destructive-text-color-dark: var(--kite-color-red-10);
	--kite-dropdown-list-item-destructive-icon-color: var(--kite-color-red-20);
	--kite-dropdown-list-item-destructive-icon-color-dark: var(--kite-color-red-10);
	--kite-dropdown-list-item-padding-top: var(--kite-spacing-element-sm);
	--kite-dropdown-list-item-padding-right: var(--kite-spacing-element-md);
	--kite-dropdown-list-item-padding-bottom: var(--kite-spacing-element-sm);
	--kite-dropdown-list-item-padding-left: var(--kite-spacing-element-md);
	--kite-dropdown-list-item-text-color: var(--kite-color-text);
	--kite-dropdown-list-item-text-color-dark: var(--kite-color-text-dark);
	--kite-dropdown-list-item-text-size: var(--kite-typography-lg-body-size);
	--kite-dropdown-list-item-icon-fill: var(--kite-color-dark-blue-30);
	--kite-dropdown-list-item-icon-fill-dark: var(--kite-color-dark-blue-30);
	--kite-dropdown-list-item-icon-height: var(--kite-size-lg);
	--kite-dropdown-list-item-icon-width: var(--kite-size-lg);
	--kite-dropdown-list-item-icon-margin-right: var(--kite-spacing-element-md);
	--kite-dropdown-list-item-disabled-text-color: var(--kite-color-disabled);
	--kite-dropdown-list-item-disabled-text-color-dark: var(--kite-color-disabled-dark);
	--kite-dropdown-list-item-disabled-cursor: var(--kite-cursor-disabled);
	--kite-dropdown-list-item-hover-background-color: var(--kite-color-gray-20);
	--kite-dropdown-list-item-hover-background-color-dark: var(--kite-color-gray-30);
	--kite-dropdown-list-item-focus-ring-color: var(--kite-focus-ring-color);
	--kite-dropdown-list-item-focus-ring-color-dark: var(--kite-focus-ring-color-dark);
	--kite-dropdown-list-item-focus-ring-style: var(--kite-focus-ring-style);
	--kite-dropdown-list-item-focus-ring-width: var(--kite-focus-ring-width);
	--kite-dropdown-inline-background-color: transparent;
	--kite-dropdown-inline-background-color-dark: transparent;
	--kite-dropdown-inline-border-color: transparent;
	--kite-dropdown-inline-border-color-dark: transparent;
	--kite-dropdown-inline-border-style: var(--kite-form-control-input-border-style);
	--kite-dropdown-inline-border-width: 0;
	--kite-dropdown-inline-focus-ring-color: var(--kite-focus-ring-color);
	--kite-dropdown-inline-focus-ring-color-dark: var(--kite-focus-ring-color-dark);
	--kite-dropdown-inline-focus-ring-style: var(--kite-focus-ring-style);
	--kite-dropdown-inline-focus-ring-width: var(--kite-focus-ring-width);
	--kite-dropdown-inline-margin-top: 0;
	--kite-dropdown-inline-margin-right: 0;
	--kite-dropdown-inline-margin-bottom: 0;
	--kite-dropdown-inline-margin-left: 0;
	--kite-dropdown-inline-padding-top: 0;
	--kite-dropdown-inline-padding-right: 0;
	--kite-dropdown-inline-padding-bottom: 0;
	--kite-dropdown-inline-padding-left: 0;
	--kite-dropdown-inline-text-color: var(--kite-form-control-input-text-color);
	--kite-dropdown-inline-text-color-dark: var(--kite-form-control-input-text-color-dark);
	--kite-dropdown-inline-label-text-weight: var(--kite-font-weight-bold);
	--kite-dropdown-inline-placeholder-margin-left: var(--kite-size-xxs);
	--kite-dropdown-inline-placeholder-margin-right: var(--kite-size-xxs);
	--kite-dropdown-inline-placeholder-text-color: var(--kite-color-gray-30);
	--kite-dropdown-inline-disabled-text-color: var(--kite-form-control-input-disabled-text-color);
	--kite-dropdown-inline-disabled-text-color-dark: var(--kite-form-control-input-disabled-text-color-dark);
	--kite-dropdown-inline-disabled-cursor: var(--kite-form-control-input-disabled-cursor);
	--kite-dropdown-inline-disabled-icon-color: var(--kite-form-control-input-disabled-text-color);
	--kite-dropdown-inline-disabled-icon-color-dark: var(--kite-form-control-input-disabled-text-color-dark);
	--kite-dynamic-hint-background-color: var(--kite-card-background-color);
	--kite-dynamic-hint-background-color-dark: var(--kite-color-dark-blue-10);
	--kite-dynamic-hint-border-color: var(--kite-card-border-color);
	--kite-dynamic-hint-border-color-dark: transparent;
	--kite-dynamic-hint-border-radius: var(--kite-card-border-radius);
	--kite-dynamic-hint-border-style: solid;
	--kite-dynamic-hint-border-width: var(--kite-card-border-width);
	--kite-dynamic-hint-elevation: var(--kite-elevation-2);
	--kite-dynamic-hint-elevation-dark: var(--kite-elevation-2-dark);
	--kite-dynamic-hint-margin: var(--kite-spacing-element-xs);
	--kite-dynamic-hint-padding-top: var(--kite-spacing-element-md);
	--kite-dynamic-hint-padding-right: var(--kite-spacing-element-md);
	--kite-dynamic-hint-padding-bottom: var(--kite-spacing-element-md);
	--kite-dynamic-hint-padding-left: var(--kite-spacing-element-md);
	--kite-dynamic-hint-arrow-background-color: var(--kite-dynamic-hint-background-color);
	--kite-dynamic-hint-arrow-background-color-dark: var(--kite-dynamic-hint-background-color-dark);
	--kite-dynamic-hint-arrow-border-color: var(--kite-dynamic-hint-border-color);
	--kite-dynamic-hint-arrow-border-color-dark: var(--kite-dynamic-hint-border-color-dark);
	--kite-dynamic-hint-arrow-border-style: solid;
	--kite-dynamic-hint-arrow-border-width: var(--kite-dynamic-hint-border-width);
	--kite-dynamic-hint-arrow-height: var(--kite-size-xs);
	--kite-dynamic-hint-arrow-width: var(--kite-size-sm);
	--kite-dynamic-hint-arrow-offset: var(--kite-spacing-element-xxl);
	--kite-dynamic-hint-title-text-weight: var(--kite-font-weight-bold);
	--kite-dynamic-hint-title-margin-bottom: var(--kite-spacing-element-md);
	--kite-dynamic-hint-entry-transition-duration: -cubic-bezier (0,0,0.58,1);
	--kite-dynamic-hint-exit-transition-duration: -cubic-bezier (0.42,0,1,1);
	--kite-dynamic-hint-criteria-margin-bottom: var(--kite-spacing-element-md);
	--kite-dynamic-hint-criteria-icon-height: var(--kite-size-md);
	--kite-dynamic-hint-criteria-icon-width: var(--kite-size-md);
	--kite-dynamic-hint-criteria-icon-margin-right: var(--kite-spacing-element-sm);
	--kite-dynamic-hint-criteria-text-size: var(--kite-typography-lg-body-size);
	--kite-dynamic-hint-criteria-text-color: var(--kite-color-text);
	--kite-dynamic-hint-criteria-text-color-dark: var(--kite-color-text-dark);
	--kite-dynamic-hint-criteria-success-icon-fill: var(--kite-color-success);
	--kite-dynamic-hint-criteria-success-icon-fill-dark: var(--kite-color-success-dark);
	--kite-dynamic-hint-criteria-error-icon-fill: var(--kite-color-error);
	--kite-dynamic-hint-criteria-error-icon-fill-dark: var(--kite-color-error-dark);
	--kite-form-group-margin-bottom: var(--kite-spacing-element-xl);
	--kite-interactive-list-padding-top: 0;
	--kite-interactive-list-padding-right: 0;
	--kite-interactive-list-padding-bottom: 0;
	--kite-interactive-list-padding-left: 0;
	--kite-interactive-list-md-padding-top: 0;
	--kite-interactive-list-md-padding-right: var(--kite-spacing-element-md);
	--kite-interactive-list-md-padding-bottom: 0;
	--kite-interactive-list-md-padding-left: var(--kite-spacing-element-md);
	--kite-interactive-list-xl-padding-top: 0;
	--kite-interactive-list-xl-padding-right: var(--kite-spacing-element-xl);
	--kite-interactive-list-xl-padding-bottom: 0;
	--kite-interactive-list-xl-padding-left: var(--kite-spacing-element-xl);
	--kite-interactive-list-xxl-padding-top: 0;
	--kite-interactive-list-xxl-padding-right: var(--kite-spacing-element-xxl);
	--kite-interactive-list-xxl-padding-bottom: 0;
	--kite-interactive-list-xxl-padding-left: var(--kite-spacing-element-xxl);
	--kite-interactive-list-item-label-text-color: var(--kite-color-text);
	--kite-interactive-list-item-label-text-color-dark: var(--kite-color-text-dark);
	--kite-interactive-list-item-label-text-size: var(--kite-typography-lg-body-size);
	--kite-interactive-list-item-label-margin-right: var(--kite-spacing-element-md);
	--kite-interactive-list-item-danger-label-text-color: var(--kite-color-red-20);
	--kite-interactive-list-item-danger-label-text-color-dark: var(--kite-color-red-10);
	--kite-interactive-list-item-info-label-text-color: var(--kite-color-blue-20);
	--kite-interactive-list-item-info-label-text-color-dark: var(--kite-color-blue-10);
	--kite-interactive-list-item-success-label-text-color: var(--kite-color-success);
	--kite-interactive-list-item-success-label-text-color-dark: var(--kite-color-success-dark);
	--kite-interactive-list-item-border-color: var(--kite-border-color);
	--kite-interactive-list-item-border-color-dark: var(--kite-color-dark-blue-10);
	--kite-interactive-list-item-border-style: solid;
	--kite-interactive-list-item-border-width: var(--kite-border-width-thin);
	--kite-interactive-list-item-cursor: var(--kite-cursor-action);
	--kite-interactive-list-item-margin-top: var(--kite-spacing-element-xs);
	--kite-interactive-list-item-margin-right: 0;
	--kite-interactive-list-item-margin-bottom: var(--kite-spacing-element-xxl);
	--kite-interactive-list-item-margin-left: 0;
	--kite-interactive-list-item-padding-top: var(--kite-spacing-element-md);
	--kite-interactive-list-item-padding-right: var(--kite-spacing-element-md);
	--kite-interactive-list-item-padding-bottom: var(--kite-spacing-element-md);
	--kite-interactive-list-item-padding-left: var(--kite-spacing-element-sm);
	--kite-interactive-list-item-text-color: var(--kite-color-text);
	--kite-interactive-list-item-text-color-dark: var(--kite-color-text-dark);
	--kite-interactive-list-item-icon-color: var(--kite-interactive-list-item-text-color);
	--kite-interactive-list-item-icon-color-dark: var(--kite-interactive-list-item-text-color-dark);
	--kite-interactive-list-item-icon-margin: var(--kite-spacing-element-md);
	--kite-interactive-list-item-icon-height: var(--kite-size-md);
	--kite-interactive-list-item-icon-width: var(--kite-size-md);
	--kite-interactive-list-item-action-icon-color: var(--kite-interactive-list-item-text-color);
	--kite-interactive-list-item-action-icon-color-dark: var(--kite-interactive-list-item-text-color-dark);
	--kite-interactive-list-item-action-icon-height: var(--kite-interactive-list-item-icon-height);
	--kite-interactive-list-item-action-icon-width: var(--kite-interactive-list-item-icon-width);
	--kite-interactive-list-item-title-text-size: var(--kite-typography-lg-body-size);
	--kite-interactive-list-item-multiline-title-text-size: var(--kite-typography-lg-title-6-size);
	--kite-interactive-list-item-multiline-title-text-weight: var(--kite-font-weight-bold);
	--kite-interactive-list-item-multiline-title-margin-bottom: var(--kite-spacing-element-xxs);
	--kite-interactive-list-item-multiline-subtitle-text-size: var(--kite-typography-lg-body-size);
	--kite-interactive-list-item-hover-background-color: 0,  16,  25;
	--kite-interactive-list-item-hover-background-color-dark: 255, 255, 255;
	--kite-interactive-list-item-hover-background-opacity: 0.02;
	--kite-interactive-list-item-hover-background-opacity-dark: 0.05;
	--kite-interactive-list-item-disabled-cursor: var(--kite-cursor-disabled);
	--kite-interactive-list-item-disabled-text-color: var(--kite-color-disabled);
	--kite-interactive-list-item-disabled-text-color-dark: var(--kite-color-disabled-dark);
	--kite-interactive-list-item-disabled-icon-color: var(--kite-interactive-list-item-disabled-text-color);
	--kite-interactive-list-item-disabled-icon-color-dark: var(--kite-interactive-list-item-disabled-text-color-dark);
	--kite-interactive-list-item-disabled-action-icon-color: var(--kite-interactive-list-item-disabled-text-color);
	--kite-interactive-list-item-disabled-action-icon-color-dark: var(--kite-interactive-list-item-disabled-text-color-dark);
	--kite-interactive-list-item-focus-ring-color: var(--kite-focus-ring-color);
	--kite-interactive-list-item-focus-ring-color-dark: var(--kite-focus-ring-color-dark);
	--kite-interactive-list-item-focus-ring-offset: var(--kite-focus-ring-inset);
	--kite-interactive-list-item-focus-ring-style: var(--kite-focus-ring-style);
	--kite-interactive-list-item-focus-ring-width: var(--kite-focus-ring-width);
	--kite-link-text-color: var(--kite-color-primary);
	--kite-link-text-color-dark: var(--kite-color-text-dark);
	--kite-link-text-decoration: underline;
	--kite-link-text-size: var(--kite-typography-lg-body-size);
	--kite-link-text-weight: var(--kite-font-weight-regular);
	--kite-link-standalone-text-weight: var(--kite-font-weight-medium);
	--kite-link-standalone-text-decoration: none;
	--kite-link-standalone-icon-fill: var(--kite-color-primary);
	--kite-link-standalone-icon-fill-dark: var(--kite-color-white);
	--kite-link-standalone-icon-height: var(--kite-size-sm);
	--kite-link-standalone-icon-width: var(--kite-size-sm);
	--kite-link-standalone-icon-margin-left: var(--kite-spacing-element-xxs);
	--kite-link-standalone-active-icon-fill: var(--kite-color-dark-blue-20);
	--kite-link-standalone-active-icon-fill-dark: var(--kite-color-gray-30);
	--kite-link-standalone-active-text-decoration: underline;
	--kite-link-standalone-focus-icon-fill: var(--kite-color-primary);
	--kite-link-standalone-focus-icon-fill-dark: var(--kite-color-text-dark);
	--kite-link-standalone-hover-icon-fill: var(--kite-color-dark-blue-10);
	--kite-link-standalone-hover-icon-fill-dark: var(--kite-color-gray-25);
	--kite-link-standalone-hover-text-decoration: underline;
	--kite-link-standalone-visited-icon-fill: var(--kite-color-dark-blue-10);
	--kite-link-standalone-visited-icon-fill-dark: var(--kite-color-gray-25);
	--kite-link-active-text-color: var(--kite-color-dark-blue-20);
	--kite-link-active-text-color-dark: var(--kite-color-gray-30);
	--kite-link-active-text-decoration: underline;
	--kite-link-visited-text-color: var(--kite-color-dark-blue-10);
	--kite-link-visited-text-color-dark: var(--kite-color-gray-25);
	--kite-link-focus-text-color: var(--kite-color-primary);
	--kite-link-focus-text-color-dark: var(--kite-color-text-dark);
	--kite-link-focus-ring-color: var(--kite-focus-ring-color);
	--kite-link-focus-ring-color-dark: var(--kite-focus-ring-color-dark);
	--kite-link-focus-ring-style: var(--kite-focus-ring-style);
	--kite-link-focus-ring-width: var(--kite-focus-ring-width);
	--kite-link-hover-text-color: var(--kite-color-dark-blue-10);
	--kite-link-hover-text-color-dark: var(--kite-color-gray-25);
	--kite-menu-list-background-color: var(--kite-color-white);
	--kite-menu-list-background-color-dark: var(--kite-color-dark-blue-20);
	--kite-menu-list-border-color: var(--kite-border-color);
	--kite-menu-list-border-color-dark: var(--kite-color-gray-30);
	--kite-menu-list-border-radius: var(--kite-border-radius-md);
	--kite-menu-list-border-style: solid;
	--kite-menu-list-border-width: var(--kite-border-width-thin);
	--kite-menu-list-cursor: var(--kite-cursor-action);
	--kite-menu-list-elevation: var(--kite-elevation-2);
	--kite-menu-list-elevation-dark: var(--kite-elevation-2-dark);
	--kite-menu-list-z-index: var(--kite-z-index-dropdown);
	--kite-menu-list-margin-top: var(--kite-spacing-element-xs);
	--kite-menu-list-offset-right: var(--kite-spacing-element-md);
	--kite-menu-list-max-width: 21rem;
	--kite-menu-list-min-width: 10.5rem;
	--kite-menu-list-transition-curve: cubic-bezier(0.25, 0.8, 0.5, 1);
	--kite-menu-list-transition-duration: var(--kite-motion-duration-fast);
	--kite-menu-list-transition-target-property: opacity;
	--kite-menu-list-hidden-opacity: 0;
	--kite-menu-list-visible-opacity: 1;
	--kite-menu-list-item-border-bottom-color: var(--kite-border-color);
	--kite-menu-list-item-border-bottom-color-dark: var(--kite-border-color);
	--kite-menu-list-item-border-bottom-style: solid;
	--kite-menu-list-item-border-bottom-width: var(--kite-border-width-thin);
	--kite-menu-list-item-destructive-text-color: var(--kite-color-red-20);
	--kite-menu-list-item-destructive-text-color-dark: var(--kite-color-red-10);
	--kite-menu-list-item-destructive-icon-color: var(--kite-color-red-20);
	--kite-menu-list-item-destructive-icon-color-dark: var(--kite-color-red-10);
	--kite-menu-list-item-padding-top: var(--kite-spacing-element-sm);
	--kite-menu-list-item-padding-right: var(--kite-spacing-element-md);
	--kite-menu-list-item-padding-bottom: var(--kite-spacing-element-sm);
	--kite-menu-list-item-padding-left: var(--kite-spacing-element-md);
	--kite-menu-list-item-text-color: var(--kite-color-text);
	--kite-menu-list-item-text-color-dark: var(--kite-color-text-dark);
	--kite-menu-list-item-text-size: var(--kite-typography-lg-body-size);
	--kite-menu-list-item-icon-fill: var(--kite-color-dark-blue-30);
	--kite-menu-list-item-icon-fill-dark: var(--kite-color-dark-blue-30);
	--kite-menu-list-item-icon-height: var(--kite-size-lg);
	--kite-menu-list-item-icon-width: var(--kite-size-lg);
	--kite-menu-list-item-icon-margin-right: var(--kite-spacing-element-md);
	--kite-menu-list-item-disabled-text-color: var(--kite-color-disabled);
	--kite-menu-list-item-disabled-text-color-dark: var(--kite-color-disabled-dark);
	--kite-menu-list-item-disabled-cursor: var(--kite-cursor-disabled);
	--kite-menu-list-item-hover-background-color: var(--kite-color-gray-20);
	--kite-menu-list-item-hover-background-color-dark: var(--kite-color-gray-30);
	--kite-menu-list-item-focus-ring-color: var(--kite-focus-ring-color);
	--kite-menu-list-item-focus-ring-color-dark: var(--kite-focus-ring-color-dark);
	--kite-menu-list-item-focus-ring-style: var(--kite-focus-ring-style);
	--kite-menu-list-item-focus-ring-width: var(--kite-focus-ring-width);
	--kite-menu-trigger-border-radius: var(--kite-border-radius-md);
	--kite-menu-trigger-padding-top: var(--kite-spacing-element-sm);
	--kite-menu-trigger-padding-right: var(--kite-spacing-element-sm);
	--kite-menu-trigger-padding-bottom: var(--kite-spacing-element-sm);
	--kite-menu-trigger-padding-left: var(--kite-spacing-element-sm);
	--kite-menu-trigger-icon-color: var(--kite-color-black);
	--kite-menu-trigger-icon-color-dark: var(--kite-color-white);
	--kite-menu-trigger-icon-height: var(--kite-size-md);
	--kite-menu-trigger-icon-width: var(--kite-size-md);
	--kite-menu-trigger-cursor: var(--kite-cursor-action);
	--kite-menu-trigger-focus-ring-color: var(--kite-focus-ring-color);
	--kite-menu-trigger-focus-ring-color-dark: var(--kite-focus-ring-color-dark);
	--kite-menu-trigger-focus-ring-style: var(--kite-focus-ring-style);
	--kite-menu-trigger-focus-ring-width: var(--kite-focus-ring-width);
	--kite-menu-trigger-hover-background-color: var(--kite-color-gray-20);
	--kite-menu-trigger-hover-background-color-dark: var(--kite-color-gray-30);
	--kite-menu-trigger-activated-icon-background-color: var(--kite-color-primary);
	--kite-menu-trigger-activated-icon-color: var(--kite-color-white);
	--kite-menu-trigger-disabled-icon-color: var(--kite-color-gray-25);
	--kite-menu-trigger-disabled-icon-color-dark: var(--kite-color-gray-30);
	--kite-meter-accessibility-tick-background-color: var(--kite-color-black);
	--kite-meter-accessibility-tick-background-color-dark: var(--kite-color-white);
	--kite-meter-accessibility-tick-width: var(--kite-size-xxs);
	--kite-meter-label-text-color: var(--kite-color-text);
	--kite-meter-label-text-color-dark: var(--kite-color-text-dark);
	--kite-meter-label-text-weight: var(--kite-font-weight-medium);
	--kite-meter-progress-fill-background-color: var(--kite-color-info);
	--kite-meter-progress-fill-background-color-dark: var(--kite-color-info-dark);
	--kite-meter-status-icon-height: var(--kite-size-sm);
	--kite-meter-status-icon-width: var(--kite-size-sm);
	--kite-meter-track-background-color: var(--kite-color-gray-20);
	--kite-meter-track-background-color-dark: var(--kite-color-dark-blue-10);
	--kite-meter-value-label-text-color: var(--kite-color-text);
	--kite-meter-value-label-text-color-dark: var(--kite-color-text-dark);
	--kite-meter-value-label-text-weight: var(--kite-font-weight-medium);
	--kite-meter-positive-progress-fill-background-color: var(--kite-color-success);
	--kite-meter-positive-progress-fill-background-color-dark: var(--kite-color-success-dark);
	--kite-meter-positive-status-icon-fill: var(--kite-color-success);
	--kite-meter-positive-status-icon-fill-dark: var(--kite-color-success-dark);
	--kite-meter-caution-progress-fill-background-color: var(--kite-color-caution);
	--kite-meter-caution-progress-fill-background-color-dark: var(--kite-color-yellow-10);
	--kite-meter-caution-status-icon-fill: var(--kite-color-caution);
	--kite-meter-caution-status-icon-fill-dark: var(--kite-color-yellow-10);
	--kite-meter-alert-progress-fill-background-color: var(--kite-color-error);
	--kite-meter-alert-progress-fill-background-color-dark: var(--kite-color-error-dark);
	--kite-meter-alert-status-icon-fill: var(--kite-color-error);
	--kite-meter-alert-status-icon-fill-dark: var(--kite-color-error-dark);
	--kite-meter-success-progress-fill-background-color: var(--kite-color-success);
	--kite-meter-success-progress-fill-background-color-dark: var(--kite-color-success-dark);
	--kite-meter-linear-track-border-radius: var(--kite-border-radius-md);
	--kite-meter-linear-track-height: var(--kite-size-xs);
	--kite-meter-linear-track-margin-bottom: var(--kite-spacing-element-xxs);
	--kite-meter-linear-label-text-margin-bottom: var(--kite-spacing-element-xs);
	--kite-meter-linear-label-text-size: 0.875rem;
	--kite-meter-linear-value-label-text-size: 0.875rem;
	--kite-meter-linear-small-border-radius: var(--kite-border-radius-md);
	--kite-meter-linear-small-track-height: 0.25rem;
	--kite-meter-linear-small-label-text-size: 0.875rem;
	--kite-meter-linear-small-value-label-text-size: 0.875rem;
	--kite-meter-linear-large-border-radius: var(--kite-border-radius-lg);
	--kite-meter-linear-large-track-height: var(--kite-size-md);
	--kite-meter-linear-large-label-text-size: var(--kite-size-lg);
	--kite-meter-linear-large-value-label-text-size: var(--kite-size-md);
	--kite-meter-circular-height: 5.5rem;
	--kite-meter-circular-width: var(--kite-meter-circular-height);
	--kite-meter-circular-track-height: var(--kite-size-xs);
	--kite-meter-circular-track-margin-bottom: var(--kite-spacing-element-xxs);
	--kite-meter-circular-label-margin-top: var(--kite-size-xxs);
	--kite-meter-circular-label-text-size: var(--kite-size-md);
	--kite-meter-circular-value-label-text-size: var(--kite-size-md);
	--kite-meter-circular-small-height: var(--kite-size-xl);
	--kite-meter-circular-small-width: var(--kite-meter-circular-small-height);
	--kite-meter-circular-small-accessibility-tick-width: 0.125rem;
	--kite-meter-circular-small-accessibility-tick-height: 0.125rem;
	--kite-meter-circular-small-status-icon-margin: 0.375rem;
	--kite-meter-circular-small-track-height: 0.125rem;
	--kite-meter-circular-small-track-margin-right: var(--kite-spacing-element-xs);
	--kite-meter-circular-small-label-text-size: 0.875rem;
	--kite-meter-circular-large-height: 9.25rem;
	--kite-meter-circular-large-width: var(--kite-meter-circular-large-height);
	--kite-meter-circular-large-status-icon-height: var(--kite-size-md);
	--kite-meter-circular-large-status-icon-margin: var(--kite-size-xs);
	--kite-meter-circular-large-status-icon-width: var(--kite-meter-circular-large-status-icon-height);
	--kite-meter-circular-large-track-height: var(--kite-size-xs);
	--kite-meter-circular-large-label-text-size: 0.875rem;
	--kite-meter-circular-large-value-label-text-size: var(--kite-size-lg);
	--kite-meter-circular-large-value-label-text-margin-bottom: var(--kite-spacing-element-xxs);
	--kite-my-component-background-color: var(--kite-color-background);
	--kite-my-component-background-color-dark: var(--kite-color-background-dark);
	--kite-my-component-text-color: var(--kite-color-text);
	--kite-my-component-text-color-dark: var(--kite-color-text-dark);
	--kite-my-component-border-color: var(--kite-form-control-input-border-color);
	--kite-my-component-border-color-dark: var(--kite-form-control-input-border-color-dark);
	--kite-my-component-border-radius: 0;
	--kite-my-component-border-style: var(--kite-form-control-input-border-style);
	--kite-my-component-border-width: var(--kite-form-control-input-border-width);
	--kite-my-component-margin-top: var(--kite-spacing-element-sm);
	--kite-my-component-margin-right: var(--kite-spacing-element-md);
	--kite-my-component-margin-bottom: var(--kite-spacing-element-sm);
	--kite-my-component-margin-left: var(--kite-spacing-element-md);
	--kite-my-component-padding-top: var(--kite-spacing-element-sm);
	--kite-my-component-padding-right: var(--kite-spacing-element-md);
	--kite-my-component-padding-bottom: var(--kite-spacing-element-sm);
	--kite-my-component-padding-left: var(--kite-spacing-element-md);
	--kite-my-component-min-width: var(--kite-size-touch-target);
	--kite-my-component-min-height: var(--kite-size-touch-target);
	--kite-my-component-width: var(--kite-size-touch-target);
	--kite-my-component-height: var(--kite-size-touch-target);
	--kite-my-component-focus-ring-color: var(--kite-focus-ring-color);
	--kite-my-component-focus-ring-color-dark: var(--kite-focus-ring-color-dark);
	--kite-my-component-focus-ring-style: var(--kite-focus-ring-style);
	--kite-my-component-focus-ring-width: var(--kite-focus-ring-width);
	--kite-my-component-disabled-cursor: var(--kite-cursor-disabled);
	--kite-my-component-cursor: var(--kite-cursor-action);
	--kite-my-component-list-background-color: var(--kite-color-white);
	--kite-my-component-list-text-color: var(--kite-color-text);
	--kite-my-component-list-padding-top: var(--kite-spacing-element-sm);
	--kite-my-component-list-padding-right: var(--kite-spacing-element-md);
	--kite-my-component-list-padding-bottom: var(--kite-spacing-element-sm);
	--kite-my-component-list-padding-left: var(--kite-spacing-element-md);
	--kite-my-component-error-background-color: var(--kite-color-error);
	--kite-my-component-variant-name-background-color: var(--kite-color-primary);
	--kite-my-component-variant-name-background-color-dark: var(--kite-color-primary-dark);
	--kite-my-component-variant-name-text-color: var(--kite-color-success);
	--kite-my-component-variant-name-text-color-dark: var(--kite-color-success-dark);
	--kite-my-component-variant-name-margin-left: var(--kite-spacing-element-lg);
	--kite-my-component-variant-name-padding-bottom: var(--kite-spacing-element-lg);
	--kite-pagination-controls-background-color: transparent;
	--kite-pagination-controls-background-color-dark: transparent;
	--kite-pagination-controls-border-color: var(--kite-border-color);
	--kite-pagination-controls-border-color-dark: var(--kite-color-dark-blue-10);
	--kite-pagination-controls-border-radius: var(--kite-border-radius-md);
	--kite-pagination-controls-border-style: solid;
	--kite-pagination-controls-border-width: var(--kite-border-width-thin);
	--kite-pagination-controls-padding-top: var(--kite-spacing-element-xxs);
	--kite-pagination-controls-padding-right: var(--kite-spacing-element-md);
	--kite-pagination-controls-padding-bottom: var(--kite-spacing-element-xxs);
	--kite-pagination-controls-padding-left: var(--kite-spacing-element-md);
	--kite-pagination-controls-button-background-color: transparent;
	--kite-pagination-controls-button-background-color-dark: transparent;
	--kite-pagination-controls-button-cursor: var(--kite-cursor-action);
	--kite-pagination-controls-button-icon-fill: var(--kite-color-blue-20);
	--kite-pagination-controls-button-icon-fill-dark: var(--kite-color-blue-20);
	--kite-pagination-controls-button-icon-height: var(--kite-size-md);
	--kite-pagination-controls-button-icon-width: var(--kite-size-md);
	--kite-pagination-controls-button-width: 4rem;
	--kite-pagination-controls-button-height: 3.25rem;
	--kite-pagination-controls-button-previous-border-color: var(--kite-pagination-controls-border-color);
	--kite-pagination-controls-button-previous-border-color-dark: var(--kite-pagination-controls-border-color-dark);
	--kite-pagination-controls-button-previous-border-radius: 0;
	--kite-pagination-controls-button-previous-border-style: var(--kite-pagination-controls-border-style);
	--kite-pagination-controls-button-previous-border-width: var(--kite-pagination-controls-border-width);
	--kite-pagination-controls-button-disabled-cursor: var(--kite-cursor-disabled);
	--kite-pagination-controls-button-disabled-icon-fill: var(--kite-color-disabled);
	--kite-pagination-controls-button-disabled-icon-fill-dark: var(--kite-color-gray-30);
	--kite-pagination-controls-button-hover-background-color: var(--kite-color-gray-20);
	--kite-pagination-controls-button-hover-background-color-dark: var(--kite-color-dark-blue-10);
	--kite-pagination-controls-button-focus-ring-color: var(--kite-focus-ring-color);
	--kite-pagination-controls-button-focus-ring-color-dark: var(--kite-focus-ring-color-dark);
	--kite-pagination-controls-button-focus-ring-style: var(--kite-focus-ring-style);
	--kite-pagination-controls-button-focus-ring-width: var(--kite-focus-ring-width);
	--kite-pagination-per-page-label-text-color: var(--kite-color-text);
	--kite-pagination-per-page-label-text-color-dark: var(--kite-color-text-dark);
	--kite-pagination-per-page-label-margin-right: var(--kite-spacing-element-xs);
	--kite-pagination-item-count-label-text-color: var(--kite-color-gray-30);
	--kite-pagination-item-count-label-text-color-dark: var(--kite-color-gray-25);
	--kite-pagination-item-count-label-margin-right: var(--kite-spacing-element-md);
	--kite-popover-trigger-cursor: var(--kite-cursor-action);
	--kite-popover-trigger-icon-fill: var(--kite-color-primary);
	--kite-popover-trigger-icon-fill-dark: var(--kite-color-white);
	--kite-popover-trigger-icon-height: var(--kite-size-md);
	--kite-popover-trigger-icon-width: var(--kite-size-md);
	--kite-popover-trigger-label-text-color: var(--kite-color-text);
	--kite-popover-trigger-label-text-color-dark: var(--kite-color-text-dark);
	--kite-popover-trigger-label-margin-right: var(--kite-spacing-element-xs);
	--kite-popover-trigger-active-icon-fill: var(--kite-color-primary);
	--kite-popover-trigger-active-icon-fill-dark: var(--kite-color-white);
	--kite-popover-trigger-focus-icon-fill: var(--kite-color-primary);
	--kite-popover-trigger-focus-icon-fill-dark: var(--kite-color-white);
	--kite-popover-trigger-focus-ring-color: var(--kite-focus-ring-color);
	--kite-popover-trigger-focus-ring-color-dark: var(--kite-focus-ring-color-dark);
	--kite-popover-trigger-focus-ring-offset: var(--kite-focus-ring-offset);
	--kite-popover-trigger-focus-ring-radius: var(--kite-border-radius-circle);
	--kite-popover-trigger-focus-ring-style: var(--kite-focus-ring-style);
	--kite-popover-trigger-focus-ring-width: var(--kite-focus-ring-width);
	--kite-popover-trigger-hover-icon-fill: var(--kite-color-primary);
	--kite-popover-trigger-hover-icon-fill-dark: var(--kite-color-white);
	--kite-popover-background-color: var(--kite-card-background-color);
	--kite-popover-background-color-dark: var(--kite-color-dark-blue-10);
	--kite-popover-arrow-background-color: var(--kite-popover-background-color);
	--kite-popover-arrow-background-color-dark: var(--kite-popover-background-color-dark);
	--kite-popover-arrow-border-color: var(--kite-card-border-color);
	--kite-popover-arrow-border-color-dark: var(--kite-card-border-color-dark);
	--kite-popover-arrow-offset: var(--kite-spacing-element-md);
	--kite-popover-arrow-height: var(--kite-size-xs);
	--kite-popover-arrow-width: var(--kite-size-sm);
	--kite-popover-border-color: var(--kite-card-border-color);
	--kite-popover-border-color-dark: var(--kite-card-border-color-dark);
	--kite-popover-border-radius: var(--kite-card-border-radius);
	--kite-popover-border-style: var(--kite-card-border-style);
	--kite-popover-border-width: var(--kite-card-border-width);
	--kite-popover-dismiss-icon-fill: var(--kite-color-black);
	--kite-popover-dismiss-icon-fill-dark: var(--kite-color-white);
	--kite-popover-dismiss-icon-height: var(--kite-size-md);
	--kite-popover-dismiss-icon-width: var(--kite-size-md);
	--kite-popover-dismiss-icon-margin-bottom: var(--kite-spacing-element-xxs);
	--kite-popover-elevation: var(--kite-elevation-2);
	--kite-popover-elevation-dark: var(--kite-elevation-2-dark);
	--kite-popover-padding-top: var(--kite-spacing-element-md);
	--kite-popover-padding-right: var(--kite-spacing-element-md);
	--kite-popover-padding-bottom: var(--kite-spacing-element-md);
	--kite-popover-padding-left: var(--kite-spacing-element-md);
	--kite-popover-margin: var(--kite-spacing-element-xs);
	--kite-popover-text-align: left;
	--kite-popover-max-width: 18rem;
	--kite-popover-max-height: 37.5rem;
	--kite-popover-z-index: var(--kite-z-index-dropdown);
	--kite-popover-entrance-transition-duration: var(--kite-motion-duration-fast);
	--kite-popover-entrance-transition-curve: var(--kite-motion-curve-deceleration);
	--kite-popover-exit-transition-duration: var(--kite-motion-duration-fast);
	--kite-popover-exit-transition-curve: var(--kite-motion-curve-acceleration);
	--kite-progress-indicator-border-color: var(--kite-color-primary);
	--kite-progress-indicator-border-color-dark: var(--kite-color-white);
	--kite-progress-indicator-border-radius: var(--kite-border-radius-circle);
	--kite-progress-indicator-border-style: solid;
	--kite-progress-indicator-lg-border-width: var(--kite-size-xxs);
	--kite-progress-indicator-lg-height: 5rem;
	--kite-progress-indicator-lg-width: 5rem;
	--kite-progress-indicator-lg-status-text-color: var(--kite-color-primary);
	--kite-progress-indicator-lg-status-text-color-dark: var(--kite-color-text-dark);
	--kite-progress-indicator-lg-status-text-size: var(--kite-typography-lg-body-size);
	--kite-progress-indicator-lg-status-text-weight: var(--kite-font-weight-bold);
	--kite-progress-indicator-lg-label-text-color: var(--kite-color-text);
	--kite-progress-indicator-lg-label-text-color-dark: var(--kite-color-text-dark);
	--kite-progress-indicator-lg-label-text-size: var(--kite-typography-lg-title-6-size);
	--kite-progress-indicator-lg-label-text-weight: var(--kite-font-weight-bold);
	--kite-progress-indicator-lg-sub-label-text-color: var(--kite-color-gray-30);
	--kite-progress-indicator-lg-sub-label-text-color-dark: var(--kite-color-text-dark);
	--kite-progress-indicator-lg-sub-label-text-size: var(--kite-typography-lg-body-size);
	--kite-progress-indicator-lg-sub-label-text-weight: var(--kite-typography-lg-body-weight);
	--kite-progress-indicator-md-border-width: var(--kite-size-xxs);
	--kite-progress-indicator-md-height: var(--kite-size-xxxl);
	--kite-progress-indicator-md-width: var(--kite-size-xxxl);
	--kite-progress-indicator-sm-border-width: var(--kite-border-width-thick);
	--kite-progress-indicator-sm-height: var(--kite-size-xl);
	--kite-progress-indicator-sm-width: var(--kite-size-xl);
	--kite-radio-border-color: var(--kite-color-gray-30);
	--kite-radio-border-color-dark: var(--kite-color-white);
	--kite-radio-border-offset: var(--kite-spacing-element-xxs);
	--kite-radio-border-radius: var(--kite-border-radius-circle);
	--kite-radio-border-style: solid;
	--kite-radio-border-width: var(--kite-border-width-thick);
	--kite-radio-cursor: var(--kite-cursor-action);
	--kite-radio-height: var(--kite-size-lg);
	--kite-radio-width: var(--kite-size-lg);
	--kite-radio-input-margin-right: var(--kite-spacing-element-xs);
	--kite-radio-label-text-color: var(--kite-color-text);
	--kite-radio-label-text-color-dark: var(--kite-color-text-dark);
	--kite-radio-label-text-size: var(--kite-typography-sm-body-size);
	--kite-radio-label-text-weight: var(--kite-typography-sm-body-weight);
	--kite-radio-label-margin-bottom: 0;
	--kite-radio-margin-top: 0;
	--kite-radio-margin-right: 0;
	--kite-radio-margin-bottom: var(--kite-spacing-element-md);
	--kite-radio-margin-left: 0;
	--kite-radio-selected-border-color: var(--kite-color-primary);
	--kite-radio-selected-border-color-dark: var(--kite-color-blue-20);
	--kite-radio-selected-border-width: var(--kite-border-width-thick);
	--kite-radio-selected-border-style: solid;
	--kite-radio-selected-indicator-background-color: var(--kite-color-primary);
	--kite-radio-selected-indicator-background-color-dark: var(--kite-color-blue-20);
	--kite-radio-selected-indicator-border-radius: var(--kite-border-radius-circle);
	--kite-radio-selected-indicator-height: var(--kite-size-sm);
	--kite-radio-selected-indicator-width: var(--kite-size-sm);
	--kite-radio-selected-disabled-border-color: var(--kite-color-disabled);
	--kite-radio-selected-disabled-border-color-dark: var(--kite-color-disabled-dark);
	--kite-radio-selected-disabled-center-background-color: var(--kite-color-disabled);
	--kite-radio-selected-disabled-center-background-color-dark: var(--kite-color-disabled-dark);
	--kite-radio-selected-focus-ring-color: var(--kite-focus-ring-color);
	--kite-radio-selected-focus-ring-color-dark: var(--kite-focus-ring-color-dark);
	--kite-radio-selected-focus-ring-style: var(--kite-focus-ring-style);
	--kite-radio-selected-focus-ring-width: var(--kite-focus-ring-width);
	--kite-radio-disabled-border-color: var(--kite-color-disabled);
	--kite-radio-disabled-border-color-dark: var(--kite-color-disabled-dark);
	--kite-radio-disabled-cursor: var(--kite-cursor-disabled);
	--kite-radio-disabled-label-text-color: var(--kite-color-disabled);
	--kite-radio-disabled-label-text-color-dark: var(--kite-color-disabled-dark);
	--kite-radio-focus-ring-color: var(--kite-focus-ring-color);
	--kite-radio-focus-ring-color-dark: var(--kite-focus-ring-color-dark);
	--kite-radio-focus-ring-offset: 0.375rem;
	--kite-radio-focus-ring-radius: var(--kite-border-radius-circle);
	--kite-radio-focus-ring-style: var(--kite-focus-ring-style);
	--kite-radio-focus-ring-width: var(--kite-focus-ring-width);
	--kite-radio-group-margin-top: var(--kite-spacing-element-xxl);
	--kite-radio-group-margin-bottom: var(--kite-spacing-element-xxl);
	--kite-select-background-color: var(--kite-form-control-input-background-color);
	--kite-select-background-color-dark: var(--kite-form-control-input-background-color-dark);
	--kite-select-border-color: var(--kite-form-control-input-border-color);
	--kite-select-border-color-dark: var(--kite-form-control-input-border-color-dark);
	--kite-select-border-style: var(--kite-form-control-input-border-style);
	--kite-select-border-width: var(--kite-form-control-input-border-width);
	--kite-select-cursor: var(--kite-form-control-input-cursor);
	--kite-select-height: var(--kite-size-touch-target);
	--kite-select-icon-height: var(--kite-size-xl);
	--kite-select-icon-width: var(--kite-size-xl);
	--kite-select-icon-color: var(--kite-color-dark-blue-30);
	--kite-select-icon-color-dark: var(--kite-color-white);
	--kite-select-label-text-color: var(--kite-form-control-label-text-color);
	--kite-select-label-text-color-dark: var(--kite-form-control-label-text-color-dark);
	--kite-select-label-margin-bottom: var(--kite-form-control-label-margin-bottom);
	--kite-select-max-width: var(--kite-form-control-input-max-width);
	--kite-select-padding-top: var(--kite-form-control-input-padding-top);
	--kite-select-padding-right: var(--kite-form-control-input-padding-right);
	--kite-select-padding-bottom: var(--kite-form-control-input-padding-bottom);
	--kite-select-padding-left: var(--kite-form-control-input-padding-right);
	--kite-select-text-color: var(--kite-form-control-input-text-color);
	--kite-select-text-color-dark: var(--kite-form-control-input-text-color-dark);
	--kite-select-focus-ring-color: var(--kite-form-control-input-focus-ring-color);
	--kite-select-focus-ring-color-dark: var(--kite-form-control-input-focus-ring-color-dark);
	--kite-select-focus-ring-style: var(--kite-form-control-input-focus-ring-style);
	--kite-select-focus-ring-width: var(--kite-form-control-input-focus-ring-width);
	--kite-select-disabled-background-color: var(--kite-form-control-input-disabled-background-color);
	--kite-select-disabled-background-color-dark: var(--kite-form-control-input-disabled-background-color-dark);
	--kite-select-disabled-border-color: var(--kite-form-control-input-disabled-border-color);
	--kite-select-disabled-border-color-dark: var(--kite-form-control-input-disabled-border-color-dark);
	--kite-select-disabled-cursor: var(--kite-form-control-input-disabled-cursor);
	--kite-select-disabled-icon-color: var(--kite-color-disabled);
	--kite-select-disabled-icon-color-dark: var(--kite-form-control-input-disabled-text-color-dark);
	--kite-select-disabled-text-color: var(--kite-form-control-input-disabled-text-color);
	--kite-select-disabled-text-color-dark: var(--kite-form-control-input-disabled-text-color-dark);
	--kite-select-error-border-color: var(--kite-form-control-input-error-border-color);
	--kite-select-error-border-color-dark: var(--kite-form-control-input-error-border-color-dark);
	--kite-select-error-text-color: var(--kite-form-control-error-text-color);
	--kite-select-error-text-color-dark: var(--kite-form-control-error-text-color-dark);
	--kite-select-error-text-size: var(--kite-form-control-error-text-size);
	--kite-select-error-text-margin-top: var(--kite-form-control-error-text-margin-top);
	--kite-skip-link-background-color: var(--kite-color-dark-blue-30);
	--kite-skip-link-background-color-dark: var(--kite-color-dark-blue-10);
	--kite-skip-link-border-color: var(--kite-color-blue-20);
	--kite-skip-link-border-color-dark: var(--kite-color-blue-10);
	--kite-skip-link-border-radius: var(--kite-border-radius-md);
	--kite-skip-link-border-style: solid;
	--kite-skip-link-border-width: var(--kite-border-width-thick);
	--kite-skip-link-text-color: var(--kite-color-white);
	--kite-skip-link-text-color-dark: var(--kite-color-text-dark);
	--kite-skip-link-padding-top: var(--kite-spacing-element-md);
	--kite-skip-link-padding-right: var(--kite-spacing-element-md);
	--kite-skip-link-padding-bottom: var(--kite-spacing-element-md);
	--kite-skip-link-padding-left: var(--kite-spacing-element-md);
	--kite-skip-link-position: absolute;
	--kite-skip-link-spacing-stack: var(--kite-spacing-element-md);
	--kite-switch-cursor: var(--kite-cursor-action);
	--kite-switch-indicator-background-color: var(--kite-color-white);
	--kite-switch-indicator-background-color-dark: var(--kite-color-white);
	--kite-switch-indicator-border-radius: var(--kite-border-radius-circle);
	--kite-switch-indicator-height: var(--kite-size-lg);
	--kite-switch-indicator-width: var(--kite-size-lg);
	--kite-switch-label-text-size: 1rem;
	--kite-switch-label-text-color: var(--kite-color-text);
	--kite-switch-label-text-color-dark: var(--kite-color-text-dark);
	--kite-switch-label-margin-right: var(--kite-spacing-element-xl);
	--kite-switch-track-border-radius: var(--kite-size-xxl);
	--kite-switch-track-height: var(--kite-size-xl);
	--kite-switch-track-width: 3rem;
	--kite-switch-track-padding-top: var(--kite-size-2);
	--kite-switch-track-padding-right: var(--kite-size-2);
	--kite-switch-track-padding-bottom: var(--kite-size-2);
	--kite-switch-track-padding-left: var(--kite-size-2);
	--kite-switch-margin-bottom: var(--kite-spacing-element-xl);
	--kite-switch-on-track-background-color: var(--kite-color-primary);
	--kite-switch-on-track-background-color-dark: var(--kite-color-primary);
	--kite-switch-on-focus-ring-color: var(--kite-focus-ring-color);
	--kite-switch-on-focus-ring-color-dark: var(--kite-focus-ring-color-dark);
	--kite-switch-off-track-background-color: var(--kite-color-gray-30);
	--kite-switch-off-track-background-color-dark: var(--kite-color-gray-30);
	--kite-switch-off-focus-ring-color: var(--kite-color-gray-30);
	--kite-switch-off-focus-ring-color-dark: var(--kite-focus-ring-color-dark);
	--kite-switch-transition-duration: var(--kite-motion-duration-fast);
	--kite-switch-transition-curve: var(--kite-motion-curve-default);
	--kite-switch-disabled-cursor: var(--kite-cursor-disabled);
	--kite-switch-disabled-indicator-background-color: var(--kite-color-gray-20);
	--kite-switch-disabled-indicator-background-color-dark: var(--kite-color-gray-25);
	--kite-switch-disabled-label-text-color: var(--kite-color-disabled);
	--kite-switch-disabled-label-text-color-dark: var(--kite-color-disabled-dark);
	--kite-switch-disabled-track-background-color: var(--kite-color-disabled);
	--kite-switch-disabled-track-background-color-dark: var(--kite-color-disabled-dark);
	--kite-switch-focus-ring-radius: var(--kite-size-md);
	--kite-switch-focus-ring-style: var(--kite-focus-ring-style);
	--kite-switch-focus-ring-width: var(--kite-focus-ring-width);
	--kite-switch-group-margin-top: var(--kite-spacing-element-xxl);
	--kite-switch-group-margin-bottom: var(--kite-spacing-element-xxl);
	--kite-tabs-item-border-bottom-color: transparent;
	--kite-tabs-item-border-bottom-color-dark: transparent;
	--kite-tabs-item-border-bottom-style: solid;
	--kite-tabs-item-border-bottom-width: var(--kite-border-width-thick);
	--kite-tabs-item-min-padding-top: var(--kite-spacing-element-md);
	--kite-tabs-item-min-padding-right: var(--kite-spacing-element-lg);
	--kite-tabs-item-min-padding-bottom: var(--kite-spacing-element-md);
	--kite-tabs-item-min-padding-left: var(--kite-spacing-element-lg);
	--kite-tabs-item-min-width: 5.625rem;
	--kite-tabs-item-text-color: var(--kite-color-text);
	--kite-tabs-item-text-color-dark: var(--kite-color-text-dark);
	--kite-tabs-item-text-size: var(--kite-typography-lg-body-size);
	--kite-tabs-item-text-weight: var(--kite-font-weight-regular);
	--kite-tabs-item-cursor: var(--kite-cursor-action);
	--kite-tabs-item-active-text-color: var(--kite-color-primary);
	--kite-tabs-item-active-text-color-dark: var(--kite-color-text-dark);
	--kite-tabs-item-active-text-weight: var(--kite-font-weight-bold);
	--kite-tabs-item-active-border-bottom-color: var(--kite-color-primary);
	--kite-tabs-item-active-border-bottom-color-dark: var(--kite-color-primary);
	--kite-tabs-item-hover-border-bottom-color: var(--kite-color-gray-30);
	--kite-tabs-item-hover-border-bottom-color-dark: var(--kite-color-white);
	--kite-tabs-item-disabled-cursor: var(--kite-cursor-disabled);
	--kite-tabs-item-disabled-text-color: var(--kite-color-disabled);
	--kite-tabs-item-disabled-text-color-dark: var(--kite-color-disabled-dark);
	--kite-tabs-item-focus-ring-color: var(--kite-focus-ring-color);
	--kite-tabs-item-focus-ring-color-dark: var(--kite-focus-ring-color-dark);
	--kite-tabs-item-focus-ring-style: var(--kite-focus-ring-style);
	--kite-tabs-item-focus-ring-width: var(--kite-focus-ring-width);
	--kite-tabs-panel-padding-top: var(--kite-spacing-element-md);
	--kite-text-area-min-height: 8.125rem;
	--kite-text-area-max-width: var(--kite-form-control-input-max-width);
	--kite-text-area-background-color: var(--kite-form-control-input-background-color);
	--kite-text-area-background-color-dark: var(--kite-form-control-input-background-color-dark);
	--kite-text-area-border-color: var(--kite-form-control-input-border-color);
	--kite-text-area-border-color-dark: var(--kite-form-control-input-border-color-dark);
	--kite-text-area-border-style: var(--kite-form-control-input-border-style);
	--kite-text-area-border-width: var(--kite-form-control-input-border-width);
	--kite-text-area-cursor: var(--kite-form-control-input-cursor);
	--kite-text-area-hint-text-color: var(--kite-color-gray-30);
	--kite-text-area-hint-text-color-dark: var(--kite-color-gray-30);
	--kite-text-area-label-text-color: var(--kite-form-control-label-text-color);
	--kite-text-area-label-text-color-dark: var(--kite-form-control-label-text-color-dark);
	--kite-text-area-label-margin-bottom: var(--kite-form-control-label-margin-bottom);
	--kite-text-area-padding-top: var(--kite-form-control-input-padding-top);
	--kite-text-area-padding-right: var(--kite-form-control-input-padding-right);
	--kite-text-area-padding-bottom: var(--kite-form-control-input-padding-bottom);
	--kite-text-area-padding-left: var(--kite-form-control-input-padding-right);
	--kite-text-area-text-color: var(--kite-form-control-input-text-color);
	--kite-text-area-text-color-dark: var(--kite-form-control-input-text-color-dark);
	--kite-text-area-disabled-background-color: var(--kite-form-control-input-disabled-background-color);
	--kite-text-area-disabled-background-color-dark: var(--kite-form-control-input-disabled-background-color-dark);
	--kite-text-area-disabled-border-color: var(--kite-form-control-input-disabled-border-color);
	--kite-text-area-disabled-border-color-dark: var(--kite-form-control-input-disabled-border-color-dark);
	--kite-text-area-disabled-cursor: var(--kite-form-control-input-disabled-cursor);
	--kite-text-area-disabled-text-color: var(--kite-form-control-input-disabled-text-color);
	--kite-text-area-disabled-text-color-dark: var(--kite-form-control-input-disabled-text-color-dark);
	--kite-text-area-error-border-color: var(--kite-form-control-input-error-border-color);
	--kite-text-area-error-border-color-dark: var(--kite-form-control-input-error-border-color-dark);
	--kite-text-area-error-text-color: var(--kite-form-control-error-text-color);
	--kite-text-area-error-text-color-dark: var(--kite-form-control-error-text-color-dark);
	--kite-text-area-error-text-size: var(--kite-form-control-error-text-size);
	--kite-text-area-error-text-margin-top: var(--kite-form-control-error-text-margin-top);
	--kite-text-area-focus-ring-color: var(--kite-form-control-input-focus-ring-color);
	--kite-text-area-focus-ring-color-dark: var(--kite-form-control-input-focus-ring-color-dark);
	--kite-text-area-focus-ring-style: var(--kite-form-control-input-focus-ring-style);
	--kite-text-area-focus-ring-width: var(--kite-form-control-input-focus-ring-width);
	--kite-text-input-background-color: var(--kite-form-control-input-background-color);
	--kite-text-input-background-color-dark: var(--kite-form-control-input-background-color-dark);
	--kite-text-input-border-color: var(--kite-form-control-input-border-color);
	--kite-text-input-border-color-dark: var(--kite-form-control-input-border-color-dark);
	--kite-text-input-border-style: var(--kite-form-control-input-border-style);
	--kite-text-input-border-width: var(--kite-form-control-input-border-width);
	--kite-text-input-cursor: var(--kite-form-control-input-cursor);
	--kite-text-input-height: var(--kite-size-touch-target);
	--kite-text-input-hint-text-color: var(--kite-color-gray-30);
	--kite-text-input-hint-text-color-dark: var(--kite-color-gray-30);
	--kite-text-input-label-text-color: var(--kite-form-control-label-text-color);
	--kite-text-input-label-text-color-dark: var(--kite-form-control-label-text-color-dark);
	--kite-text-input-label-margin-bottom: var(--kite-form-control-label-margin-bottom);
	--kite-text-input-max-width: var(--kite-form-control-input-max-width);
	--kite-text-input-padding-top: var(--kite-form-control-input-padding-top);
	--kite-text-input-padding-right: var(--kite-form-control-input-padding-right);
	--kite-text-input-padding-bottom: var(--kite-form-control-input-padding-bottom);
	--kite-text-input-padding-left: var(--kite-form-control-input-padding-right);
	--kite-text-input-text-color: var(--kite-form-control-input-text-color);
	--kite-text-input-text-color-dark: var(--kite-form-control-input-text-color-dark);
	--kite-text-input-disabled-background-color: var(--kite-form-control-input-disabled-background-color);
	--kite-text-input-disabled-background-color-dark: var(--kite-form-control-input-disabled-background-color-dark);
	--kite-text-input-disabled-border-color: var(--kite-form-control-input-disabled-border-color);
	--kite-text-input-disabled-border-color-dark: var(--kite-form-control-input-disabled-border-color-dark);
	--kite-text-input-disabled-cursor: var(--kite-form-control-input-disabled-cursor);
	--kite-text-input-disabled-text-color: var(--kite-form-control-input-disabled-text-color);
	--kite-text-input-disabled-text-color-dark: var(--kite-form-control-input-disabled-text-color-dark);
	--kite-text-input-error-border-color: var(--kite-form-control-input-error-border-color);
	--kite-text-input-error-border-color-dark: var(--kite-form-control-input-error-border-color-dark);
	--kite-text-input-error-text-color: var(--kite-form-control-error-text-color);
	--kite-text-input-error-text-color-dark: var(--kite-form-control-error-text-color-dark);
	--kite-text-input-error-text-size: var(--kite-form-control-error-text-size);
	--kite-text-input-error-text-margin-top: var(--kite-form-control-error-text-margin-top);
	--kite-text-input-focus-ring-color: var(--kite-form-control-input-focus-ring-color);
	--kite-text-input-focus-ring-color-dark: var(--kite-form-control-input-focus-ring-color-dark);
	--kite-text-input-focus-ring-style: var(--kite-form-control-input-focus-ring-style);
	--kite-text-input-focus-ring-width: var(--kite-form-control-input-focus-ring-width);
	--kite-text-input-secure-confirm-icon-color: var(--kite-color-success);
	--kite-text-input-secure-confirm-icon-color-dark: var(--kite-color-success);
	--kite-text-input-secure-confirm-icon-height: var(--kite-size-xl);
	--kite-text-input-secure-confirm-icon-width: var(--kite-size-xl);
	--kite-text-input-secure-confirm-icon-margin-right: var(--kite-spacing-element-xs);
	--kite-text-input-secure-show-icon-color: var(--kite-color-primary);
	--kite-text-input-secure-show-icon-color-dark: var(--kite-color-primary);
	--kite-text-input-secure-show-icon-height: var(--kite-size-xl);
	--kite-text-input-secure-show-icon-width: var(--kite-size-xl);
	--kite-text-input-secure-show-icon-focus-ring-color: var(--kite-focus-ring-color);
	--kite-text-input-secure-show-icon-focus-ring-color-dark: var(--kite-color-blue-30);
	--kite-text-input-secure-show-icon-focus-ring-style: var(--kite-focus-ring-style);
	--kite-text-input-secure-show-icon-focus-ring-width: var(--kite-focus-ring-width);
	--kite-text-input-secure-hide-icon-color: var(--kite-color-primary);
	--kite-text-input-secure-hide-icon-color-dark: var(--kite-color-primary);
	--kite-text-input-secure-hide-icon-height: var(--kite-size-xl);
	--kite-text-input-secure-hide-icon-width: var(--kite-size-xl);
	--kite-toast-background-color: var(--kite-color-dark-blue-30);
	--kite-toast-background-color-dark: var(--kite-color-dark-blue-10);
	--kite-toast-border-radius: var(--kite-border-radius-md);
	--kite-toast-elevation: var(--kite-elevation-3);
	--kite-toast-height: var(--kite-size-xxxl);
	--kite-toast-icon-height: var(--kite-size-md);
	--kite-toast-icon-margin-right: var(--kite-spacing-element-sm);
	--kite-toast-icon-width: var(--kite-size-md);
	--kite-toast-margin-top: var(--kite-spacing-element-xl);
	--kite-toast-margin-bottom: var(--kite-spacing-element-xl);
	--kite-toast-max-width: 20rem;
	--kite-toast-padding-top: var(--kite-spacing-element-xs);
	--kite-toast-padding-right: var(--kite-spacing-element-sm);
	--kite-toast-padding-bottom: var(--kite-spacing-element-xs);
	--kite-toast-padding-left: var(--kite-spacing-element-sm);
	--kite-toast-text-color: var(--kite-color-white);
	--kite-toast-text-color-dark: var(--kite-color-text-dark);
	--kite-toast-text-size: var(--kite-typography-lg-body-size);
	--kite-toast-transition-curve: cubic-bezier(0.25, 0.8, 0.5, 1);
	--kite-toast-transition-duration: var(--kite-motion-duration-fast);
	--kite-toast-z-index: var(--kite-z-index-notification);
	--kite-toast-line-height: var(--kite-typography-sm-body-line-height);
	--kite-tooltip-background-color: var(--kite-color-dark-blue-30);
	--kite-tooltip-background-color-dark: var(--kite-color-dark-blue-10);
	--kite-tooltip-border-radius: var(--kite-card-border-radius);
	--kite-tooltip-padding-top: var(--kite-spacing-element-xxs);
	--kite-tooltip-padding-right: var(--kite-spacing-element-md);
	--kite-tooltip-padding-bottom: var(--kite-spacing-element-xxs);
	--kite-tooltip-padding-left: var(--kite-spacing-element-md);
	--kite-tooltip-margin: var(--kite-spacing-element-xs);
	--kite-tooltip-text-color: var(--kite-color-white);
	--kite-tooltip-text-color-dark: var(--kite-color-text-dark);
	--kite-tooltip-max-width: 18rem;
	--kite-tooltip-max-height: 4.25rem;
	--kite-tooltip-z-index: var(--kite-z-index-dropdown);
	--kite-tooltip-arrow-background-color: var(--kite-tooltip-background-color);
	--kite-tooltip-arrow-background-color-dark: var(--kite-tooltip-background-color-dark);
	--kite-tooltip-arrow-directional-offset: var(--kite-spacing-element-xs);
	--kite-tooltip-arrow-height: var(--kite-size-xxs);
	--kite-tooltip-arrow-width: var(--kite-size-xs);
	--kite-tooltip-entrance-transition-duration: var(--kite-motion-duration-fast);
	--kite-tooltip-entrance-transition-curve: var(--kite-motion-curve-deceleration);
	--kite-tooltip-exit-transition-duration: var(--kite-motion-duration-fast);
	--kite-tooltip-exit-transition-curve: var(--kite-motion-curve-acceleration);
}
