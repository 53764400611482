@import '../../global/focus/mixins';
@import '../../global/theme/mixins';

@mixin default($selector) {
	#{$selector} {
		--text-decoration: var(--kite-link-text-decoration);
		--icon-size: var(--kite-link-standalone-icon-height);
		color: var(--color);
		font-weight: var(--kite-link-text-weight);
		text-decoration: var(--text-decoration);
	}

	@include _kite-theme-component('light') {
		#{$selector} {
			--color: var(--kite-link-text-color);
			--focus-ring-color: var(--kite-link-focus-ring-color);
			--focus-text-color: var(--kite-link-focus-text-color);
			--hover-text-color: var(--kite-link-hover-text-color);
			--active-text-color: var(--kite-link-active-text-color);
			--visited-text-color: var(--kite-link-visited-text-color);
		}
	}

	@include _kite-theme-component('dark') {
		#{$selector} {
			--color: var(--kite-link-text-color-dark);
			--focus-ring-color: var(--kite-link-focus-ring-color-dark);
			--focus-text-color: var(--kite-link-focus-text-color-dark);
			--hover-text-color: var(--kite-link-hover-text-color-dark);
			--active-text-color: var(--kite-link-active-text-color-dark);
			--visited-text-color: var(--kite-link-visited-text-color-dark);
		}
	}

	#{$selector} {
		@include kite-focus-visible() {
			@include kite-focus-outline();
			color: var(--focus-text-color);
			outline-color: var(--focus-ring-color);
		}

		&--hover,
		&:hover {
			color: var(--hover-text-color);
		}

		&--active,
		&:active {
			color: var(--active-text-color);
		}

		&--visited,
		&:visited {
			color: var(--visited-text-color);
		}
	}
}
