@import '../grid/vars';
@import '../breakpoint';

// Display utilities
// ---------------------------------------------------------
// Utilities for common `display` values
// @access private
// @group @kite/utilities@include _kite-display();
@mixin _kite-display {
	@each $breakpoint in map-keys($kite-grid-breakpoints) {
		@include kite-breakpoint-up($breakpoint, $kite-grid-breakpoints) {
			$largest: _kite-breakpoint-is-largest($breakpoint, $kite-grid-breakpoints);
			$smallest: _kite-breakpoint-is-smallest($breakpoint, $kite-grid-breakpoints);

			$infix: '';
			@if not $largest and not $smallest {
				$infix: '-gt-' + $breakpoint;
			}

			@if not $largest {
				.kite-display-none#{$infix} {
					display: none;
				}
				.kite-display-inline#{$infix} {
					display: inline;
				}
				.kite-display-inline-block#{$infix} {
					display: inline-block;
				}
				.kite-display-block#{$infix} {
					display: block;
				}
				.kite-display-table#{$infix} {
					display: table;
				}
				.kite-display-table-cell#{$infix} {
					display: table-cell;
				}
				.kite-display-flex#{$infix} {
					display: flex;
				}
				.kite-display-inline-flex#{$infix} {
					display: inline-flex;
				}
			}
		}
	}
}
