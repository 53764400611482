@import 'breakpoint';
@import 'deprecate';
@import 'helpers';
@import 'vars';

// Layout containers
// ---------------------------

// Helper classes to enable making full-width
// sections, while locking "content" to a max-width.

// Example:
// <div class="kite-container">
//   <section>Normal section</section>
//   <section class="kite-container--full-overflow">A full bleed section</section>
//   <section>Normal section</section>
// </div>
// <div class="kite-container--fluid">
//   <section>A section that will go the full width of the viewport</section>
// </div>

.#{$prefix}-container,
.#{$prefix}-container--fluid,
.#{$prefix}-container--full-overflow {
	margin-right: auto;
	margin-left: auto;
	padding: 0 var(--kite-grid-xs-margins);
	width: 100%;

	@include kite-breakpoint-up('lg') {
		padding: 0 var(--kite-grid-lg-margins);
	}
}

.#{$prefix}-container {
	max-width: kite-breakpoint-min('xxl');
}

.#{$prefix}-container--full-overflow {
	margin-right: -50vw;
	margin-left: -50vw;
	position: relative;
	right: 50%;
	left: 50%;
	width: 100vw;
}

// If the global variable exists, and it is exactly `true`
@if (not _undefined('kite-css--enable-legacy-layout')) and ($kite-css--enable-legacy-layout == true) {
	@include deprecate('See the Migration Guide for details on using the `exclude-legacy-layout` config option to remove legacy `@kite/web` layout styles.') {
		.kite-container,
		.kite-container-full .kite-content {
			margin-right: auto;
			margin-left: auto;
			max-width: kite-breakpoint-min('xxl');
			min-width: 320px;
			padding: 0 var(--kite-grid-xs-margins);

			@include kite-breakpoint-up('lg') {
				padding: 0 var(--kite-grid-lg-margins);
			}
		}

		.kite-container-full {
			max-width: none;
			padding: 0;
		}
	}
}
