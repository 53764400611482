
@import 'config';
@import 'vars';
@import 'reset';
@import 'typography/vars';

/// Body styles
/// @access private
/// @group @kite/body
@mixin kite-body(
	$body: $kite-font-family,
	$mono: kite-font-family('mono')
) {
	body,
	.kite-body {
		// @include kite-reset();
		@include kite-type-style('body');
		background-color: var(--kite-color-background);
		color: var(--kite-color-text);
		font-family: $kite-font-family;
		// text-rendering: optimizeLegibility; // TODO(dura): Test this setting
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;

		&:focus {
			outline: none;
		}
	}

	body {
		// Inherited from `kite-type-style(body)`, reset to avoid body scrolling issues
		margin: 0;
	}
}

// If the global variable does not exist, or it is exactly `false`
@if _undefined('kite-css--exclude-body') or ($kite-css--exclude-body == false) {
	@include kite-body();
}
