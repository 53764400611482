// TODO(dura): These are overrides because they don't exist or are wrong in `@kite/tokens`
// *Temporary* overrides should be deleted when tokens have been updated
@import '../typography/mixins';

// Temporary
/* Temporary shameful overrides */
:root, .kite-theme {

	// TODO(dura): Spectrum Sans tokens
	// Small
	--kite-typography-sans-sm-display-size: var(--kite-typography-sm-display-size);
	--kite-typography-sans-sm-display-weight: #{kite-font-weight('extrabold', $kite-font-weights--spectrum-sans)};
	--kite-typography-sans-sm-display-tracking: var(--kite-typography-sm-display-tracking);
	--kite-typography-sans-sm-display-line-height: var(--kite-typography-sm-display-line-height);
	--kite-typography-sans-sm-display-margin-bottom: var(--kite-typography-sm-display-margin-bottom);

	--kite-typography-sans-sm-title-1-size: var(--kite-typography-sm-title-1-size);
	--kite-typography-sans-sm-title-1-weight: #{kite-font-weight('extrabold', $kite-font-weights--spectrum-sans)};
	--kite-typography-sans-sm-title-1-tracking: var(--kite-typography-sm-title-1-tracking);
	--kite-typography-sans-sm-title-1-line-height: var(--kite-typography-sm-title-1-line-height);
	--kite-typography-sans-sm-title-1-margin-bottom: var(--kite-typography-sm-title-1-margin-bottom);

	--kite-typography-sans-sm-title-2-size: var(--kite-typography-sm-title-2-size);
	--kite-typography-sans-sm-title-2-weight: #{kite-font-weight('bold', $kite-font-weights--spectrum-sans)};
	--kite-typography-sans-sm-title-2-tracking: var(--kite-typography-sm-title-2-tracking);
	--kite-typography-sans-sm-title-2-line-height: var(--kite-typography-sm-title-2-line-height);
	--kite-typography-sans-sm-title-2-margin-bottom: var(--kite-typography-sm-title-2-margin-bottom);

	--kite-typography-sans-sm-title-3-size: var(--kite-typography-sm-title-3-size);
	--kite-typography-sans-sm-title-3-weight: #{kite-font-weight('bold', $kite-font-weights--spectrum-sans)};;
	--kite-typography-sans-sm-title-3-tracking: var(--kite-typography-sm-title-3-tracking);
	--kite-typography-sans-sm-title-3-line-height: var(--kite-typography-sm-title-3-line-height);
	--kite-typography-sans-sm-title-3-margin-bottom: var(--kite-typography-sm-title-3-margin-bottom);

	--kite-typography-sans-sm-title-4-size: var(--kite-typography-sm-title-4-size);
	--kite-typography-sans-sm-title-4-weight: #{kite-font-weight('medium', $kite-font-weights--spectrum-sans)};
	--kite-typography-sans-sm-title-4-tracking: var(--kite-typography-sm-title-4-tracking);
	--kite-typography-sans-sm-title-4-line-height: var(--kite-typography-sm-title-4-line-height);
	--kite-typography-sans-sm-title-4-margin-bottom: var(--kite-typography-sm-title-4-margin-bottom);

	--kite-typography-sans-sm-title-5-size: var(--kite-typography-sm-title-5-size);
	--kite-typography-sans-sm-title-5-weight: #{kite-font-weight('medium', $kite-font-weights--spectrum-sans)};
	--kite-typography-sans-sm-title-5-tracking: var(--kite-typography-sm-title-5-tracking);
	--kite-typography-sans-sm-title-5-line-height: var(--kite-typography-sm-title-5-line-height);
	--kite-typography-sans-sm-title-5-margin-bottom: var(--kite-typography-sm-title-5-margin-bottom);

	--kite-typography-sans-sm-title-6-size: var(--kite-typography-sm-title-6-size);
	--kite-typography-sans-sm-title-6-weight: #{kite-font-weight('medium', $kite-font-weights--spectrum-sans)};
	--kite-typography-sans-sm-title-6-tracking: var(--kite-typography-sm-title-6-tracking);
	--kite-typography-sans-sm-title-6-line-height: var(--kite-typography-sm-title-6-line-height);
	--kite-typography-sans-sm-title-6-margin-bottom: var(--kite-typography-sm-title-6-margin-bottom);

	--kite-typography-sans-sm-body-display-size: var(--kite-typography-sm-body-display-size);
	--kite-typography-sans-sm-body-display-weight: #{kite-font-weight('regular', $kite-font-weights--spectrum-sans)};
	--kite-typography-sans-sm-body-display-tracking: var(--kite-typography-sm-body-display-tracking);
	--kite-typography-sans-sm-body-display-line-height: var(--kite-typography-sm-body-display-line-height);
	--kite-typography-sans-sm-body-display-margin-bottom: var(--kite-typography-sm-body-display-margin-bottom);

	--kite-typography-sans-sm-body-size: var(--kite-typography-sm-body-size);
	--kite-typography-sans-sm-body-weight: #{kite-font-weight('regular', $kite-font-weights--spectrum-sans)};
	--kite-typography-sans-sm-body-tracking: var(--kite-typography-sm-body-tracking);
	--kite-typography-sans-sm-body-line-height: var(--kite-typography-sm-body-line-height);
	--kite-typography-sans-sm-body-margin-bottom: var(--kite-typography-sm-body-margin-bottom);

	--kite-typography-sans-sm-eyebrow-size: var(--kite-typography-sm-eyebrow-size);
	--kite-typography-sans-sm-eyebrow-weight: #{kite-font-weight('bold', $kite-font-weights--spectrum-sans)};
	--kite-typography-sans-sm-eyebrow-tracking: var(--kite-typography-sm-eyebrow-tracking);
	--kite-typography-sans-sm-eyebrow-line-height: var(--kite-typography-sm-eyebrow-line-height);
	--kite-typography-sans-sm-eyebrow-margin-bottom: var(--kite-typography-sm-eyebrow-margin-bottom);
	--kite-typography-sans-sm-eyebrow-style: var(--kite-typography-sm-eyebrow-style);

	--kite-typography-sans-sm-caption-size: var(--kite-typography-sm-caption-size);
	--kite-typography-sans-sm-caption-weight: #{kite-font-weight('regular', $kite-font-weights--spectrum-sans)};
	--kite-typography-sans-sm-caption-tracking: var(--kite-typography-sm-caption-tracking);
	--kite-typography-sans-sm-caption-line-height: var(--kite-typography-sm-caption-line-height);
	--kite-typography-sans-sm-caption-margin-bottom: var(--kite-typography-sm-caption-margin-bottom);

	// Large
	--kite-typography-sans-lg-display-size: var(--kite-typography-lg-display-size);
	--kite-typography-sans-lg-display-weight: #{kite-font-weight('extrabold', $kite-font-weights--spectrum-sans)};
	--kite-typography-sans-lg-display-tracking: var(--kite-typography-lg-display-tracking);
	--kite-typography-sans-lg-display-line-height: var(--kite-typography-lg-display-line-height);
	--kite-typography-sans-lg-display-margin-bottom: var(--kite-typography-lg-display-margin-bottom);

	--kite-typography-sans-lg-title-1-size: var(--kite-typography-lg-title-1-size);
	--kite-typography-sans-lg-title-1-weight: #{kite-font-weight('extrabold', $kite-font-weights--spectrum-sans)};
	--kite-typography-sans-lg-title-1-tracking: var(--kite-typography-lg-title-1-tracking);
	--kite-typography-sans-lg-title-1-line-height: var(--kite-typography-lg-title-1-line-height);
	--kite-typography-sans-lg-title-1-margin-bottom: var(--kite-typography-lg-title-1-margin-bottom);

	--kite-typography-sans-lg-title-2-size: var(--kite-typography-lg-title-2-size);
	--kite-typography-sans-lg-title-2-weight: #{kite-font-weight('bold', $kite-font-weights--spectrum-sans)};
	--kite-typography-sans-lg-title-2-tracking: var(--kite-typography-lg-title-2-tracking);
	--kite-typography-sans-lg-title-2-line-height: var(--kite-typography-lg-title-2-line-height);
	--kite-typography-sans-lg-title-2-margin-bottom: var(--kite-typography-lg-title-2-margin-bottom);

	--kite-typography-sans-lg-title-3-size: var(--kite-typography-lg-title-3-size);
	--kite-typography-sans-lg-title-3-weight: #{kite-font-weight('bold', $kite-font-weights--spectrum-sans)};
	--kite-typography-sans-lg-title-3-tracking: var(--kite-typography-lg-title-3-tracking);
	--kite-typography-sans-lg-title-3-line-height: var(--kite-typography-lg-title-3-line-height);
	--kite-typography-sans-lg-title-3-margin-bottom: var(--kite-typography-lg-title-3-margin-bottom);

	--kite-typography-sans-lg-title-4-size: var(--kite-typography-lg-title-4-size);
	--kite-typography-sans-lg-title-4-weight: #{kite-font-weight('medium', $kite-font-weights--spectrum-sans)};
	--kite-typography-sans-lg-title-4-tracking: var(--kite-typography-lg-title-4-tracking);
	--kite-typography-sans-lg-title-4-line-height: var(--kite-typography-lg-title-4-line-height);
	--kite-typography-sans-lg-title-4-margin-bottom: var(--kite-typography-lg-title-4-margin-bottom);

	--kite-typography-sans-lg-title-5-size: var(--kite-typography-lg-title-5-size);
	--kite-typography-sans-lg-title-5-weight: #{kite-font-weight('medium', $kite-font-weights--spectrum-sans)};
	--kite-typography-sans-lg-title-5-tracking: var(--kite-typography-lg-title-5-tracking);
	--kite-typography-sans-lg-title-5-line-height: var(--kite-typography-lg-title-5-line-height);
	--kite-typography-sans-lg-title-5-margin-bottom: var(--kite-typography-lg-title-5-margin-bottom);

	--kite-typography-sans-lg-title-6-size: var(--kite-typography-lg-title-6-size);
	--kite-typography-sans-lg-title-6-weight: #{kite-font-weight('medium', $kite-font-weights--spectrum-sans)};
	--kite-typography-sans-lg-title-6-tracking: var(--kite-typography-lg-title-6-tracking);
	--kite-typography-sans-lg-title-6-line-height: var(--kite-typography-lg-title-6-line-height);
	--kite-typography-sans-lg-title-6-margin-bottom: var(--kite-typography-lg-title-6-margin-bottom);

	--kite-typography-sans-lg-body-display-size: var(--kite-typography-lg-body-display-size);
	--kite-typography-sans-lg-body-display-weight: #{kite-font-weight('regular', $kite-font-weights--spectrum-sans)};
	--kite-typography-sans-lg-body-display-tracking: var(--kite-typography-lg-body-display-tracking);
	--kite-typography-sans-lg-body-display-line-height: var(--kite-typography-lg-body-display-line-height);
	--kite-typography-sans-lg-body-display-margin-bottom: var(--kite-typography-lg-body-display-margin-bottom);

	--kite-typography-sans-lg-body-size: var(--kite-typography-lg-body-size);
	--kite-typography-sans-lg-body-weight: #{kite-font-weight('regular', $kite-font-weights--spectrum-sans)};
	--kite-typography-sans-lg-body-tracking: var(--kite-typography-lg-body-tracking);
	--kite-typography-sans-lg-body-line-height: var(--kite-typography-lg-body-line-height);
	--kite-typography-sans-lg-body-margin-bottom: var(--kite-typography-lg-body-margin-bottom);

	--kite-typography-sans-lg-eyebrow-size: var(--kite-typography-lg-eyebrow-size);
	--kite-typography-sans-lg-eyebrow-weight: #{kite-font-weight('bold', $kite-font-weights--spectrum-sans)};
	--kite-typography-sans-lg-eyebrow-tracking: var(--kite-typography-lg-eyebrow-tracking);
	--kite-typography-sans-lg-eyebrow-line-height: var(--kite-typography-lg-eyebrow-line-height);
	--kite-typography-sans-lg-eyebrow-margin-bottom: var(--kite-typography-lg-eyebrow-margin-bottom);
	--kite-typography-sans-lg-eyebrow-style: var(--kite-typography-lg-eyebrow-style);

	--kite-typography-sans-lg-caption-size: var(--kite-typography-lg-caption-size);
	--kite-typography-sans-lg-caption-weight:  #{kite-font-weight('regular', $kite-font-weights--spectrum-sans)};
	--kite-typography-sans-lg-caption-tracking: var(--kite-typography-lg-caption-tracking);
	--kite-typography-sans-lg-caption-line-height: var(--kite-typography-lg-caption-line-height);
	--kite-typography-sans-lg-caption-margin-bottom: var(--kite-typography-lg-caption-margin-bottom);

	// Not a shame, but moving to use next version of tokens early
	--kite-font-family-sans-serif: 'Spectrum Sans', -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
	--kite-font-family-rutledge: 'Rutledge', -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;

	// Dropdown
	// TODO(dura): Dropdown tokens don't exist yet, using a minimal set as a starting point to
	// help with coding up the Pagination Component, which uses Dropdowns.
	// Some (most?) of the styles for the Pagination specific Dropdowns will come from
	// the default Dropdown component, (eg, `--kite-dropdown-*`).
	// Once the default Dropdown is created, we can override with
	// `--kite-pagination-dropdown-*` tokens when appropriate.
	// Padding for dropdown/select button/trigger
	--kite-pagination-dropdown-padding-top: 12px;
	--kite-pagination-dropdown-padding-right: 16px;
	--kite-pagination-dropdown-padding-bottom: 12px;
	--kite-pagination-dropdown-padding-left: 16px;
	--kite-pagination-dropdown-icon-width: var(--kite-size-sm); // Icon width
	--kite-pagination-dropdown-icon-height: var(--kite-size-sm); // Icon height
	--kite-pagination-dropdown-min-width: 3rem; // Min-width of the dropdown (and list)
	--kite-pagination-dropdown-item-icon-width: 12px;
	--kite-pagination-dropdown-item-icon-fill: #0073d1;
	--kite-pagination-dropdown-item-icon-margin-right: 4px;
	// The dropdown trigger `<button>` needs these because UA stylesheet applies more specific `<button>` styles
	--kite-pagination-dropdown-hover-background-color: #d8dde6;
	--kite-pagination-dropdown-hover-background-color-dark: var(--kite-color-dark-blue-10);
	--kite-pagination-dropdown-text-color: #000;
	--kite-pagination-dropdown-text-color-dark: #fff;

	// missing tokens due to design difference between inline dropdown and pagination dropdown
	--kite-pagination-dropdown-selection-text-weight: bold;
	--kite-pagination-dropdown-border-radius: 4px;

	// Pagination

	// TODO(dura): White and dark blue 30
	// --kite-pagination-controls-background-color: transparent;
	--kite-pagination-controls-background-color: var(--kite-color-white);
	--kite-pagination-controls-background-color-dark: var(--kite-color-dark-blue-30);

	// TODO(dura): Width needs to increase to keep aspect ratio
	--kite-pagination-controls-button-width: 4rem;

	// TODO(dura): Margin tokens for the page controls div
	--kite-pagination-controls-margin-top: 0;
	--kite-pagination-controls-margin-right: 20px;
	--kite-pagination-controls-margin-bottom: 8px; // stacking on mobile
	--kite-pagination-controls-margin-bottom-lg: 0; // What is our breakpoint naming convention for tokens?
	--kite-pagination-controls-margin-left: 20px;

	// TODO(dura): Padding because we want tighter left/right spacing for pagination drops
	--kite-pagination-dropdown-padding: 4px;

	// TODO(dura): These are the margins for the labels, changing the order reduces the amount of tokens needed, I think
	// --kite-pagination-display-filter-left-label-margin-right: var(--kite-spacing-element-xs);
	// --kite-pagination-display-filter-select-margin-right: var(--kite-spacing-element-xs);
	// --kite-pagination-display-filter-margin-right: var(--kite-spacing-element-lg);
	--kite-pagination-count-margin-right: 16px;
	--kite-pagination-page-size-label-margin-right: 4px;

	// TODO(dura): Item count label
	// --kite-pagination-display-filter-right-label-text-color: var(--kite-color-gray-30);
	// --kite-pagination-display-filter-right-label-text-color-dark: var(--kite-color-gray-25);
	--kite-pagination-count-text-color: var(--kite-color-gray-30);
	--kite-pagination-count-text-color-dark: var(--kite-color-gray-25);

	// TODO(dura): Spacing for Pagination Dropdowns
	--kite-pagination-dropdown-margin-top: 0;
	--kite-pagination-dropdown-margin-right: 0.25rem;
	--kite-pagination-dropdown-margin-bottom: 0;
	--kite-pagination-dropdown-margin-left: 0.25rem;

	--kite-pagination-input-padding-top: 0.625rem;
	--kite-pagination-input-padding-left: 0.625rem;
	--kite-pagination-input-padding-right: 0.625rem;
	--kite-pagination-input-padding-bottom: 0.625rem;
	--kite-pagination-input-width: 2.5rem;
	--kite-pagination-input-margin-left: 0.5rem;
	--kite-pagination-input-margin-right: 0.5rem;

	--kite-pagination-label-padding-top: 0.625rem;
	--kite-pagination-label-padding-left: 0.625rem;
	--kite-pagination-label-padding-right: 0.625rem;
	--kite-pagination-label-padding-bottom: 0.625rem;

	// TODO(dura): IMO, as a matter of best Design practice, there should never
	// be a styling difference between the two Dropdowns (within the Pagination), just use one set of tokens for both.
	// All these can be removed from Pagination tokens, and just use the default Dropdown tokens instead.
	// --kite-pagination-select-background-color: transparent;
	// --kite-pagination-select-background-color-dark: transparent;
	// --kite-pagination-select-border-radius: var(--kite-border-radius-md);
	// --kite-pagination-select-cursor: var(--kite-cursor-action);
	// --kite-pagination-select-height: var(--kite-size-touch-target);
	// --kite-pagination-select-min-width: 3rem;
	// --kite-pagination-select-text-color: var(--kite-color-text);
	// --kite-pagination-select-text-color-dark: var(--kite-color-text-dark);
	// --kite-pagination-select-text-weight: var(--kite-font-weight-bold);
	// --kite-pagination-select-icon-fill: var(--kite-color-dark-blue-30);
	// --kite-pagination-select-icon-fill-dark: var(--kite-color-white);
	// --kite-pagination-select-icon-height: var(--kite-size-md);
	// --kite-pagination-select-icon-width: var(--kite-size-md);
	// --kite-pagination-select-hover-background-color: var(--kite-color-gray-20);
	// --kite-pagination-select-hover-background-color-dark: var(--kite-color-blue-10);
	// --kite-pagination-select-focus-ring-color: var(--kite-focus-ring-color);
	// --kite-pagination-select-focus-ring-color-dark: var(--kite-focus-ring-color-dark);
	// --kite-pagination-select-focus-ring-style: var(--kite-focus-ring-style);
	// --kite-pagination-select-focus-ring-width: var(--kite-focus-ring-width);
	// --kite-pagination-display-filter-left-label-text-color: var(--kite-color-text);
	// --kite-pagination-display-filter-left-label-text-color-dark: var(--kite-color-text-dark);
	// --kite-pagination-display-filter-left-label-margin-right: var(--kite-spacing-element-xs);
	// --kite-pagination-display-filter-select-margin-right: var(--kite-spacing-element-xs);
	// --kite-pagination-display-filter-right-label-text-color: var(--kite-color-gray-30);
	// --kite-pagination-display-filter-right-label-text-color-dark: var(--kite-color-gray-25);
	// --kite-pagination-display-filter-margin-right: var(--kite-spacing-element-lg);

	// Tooltip
	// TODO(dura): Rename to just `--kite-tooltip-offset`
	// --kite-tooltip-arrow-directional-offset: var(--kite-spacing-element-xs);
	// TODO(dura): Wrong size tokens
	--kite-tooltip-arrow-height: var(--kite-size-xs);
	--kite-tooltip-arrow-width: var(--kite-size-sm);
	// TODO(dura): Create a tooltip z-index
	// --kite-tooltip-z-index: var(--kite-z-index-dropdown);
	// TODO(dura): Margin to what? hmmmmm... maybe this is the "offset", in which case it maybe should be 0 and changed to `--kite-tooltip-offset`?
	// --kite-tooltip-margin: var(--kite-spacing-element-xs);
	--kite-tooltip-offset: 0;
	// TODO(dura): Unused for styling, should be done via JS?
	// --kite-tooltip-max-width: 18rem;
	// --kite-tooltip-max-height: 4.25rem;
	// TODO(dura): These can't be used because of the structure of the component creating "seams"
	// --kite-tooltip-entrance-transition-duration: var(--kite-motion-duration-fast);
	// --kite-tooltip-entrance-transition-curve: var(--kite-motion-curve-deceleration);
	// --kite-tooltip-exit-transition-duration: var(--kite-motion-duration-fast);
	// --kite-tooltip-exit-transition-curve: var(--kite-motion-curve-acceleration);

	// Badge
	// TODO(dura): Same error-color reference on light/dark... should it be referencing error or just the red you want? Using a "light" intent in "dark" theme seems smelly
	// --kite-badge-background-color: var(--kite-color-error);
	// --kite-badge-background-color-dark: var(--kite-color-error);

	// --kite-badge-border-radius: var(--kite-border-radius-circle);
	// --kite-badge-text-color: var(--kite-color-white);
	// --kite-badge-text-color-dark: var(--kite-color-white);
	// --kite-badge-text-size: var(--kite-size-sm);
	// --kite-badge-padding-top: var(--kite-spacing-element-xxs);
	// --kite-badge-padding-right: var(--kite-spacing-element-xxs);
	// --kite-badge-padding-bottom: var(--kite-spacing-element-xxs);
	// --kite-badge-padding-left: var(--kite-spacing-element-xxs);
	// TODO(dura): Rename icon-dot tokens to just `sm`
	// --kite-badge-icon-dot-height: var(--kite-size-xs);
	// --kite-badge-icon-dot-width: var(--kite-size-xs);
	--kite-badge-width-sm: var(--kite-size-xs);
	--kite-badge-height-sm: var(--kite-size-xs);
	// --kite-badge-tab-margin-right: var(--kite-spacing-element-xs);
	// TODO(dura): Need font-weight or bold?
	--kite-badge-text-weight: var(--kite-font-weight-bold);
	// --kite-badge-border-radius: 1.25rem;
	// TODO(dura): Define the min-width/height, eg, what a circle would be
	// Match the border-radius to make a circle
	// should also be relative to the font-size, I think
	--kite-badge-min-width: 1.75em;
	--kite-badge-min-height: var(--kite-badge-min-width);
	// TODO(dura): The badge isn't always a circle
	// --kite-badge-border-radius: var(--kite-border-radius-circle);
	--kite-badge-border-radius: var(--kite-badge-min-width);
	// TODO(dura): Needs disabled color
	--kite-badge-disabled-background-color: var(--kite-color-gray-20);
	--kite-badge-disabled-background-color-dark: var(--kite-color-dark-blue-10);
	--kite-badge-disabled-text-color: var(--kite-color-disabled);
	--kite-badge-disabled-text-color-dark: var(--kite-color-disabled-dark);
	// TODO(dura): It's not just the margin for tab, it's the margin for when any element is next to it
	// --kite-badge-tab-margin-right: var(--kite-spacing-element-xs);
	--kite-badge-margin: var(--kite-spacing-element-xs);

	--kite-chip-cursor: pointer;
	--kite-chip-selected-padding-left: 19px;
	--kite-chip-selected-padding-right: 19px;
	--kite-chip-indicator-width: 18px;
	--kite-chip-indicator-height: 18px;
	--kite-chip-focus-ring-offset: 6px;
	--kite-chip-disabled-border-color: var(--kite-color-gray-25);
	--kite-chip-disabled-border-color-dark: var(--kite-color-gray-30);
	--kite-chip-disabled-background-color: var(--kite-color-white);
	--kite-chip-hover-background-color-dark: var(--kite-color-dark-blue-20);
	// Updated chip margins to align chip to the left of container for multi-line chips and because inline block elements do not collapse margin
	//https://www.w3.org/TR/CSS2/box.html#:~:text=Margins%20of%20inline%2Dblock%20boxes,unless%20that%20sibling%20has%20clearance.
	// The zero valued props have unit so they can be used in calculations
	--kite-chip-margin-top: 0;
	--kite-chip-margin-left: 0;
	--kite-chip-margin-right: 1rem;
	--kite-chip-margin-bottom: 1rem;
	--kite-chip-legend-margin-bottom: 1rem;
	--kite-chip-focus-background-color: var(--kite-color-gray-20);
	--kite-chip-focus-background-color-dark: var(--kite-color-dark-blue-20);
	// TODO(dura): Create pill token for pill shaped border radius
	--kite-border-radius-pill: 9999px;
	--kite-chip-border-radius: var(--kite-border-radius-pill);

	// Menu ((Incorrect / Missing dark theme colors && missing tokens)
	--kite-menu-trigger-icon-fill-dark: var(--kite-color-white);
	--kite-menu-trigger-hover-background-color-dark: var(--kite-color-gray-30);
	--kite-menu-trigger-disabled-icon-fill-dark: var(--kite-color-gray-30);
	--kite-menu-list-background-color-dark: var(--kite-color-dark-blue-20);
	--kite-menu-list-border-color-dark: var(--kite-color-gray-30);
	--kite-menu-list-item-text-color-dark: var(--kite-color-white);
	--kite-menu-list-item-focus-ring-color-dark: var(--kite-color-white);
	--kite-menu-list-item-hover-background-color-dark: var(--kite-color-gray-30);
	--kite-menu-list-item-destructive-icon-color: var(--kite-color-error);
	--kite-menu-width: 44px;
	--kite-menu-trigger-padding: 12px;
	--kite-menu-trigger-border-radius: 4px;
	--kite-menu-trigger-icon-fill: var(--kite-color-black);
	--kite-menu-trigger-disabled-icon-fill: var(--kite-color-gray-25);
	--kite-menu-trigger-active-icon-fill: var(--kite-color-white);
	--kite-menu-trigger-hover-background-color: var(--kite-color-gray-20);
	--kite-menu-trigger-active-background-color: #0073d1;
	--kite-menu-top: var(--kite-spacing-element-xl);
	--kite-menu-right: var(--kite-spacing-element-xl);
	--kite-menu-list-top: 0;
	--kite-menu-list-margin-right: 4px;
	--kite-menu-list-item-disabled-cursor: not-allowed;
	// incorrect color
	--kite-menu-list-item-disabled-text-color-dark: var(--kite-color-gray-30);
	--kite-menu-list-elevation-dark: var(--kite-elevation-2-dark);
	// change name to remove bottom reference
	--kite-menu-list-item-border-color: var(--kite-menu-list-item-border-bottom-color);
	--kite-menu-list-item-border-width: var(--kite-menu-list-item-border-bottom-width);
	--kite-menu-list-item-border-style: var(--kite-menu-list-item-border-bottom-style);
	--kite-menu-list-item-border-color-dark: var(--kite-color-gray-30);
	--kite-dropdown-list-item-border-width: var(--kite-dropdown-list-item-border-bottom-width);
	--kite-dropdown-list-item-border-style: var(--kite-dropdown-list-item-border-bottom-style);
	// Add list max width so it cannot be larger than viewport
	--kite-menu-list-max-width: 21rem;

	// TODO(dura): Contextual Menu With Label variation
	--kite-menu-trigger-label-color: var(--kite-color-text);
	--kite-menu-trigger-label-color-dark: var(--kite-color-text-dark);
	--kite-menu-active-trigger-label-color: var(--kite-color-text-dark);
	--kite-menu-active-trigger-label-color-dark: var(--kite-color-text-dark);
	--kite-menu-disabled-trigger-label-color: var(--kite-color-disabled);
	--kite-menu-disabled-trigger-label-color-dark: var(--kite-color-disabled-dark);

	// Dropdown Menu (missing tokens)
	--kite-dropdown-list-item-select-icon-fill: var(--kite-color-blue-20);
	--kite-dropdown-list-item-select-icon-fill-dark: var(--kite-color-white);
	--kite-dropdown-disabled-cursor: not-allowed;
	--kite-dropdown-disabled-text-color: var(--kite-color-gray-25);
	--kite-dropdown-disabled-text-color-dark: var(--kite-color-gray-30);
	--kite-dropdown-disabled-icon-fill: var(--kite-color-gray-25);
	--kite-dropdown-disabled-icon-fill-dark: var(--kite-color-gray-30);
	--kite-dropdown-padding-top: 0.625rem; // override existing token
	--kite-dropdown-padding-bottom: 0.625rem; // override existing token
	--kite-dropdown-icon-margin-right: 0.5rem;
	--kite-dropdown-hover-border-width: 2px;
	--kite-dropdown-list-margin-top: 0.25rem;
	--kite-dropdown-focus-ring-offset: -0.125rem;
	--kite-dropdown-list-item-padding-top: 0.625rem;
	--kite-dropdown-list-item-padding-bottom: 0.625rem;
	--kite-dropdown-list-item-padding-right: 0.75rem;
	--kite-dropdown-list-item-padding-left: 0;
	--kite-dropdown-list-item-icon-width: 1rem;
	--kite-dropdown-list-item-icon-height: 1rem;
	--kite-dropdown-list-item-icon-margin-right: 0.25rem;
	--kite-dropdown-list-item-icon-margin-left: 0.25rem;
	--kite-dropdown-list-item-selected-text-weight: 700;
	--kite-dropdown-selection-line-height: 1.5;
	--kite-dropdown-action-line-height: 1.5;

	// Dropdown Dark Theme (Incorrect / Missing dark theme colors)
	--kite-dropdown-background-color-dark: transparent;
	--kite-dropdown-border-color-dark: var(--kite-color-gray-30);
	--kite-dropdown-text-color-dark: var(--kite-color-white);
	--kite-dropdown-background-color-dark: var(--kite-color-dark-blue-20);
	--kite-dropdown-disabled-background-color-dark: var(--kite-color-dark-blue-20);

	//Inline Menu (Incorrect / Missing dark theme colors)
	--kite-dropdown-inline-text-color-dark: var(--kite-color-white);
	--kite-dropdown-inline-disabled-text-color-dark: var(--kite-color-gray-30);
	--kite-dropdown-inline-focus-ring-offset: 0.125rem;
	--kite-dropdown-inline-background-color: transparent;
	--kite-dropdown-inline-background-color-dark: transparent;
	--kite-dropdown-inline-disabled-background-color: transparent;
	--kite-dropdown-inline-disabled-background-color-dark: transparent;

	/* Dropdown / Inline Menu tokens No longer needed, sharing with contextual menu
	--kite-dropdown-list-item-focus-ring-color
	--kite-dropdown-focus-ring-color
	--kite-dropdown-focus-ring-color-dark;
	--kite-dropdown-list-background-color
	--kite-dropdown-list-border-color
	--kite-dropdown-list-elevation
	--kite-dropdown-list-elevation-dark
	--kite-dropdown-list-item-border-bottom-color
	--kite-dropdown-list-item-hover-background-color
	--kite-dropdown-list-item-text-color
	--kite-dropdown-list-item-disabled-text-color
	--kite-dropdown-inline-border-color
	--kite-dropdown-inline-border-color-dark
	--kite-dropdown-inline-focus-ring-color
	--kite-dropdown-inline-focus-ring-color-dark
	--kite-dropdown-inline-disabled-text-color
	--kite-dropdown-inline-disabled-text-color-dark
	--kite-dropdown-inline-background-color
	--kite-dropdown-inline-background-color-dark
	*/

	// Meter Tokens
	--kite-meter-progress-fill-background-color: var(--kite-color-info);
	--kite-meter-progress-fill-background-color-dark: var(--kite-color-info-dark);
	--kite-meter-success-progress-fill-background-color: var(--kite-meter-positive-progress-fill-background-color);
	--kite-meter-success-progress-fill-background-color-dark: var(--kite-meter-positive-progress-fill-background-color-dark);
	--kite-meter-linear-large-border-radius: 0.5rem;
	--kite-meter-linear-small-border-radius: 0.25rem;
	--kite-meter-circular-large-status-icon-height: 1rem;
	--kite-meter-circular-large-status-icon-width: 1rem;
	--kite-meter-circular-small-status-icon-margin: 0.375rem;
	--kite-meter-circular-large-status-icon-margin: 0.5rem;
	--kite-meter-circular-large-label-width: 75%;
	--kite-meter-circular-label-margin-top: 0.25rem;

	// Popover
	--kite-popover-dismiss-cursor: var(--kite-popover-trigger-cursor);
	--kite-popover-arrow-border-width: 0.125rem;
	--kite-popover-title-margin-bottom: 0.25rem;
	--kite-popover-link-margin-top: 0.75rem;
	--kite-popover-trigger-focus-ring-offset: 1px;
	--kite-popover-offset-y: 0.5rem;
	--kite-popover-trigger-margin-left: 0.25rem;

	// Dynamic Hint
	--kite-dynamic-hint-max-width: 18rem;
	--kite-dynamic-hint-arrow-height: 0.625rem;
	--kite-dynamic-hint-z-index: 1100;

	// Color Token Name changes
	--kite-dynamic-hint-criteria-error-icon-color: var(--kite-dynamic-hint-criteria-error-icon-fill);
	--kite-dynamic-hint-criteria-success-icon-color: var(--kite-dynamic-hint-criteria-success-icon-fill);
	--kite-dynamic-hint-criteria-error-icon-color-dark: var(--kite-dynamic-hint-criteria-error-icon-fill-dark);
	--kite-dynamic-hint-criteria-success-icon-color-dark: var(--kite-dynamic-hint-criteria-success-icon-fill-dark);

	// To remove because the transition motion for popover was removed
	// --kite-dynamic-hint-entry-transition-duration
	// --kite-dynamic-hint-exit-transition-duration

	// Alert
	--kite-alert-global-caution-text-color-dark: var(--kite-color-black);
	--kite-alert-global-caution-link-text-color-dark: var(--kite-color-black);
	--kite-alert-global-info-text-color-dark: var(--kite-color-black);
	--kite-alert-global-info-link-text-color-dark: var(--kite-color-black);
	--kite-alert-page-text-color-dark: var(--kite-color-black);

	// Accordion
	--kite-accordion-title-weight: var(--kite-typography-lg-body-weight);
	--kite-accordion-label-weight: var(--kite-typography-lg-body-weight);
	--kite-accordion-expanded-padding-top: 0.5rem;

	// Data Table
	--kite-data-table-background-color: var(--kite-color-white);
	--kite-data-table-background-color-dark: (--kite-color-dark-blue-30);
	--kite-data-table-row-striped-inverse-background-color: var(--kite-color-white);
	--kite-data-table-row-striped-background-color-dark: var(--kite-color-dark-blue-20);
	--kite-data-table-row-striped-inverse-background-color-dark: var(--kite-color-dark-blue-30);
	--kite-data-table-sort-icon-padding-left: 0.25rem;
	--kite-data-table-scroll-shadow: inset -14px 0 10px -14px rgba(0, 0, 0, 0.2);
	--kite-data-table-scroll-shadow-dark: inset -14px 0 10px -14px rgba(0, 0, 0, 0.5);
	--kite-data-table-shadow-width: 5px;
	--kite-data-table-menu-column-width: 2.75rem;
	--kite-data-table-menu-column-padding: 0;
	--kite-data-table-menu-cell-padding-left: 0;
	--kite-data-table-menu-cell-padding-right: 0;
	--kite-data-table-menu-cell-padding-top: 0.25rem;
	--kite-data-table-menu-cell-padding-bottom: 0.25rem;


	// Dialog
	--kite-motion-standard: cubic-bezier(0.25, 0.1, 0.25, 1);
	--kite-dialog-content-group-margin-top: 1.25rem;
	--kite-dialog-alert-margin-top: 0.5rem;
	--kite-dialog-alert-margin-bottom: var(--kite-spacing-element-lg);
	--kite-dialog-button-min-width: 15.25rem;
	--kite-dialog-border-width: 0; // Kite dialog has no border, but kite card does, overriding
	--kite-dialog-full-size-padding-right: 1rem;
	--kite-dialog-position-x: center;
	--kite-dialog-position-y: center;
	--kite-dialog-breakpoint-md-width: 75%;
	--kite-dialog-breakpoint-lg-width: 66.66%;
	--kite-dialog-breakpoint-2xl-width: 50%;
	--kite-dialog-full-size-padding-top: 3rem; // Update full size padding to not overlay heading with dismiss
	--kite-dialog-transition-duration: 0;
	--kite-dialog-transition-timing-function: var(--kite-motion-standard);

	// TODO(dura): Create new tokens for text area char count
	// Text Area
	--kite-text-area-character-count-text-color: var(--kite-color-gray-30);
	--kite-text-area-character-count-text-color-dark: var(--kite-color-gray-25);
	--kite-text-area-character-count-font-size: var(--kite-typography-sm-eyebrow-size);

	// TODO(dura): Update input element disabled background color
	// to match spec
	--kite-form-control-input-disabled-background-color: var(--kite-form-control-input-background-color);
	--kite-form-control-input-disabled-background-color-dark: var(--kite-form-control-input-background-color-dark);
	--kite-form-control-label-disabled-text-color: var(--kite-color-disabled);
	--kite-form-control-label-disabled-text-color-dark: var(--kite-color-disabled-dark);

	// TODO(dura): Update Colors to Beam 4
	--kite-color-dark-blue-30: #001221;
	--kite-color-dark-blue-20: #002747;
	--kite-color-dark-blue-10: #003561;
	--kite-color-yellow-20: #faa41a;

	// TODO(dura): Add token for checkbox hover background color
	--kite-checkbox-hover-background-color: transparent;
	--kite-checkbox-hover-background-color-dark: transparent;

	// Select
	// TODO(Dura): we need to explicitly set line-height to work with existing select height and prevent cutoff
	--kite-select-line-height: 1.1;

	// Radio Group
	// TODO(Dura): legend style differs depending on the type of reboot being used, we should explicitly define them
	--kite-radio-group-legend-font-size: 1rem;
	--kite-radio-group-label-margin-bottom: 1rem;
	// TODO(tammy): these already exist in Beam 4 tokens, can be removed when we move to vanilla v2
	--kite-radio-input-margin-bottom: var(--kite-size-2);
	--kite-radio-input-margin-top: var(--kite-size-2);
	--kite-radio-input-margin-left: 0;

	// Tabs
	// TODO(dura): Add token for Kite tab line height
	--kite-tabs-item-line-height: var(--kite-typography-lg-body-line-height);

	// Interactive List
	--kite-interactive-list-item-icon-lg-width: 1.5rem;
	--kite-interactive-list-item-icon-lg-height: 1.5rem;
	--kite-interactive-list-icon-lg-inline-offset: .25rem;
	--kite-interactive-list-start-margin-right: var(--kite-interactive-list-item-icon-margin);
	--kite-interactive-list-start-width: auto;
	--kite-interactive-list-start-alignment: center;
	--kite-interactive-list-center-alignment: start;
	--kite-interactive-list-end-width: auto;
	--kite-interactive-list-end-alignment: center;
	--kite-interactive-list-end-margin-left: 10px;
	--kite-interactive-list-item-image-max-width: 80px;
	--kite-interactive-list-item-image-border-radius: 0.25rem;
	--kite-interactive-list-image-max-width: 84px;
	--kite-interactive-list-image-max-height: 84px;
	--kite-interactive-list-image-border-radius: 4px;
	--kite-interactive-list-icon-inline-offset: 0;
	--kite-interactive-list-action-icon-inline-offset: -1px;

	--kite-interactive-list-item-title-text-size: 18px; // title 6 sm values
	--kite-interactive-list-item-title-text-weight: 500;
	--kite-interactive-list-item-title-text-line-height: 24px;

	--kite-interactive-list-item-breakpoint-md-title-text-size: 20px; // title 6 md values
	--kite-interactive-list-item-breakpoint-md-title-text-weight: 500;
	--kite-interactive-list-item-breakpoint-md-title-text-line-height: 28px;
}
