@import '../config';
@import 'mixins';
@import 'vars';
@import '../breakpoint';


/// @access private
@mixin _kite-grid-spacing($gridPrefix: $prefix, $containerQuery: false) {
	// Some rules here are applied using duplicate selectors.
	// This is on purpose to increase their specificity when applied.
	// For example: `.kite-cell-2-xs.kite-cell-2-xs`

	.#{$gridPrefix}-grid {
		display: flex;
		flex-wrap: wrap;

		@if ($containerQuery) {
			container-type: inline-size;
			container-name: #{$prefix}-grid-container;
		}

		$vpList : map-keys($kite-grid-breakpoints);
		// Take the max number of columns from the largest possible vp
		$maxColumnSize : map-get(map-get($kite-grid-breakpoints, nth($vpList, length($vpList))), "columns");

		@for $i from 1 through length($vpList) {
			$vpName: nth($vpList, $i);
			$isMinSize : $i == 1;
			// Smallest VP does not need a media query
			@if $isMinSize {
				margin: 0 calc(var(--#{$prefix}-grid-#{$vpName}-gutters) / -2);
			} @else {
				@if ($containerQuery) {
					@include kite-container-breakpoint-up($vpName, null, $kite-grid-breakpoints) {
						margin: 0 calc(var(--#{$prefix}-grid-#{$vpName}-gutters) / -2);
					}
				} @else {
					@include kite-breakpoint-up($vpName, $kite-grid-breakpoints) {
						margin: 0 calc(var(--#{$prefix}-grid-#{$vpName}-gutters) / -2);
					}
				}
			}
		}

		> .#{$gridPrefix}-cell {
			box-sizing: border-box;

			@for $i from 1 through length($vpList) {
				$vpName: nth($vpList, $i);
				$isMinSize : $i == 1;
				// Smallest VP does not need a media query
				@if $isMinSize {
					padding: calc(var(--#{$prefix}-grid-#{$vpName}-gutters) / 2);
				} @else {
					@if ($containerQuery) {
						@include kite-container-breakpoint-up($vpName, null, $kite-grid-breakpoints) {
							padding: calc(var(--#{$prefix}-grid-#{$vpName}-gutters) / 2);
						}
					} @else {
						@include kite-breakpoint-up($vpName, $kite-grid-breakpoints) {
							padding: calc(var(--#{$prefix}-grid-#{$vpName}-gutters) / 2);
						}

					}
				}
			}
		}

		&.#{$gridPrefix}-grid--no-gutters {
			margin: 0;

			> .#{$gridPrefix}-cell {
				padding: 0;
			}
		}
	}

	// TODO(dura): Card content (like `.table-responsive`) could
	// force cells to go wider than parent, assuming this is a
	// bad thing, we give cells a `max-width: 100%;`.
	.#{$gridPrefix}-cell {
		flex: 0 0 100%;
		max-width: 100%;

		> .#{$prefix}-card {
			height: 100%; // Assume cards in a grid should be equal heights
		}
	}
}

/// @access private
@mixin _kite-custom-grid($containerQuery:false) {

	$gridPrefix: $prefix;
	@if ($containerQuery)	{
		$gridPrefix: "#{$prefix}-container";
	}

	@include _kite-grid-spacing($gridPrefix, $containerQuery);

	$vpList : map-keys($kite-grid-breakpoints);
	// Take the max number of columns from the largest possible vp
	$maxColumnSize : map-get(map-get($kite-grid-breakpoints, nth($vpList, length($vpList))), "columns");

	@for $i from 1 through length($vpList) {
		$vpName: nth($vpList, $i);
		$isMinSize : $i == 1;
		$isMaxSize : $i == length($vpList);
		$vpValues : map-get($kite-grid-breakpoints, $vpName);
		$columns : map-get($vpValues, "columns");
		$width: map-get($vpValues, "width");

		@if ($containerQuery) {
			// Smallest Breakpoint does not require extra breakpoint rules
			@if $isMinSize {
				// Define partial sizes for available columns.
				@for $i from 1 through ($columns - 1) {
					@include _kite-container-partial-size($i, $vpName, $columns, $gridPrefix);
				}
				// Define 100% for columns above the max columns.
				@for $i from $columns through $maxColumnSize {
					@include _kite-container-full-size($i, $vpName, $gridPrefix);
				}
				.#{$gridPrefix}-cell-flex {
					flex: 1;
				}
			} @else {
				$previousVpName: nth($vpList, $i - 1);
				$gtVpName: "gt-#{$previousVpName}";
				@include kite-container-breakpoint-up($vpName, null, $kite-grid-breakpoints) {
					@for $i from 1 through ($columns - 1) {
						@include _kite-container-partial-size($i, $gtVpName, $columns, $gridPrefix);
					}

					@for $i from $columns through $maxColumnSize {
						@include _kite-container-full-size($i, $gtVpName, $gridPrefix);
					}
					.#{$gridPrefix}-cell-flex-#{$gtVpName}.#{$gridPrefix}-cell-flex-#{$gtVpName} {
						flex: 1;
					}
				} 
			}
		} @else {
			@if $isMinSize {
				// Define partial sizes for available columns.
				@for $i from 1 through ($columns - 1) {
					@include _kite-partial-size($i, $vpName, $columns, $gridPrefix);
				}
				// Define 100% for columns above the max columns.
				@for $i from $columns through $maxColumnSize {
					@include _kite-full-size($i, $vpName, $gridPrefix);
				}
				.#{$gridPrefix}-cell-flex {
					flex: 1;
				}
			}	@else {
				$previousVpName: nth($vpList, $i - 1);
				$gtVpName: "gt-#{$previousVpName}";
				@include kite-breakpoint-up($vpName, $kite-grid-breakpoints) {
					@for $i from 1 through ($columns - 1) {
						@include _kite-partial-size($i, $gtVpName, $columns, $gridPrefix);
					}

					@for $i from $columns through $maxColumnSize {
						@include _kite-full-size($i, $gtVpName, $gridPrefix);
					}
					.#{$gridPrefix}-cell-flex-#{$gtVpName}.#{$gridPrefix}-cell-flex-#{$gtVpName} {
						flex: 1;
					}
				}
			}
		}
	}
}

/// Outputs the legacy grid classes from `@kite/web`
/// @access private
@mixin _kite-legacy-grid {

	// gt-xs
	@include kite-breakpoint-up('sm') {
		.#{$prefix}-cell-flex-gt-xs.#{$prefix}-cell-flex-gt-xs {
			flex: 1;
		}
		@for $i from 1 through ($kite-grid-xs-columns - 1) {
			@include _kite-partial-size($i, 'gt-xs', $kite-grid-xs-columns);
		}

		@for $i from $kite-grid-xs-columns through $kite-grid-lg-columns {
			@include _kite-full-size($i, 'gt-xs');
		}
	}

	// gt-sm
	@include kite-breakpoint-up('md') {
		.#{$prefix}-cell-flex-gt-sm.#{$prefix}-cell-flex-gt-sm {
			flex: 1;
		}
		@for $i from 1 through ($kite-grid-xs-columns - 1) {
			@include _kite-partial-size($i, 'gt-sm', $kite-grid-xs-columns);
		}

		@for $i from $kite-grid-xs-columns through $kite-grid-lg-columns {
			@include _kite-full-size($i, 'gt-sm');
		}
	}

	// gt-md
	@include kite-breakpoint-up('lg') {
		.#{$prefix}-cell-flex-gt-md.#{$prefix}-cell-flex-gt-md {
			flex: 1;
		}
		@for $i from 1 through $kite-grid-lg-columns {
			@include _kite-partial-size($i, 'gt-md', $kite-grid-lg-columns);
		}
	}

	// gt-lg
	@include kite-breakpoint-up('xl') {

		.#{$prefix}-cell-flex-gt-lg.#{$prefix}-cell-flex-gt-lg {
			flex: 1;
		}
		@for $i from 1 through $kite-grid-lg-columns {
			@include _kite-partial-size($i, 'gt-lg', $kite-grid-lg-columns);
		}
	}

	// Some rules here are applied using duplicate selectors.
	// This is on purpose to increase their specificity when applied.
	// For example: `.kite-cell-2-xs.kite-cell-2-xs`

	.#{$prefix}-grid {
		display: flex;
		flex-wrap: wrap;
		margin: 0 calc(var(--kite-grid-xs-gutters) / -2);

		@include kite-breakpoint-up('lg') {
			margin: 0 calc(var(--kite-grid-lg-gutters) / -2);
		}

		> .#{$prefix}-cell {
			box-sizing: border-box;
			padding: calc(var(--kite-grid-xs-gutters) / 2);

			@include kite-breakpoint-up('lg') {
				padding: calc(var(--kite-grid-lg-gutters) / 2);
			}
		}

		&.#{$prefix}-grid--no-gutters {
			margin: 0;

			> .#{$prefix}-cell {
				padding: 0;
			}
		}
	}

	// TODO(dura): Card content (like `.table-responsive`) could
	// force cells to go wider than parent, assuming this is a
	// bad thing, we give cells a `max-width: 100%;`.
	.#{$prefix}-cell {
		flex: 0 0 100%;
		max-width: 100%;

		> .#{$prefix}-card {
			height: 100%; // Assume cards in a grid should be equal heights
		}
	}
	.#{$prefix}-cell-flex {
		flex: 1;
	}

	// Define partial sizes for available columns.
	@for $i from 1 through ($kite-grid-xs-columns - 1) {
		@include _kite-partial-size($i, 'xs', $kite-grid-xs-columns);
	}
	// Define 100% for columns above the max columns.
	@for $i from $kite-grid-xs-columns through $kite-grid-lg-columns {
		@include _kite-full-size($i, 'xs');
	}
}

// If the global variable does not exist, or it is exactly `false`
@if _undefined('kite-css--enable-legacy-grid') or ($kite-css--enable-legacy-grid == false) {
	@include _kite-custom-grid();
	@if _undefined('kite-css--disable-container-grid') or ($kite-css--disable-container-grid == false) {
		@include _kite-custom-grid(true);
	}
} @else {
	@include _kite-legacy-grid();
}