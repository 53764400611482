@use 'sass:meta';

@import 'config';
@import 'deprecate';
@import 'web/kite-reboot';
@import 'vars';

// TODO(dura): "use" kind of screws this import up because of the global
// variables, so we may need to rethink this eventually because "use" is the future

// If the global variable does not exist, or it is exactly `false`
@if _undefined('kite-css--exclude-reboot') or ($kite-css--exclude-reboot == false) {
	@if _undefined('kite-css--enable-legacy-reboot') or ($kite-css--enable-legacy-reboot == false) {
		*,
		*::before,
		*::after {
			box-sizing: border-box;
		}
		// stylelint-disable-next-line order/order
		@include meta.load-css('normalize.css/normalize.css');
	} @else {
		@include deprecate('Legacy `@kite/web` reboot will be replaced with `normalize.css`') {
			@include _web-kite-reboot();
		}
	}
}

// Include a type reboot for things like `html`
html {
	font-size: $kite-font-size-base; // Need to use `px` for html in order for rems to work
}

code,
kbd,
samp {
	font-family: kite-font-family('mono');
}
