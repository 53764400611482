
// Kite UI Style Guide colors
// ---------------------------------------------------------

////
/// These are the Kite UI colors, based on the Brand Identity colors
/// for Charter Spectrum.
/// @group Colors
/// @access public
/// @author Christopher Dura ([@dura](https://gitlab.spectrumxg.com/u/dura))
////

/// @type Color
$kite-white:             #fff !default;    // rgb(255,255,255);
/// @type Color
$kite-gray-1:            #f8f8f8 !default; // rgb(248,248,248);
/// @type Color
$kite-gray-2:            #d8dde6 !default; // rgb(216,221,230);
/// @type Color
$kite-gray-3:            #63738a !default; // rgb(99,115,138);
/// @type Color
$kite-black:             #000 !default;    // rgb(0,0,0);

/// @type Color
$kite-light-blue:        #b7cee5 !default; // rgb(103,206,229);
/// @type Color
$kite-light-blue-2:      #eef7fd !default; // rgb(238,247,253);

/// @type Color
$kite-blue-1:            #0d92ff !default; // rgb(13,146,255);
/// @type Color
$kite-blue-2:            #0073d1 !default; // rgb(0,115,209);
/// @type Color
$kite-blue-3:            #0062b2 !default; // rgb(0,98,178);

/// @type Color
$kite-dark-blue-1:       #004366 !default; // rgb(0,67,102);
/// @type Color
$kite-dark-blue-2:       #002133 !default; // rgb(0,33,51);
/// @type Color
$kite-dark-blue-3:       #001019 !default; // rgb(0,16,25);

/// @type Color
$kite-gold:              #ffd400 !default; // rgb(255,212,0);
/// @type Color
$kite-dark-gold:         #feb533 !default; // rgb(254,181,51);
/// @type Color
$kite-green:             #00bf1f !default; // rgb(0,191,31);
/// @type Color
$kite-dark-green:        #008516 !default; // rgb(0,133,21);
/// @type Color
$kite-red:               #ff4d4a !default; // rgb(255,75,74);
/// @type Color
$kite-dark-red:          #d6312b !default; // rgb(214,49,43);
